import React from "react";
import "../style/exportmaskmodal.css";
import {faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from 'react-select';
import Modal from "../components/modal";


export default class ExportMaskModal extends React.Component {
	constructor(props) {
    	super();
    	this.state = {
    		selections : [],
			out_save_name: props.active_slide_name.split('.')[0] + "_mask",
			cur_slide_name: props.active_slide_name // to track when slide changes
    	}
    	this.handleSelections = this.handleSelections.bind(this);
		this.handleOutSaveName = this.handleOutSaveName.bind(this);
 	}

 	//returns an array of possible selections (rectangles, polygon) for the second circle plot
	getAllSelections(){
		let options = []
		this.props.overlays.forEach( (e,i) => options.push({ value: i, label: 'Selection ' + i }))
		this.props.polygonPoints.forEach( (e,i) => options.push({ value: 'Polygon' + i, label: 'Polygon ' + i }))
		this.props.maskPoints.forEach( (e,i) => options.push({ value: 'Freehand' + i, label: 'Freehand ' + i }))
		return options;
	}

	handleSelections(e){
		if (e == null){
			this.setState({ selections: []})
		}
		else{
			let t_selections = e.map((el) => el.label)
			this.setState({ selections : t_selections });
		}
	}

	componentDidUpdate() {
		// check if active slide has changed
		if (this.props.active_slide_name != this.state.cur_slide_name){
			this.setState({ cur_slide_name: this.props.active_slide_name })
			this.setState({ out_save_name: this.props.active_slide_name.split('.')[0] + "_mask",})
		}
	}

	handleOutSaveName(v){
		this.setState({out_save_name: v.target.value})
	}

	render() {
		const { show } = this.props;

		if (!show) {
			return null;
		}

		const possible_selections = this.getAllSelections().filter((v) => {
			return !(this.state.selections.includes(v.label));
		})

		return (
		<Modal className="modal" toggle={this.props.toggle} show={this.props.show}>
			<div className='header'>
				<h1>Export Binary Mask
				</h1>
			</div>
			_______________________________________________________________________________________________

			<h3>Choose what selections to use:</h3>
			<Select
				  className='selection-select'
		          isMulti={true}
				  maxMenuHeight={120}
		          onChange={this.handleSelections}
		          value={this.state.selections.map( (s) => {
		          	return {
		          		value: s,
		          		label: s,
		          	}
		          })}
		          options={possible_selections}
		    />

			<div className="spacer"></div>

			<div>
				<h1>Filename</h1>
				<input
					type="text"
					className="save-name-input"
					placeholder={this.state.out_save_name}
					value={this.state.out_save_name}
					onChange={this.handleOutSaveName}
				/>
				.tif
			</div>

			<div>
				<h1>Invert</h1> 
				<label htmlFor="invert">
				<input type="checkbox" id="invert"
					value={this.props.invertMask} 
					checked={this.props.invertMask}
					onChange={this.props.handleInvertMask}
					style={{width:'16px', height:'16px'}}/>
				</label>
			</div>

			<button className='ui button export' onClick={()=> {	
					this.props.exportMask(this.state.selections, this.state.out_save_name)
				}}
				disabled={this.state.selections.length == 0}
				>
			Export</button>

		</Modal>
		);
	}
}