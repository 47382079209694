import React from "react";
//import "../style/CirclePlot.css";
//data-ui histogram package 
import * as d3 from "d3";
import "../style/circleplot.css";
import Plot from 'react-plotly.js';

export default class HeatMap extends React.Component {


	render () {
		let x_axis_label = this.props.activeChannelsScatterPlot[0];
		let y_axis_label = this.props.activeChannelsScatterPlot[1];
		let selection = this.props.activeSelectionScatterPlot;
		let x_bin_size = this.props.heatmapXBinSize;
		let y_bin_size = this.props.heatmapYBinSize;

		return(

		<Plot
	        data ={[
			  {
			    z: this.props.heatmapData,
			    x: this.props.heatmapXLabels,
			    y: this.props.heatmapYLabels,
			    type: 'heatmap',
    			hovertemplate: ' ' + x_axis_label + ': %{x} to %{x:.2f}<br>' +
    			 ' ' + y_axis_label + ': %{y} to %{y}<br>' +
    			 ' Count: %{z}<br><extra></extra>'
			  }
			]}
	        layout={ {width: 300, height: 500, 
	        title: {
	        	text: 'Heatmap for ' + selection, 
	        	font: {
			        family: 'Courier New, monospace',
			        size: 18,
			        color: '#7f7f7f'
			    }
	    	},
	    	xaxis: {
			    title: {
			      text: x_axis_label,
			      font: {
			        family: 'Courier New, monospace',
			        size: 18,
			        color: '#7f7f7f'
			      }
			    },
  			},
			yaxis: {
			    title: {
			      text: y_axis_label,
			      font: {
			        family: 'Courier New, monospace',
			        size: 18,
			        color: '#7f7f7f'
			      }
			    },
  			},


  			}}
	        config = {{displayModeBar: false}}
      	/>



      	);
	}
}