import React from "react";
import "../style/controls_modal";
import Controls from "../containers/controls";
import { Confirm } from "semantic-ui-react";
import { faLessThan, faGreaterThan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class ControlsModal extends React.Component {
 render() {
  if (!this.props.show) {
   return (
    <div className="row">
     <div className="col-md-6 col-lg-6 col-xl-4">
      <div className="maxbtn">
       <span id="max-switch" className="nav-item">
        <a className="btn" onClick={this.props.handleMaximize} title="Maximize">
         <FontAwesomeIcon
          icon={faGreaterThan}
          size={"lg"}
          color={"white"}
          className="fa-icon"
         />
        </a>
       </span>
      </div>
     </div>
    </div>
   );
  }

  return (
   <div className="row justify-content-between">
    <div className="col-md-6 col-lg-6 col-xl-4 bg-trans">
     <div className="minimizebtn">
      <span id="min-switch">
       <a className="btn" onClick={this.props.handleMinimize} title="Minimize">
        <FontAwesomeIcon
         icon={faLessThan}
         size={"lg"}
         className="fa-icon"
         color={"white"}
        />
       </a>
      </span>
     </div>

     <span>&nbsp;</span>

     {this.props.tabBar}

     <Controls
      channels={this.props.channels}
      addOverlayText={this.props.addOverlayText}
      deleteOverlay={this.props.deleteOverlay}
      deleteLine={this.props.deleteLine}
      deletePolygon={this.props.deletePolygon}
      deleteMask={this.props.deleteMask}
      highlightPolygon={this.props.highlightPolygon}
      highlightMask={this.props.highlightMask}
      linePoints={this.props.linePoints}
      maskPoints={this.props.maskPoints}
      polygonPoints={this.props.polygonPoints}
      drawType={this.props.drawType}
      boxClick={this.props.boxClick}
      num_fps={this.props.num_fps}
      lineClick={this.props.lineClick}
      polygonClick={this.props.polygonClick}
      maskDrawClick={this.props.maskDrawClick}
      handleChange={this.props.handleChange}
      curTabControls={this.props.curTabControls}
      chanLabel={this.props.chanLabel}
      handleSelect={this.props.handleSelect}
      addChannel={this.props.addChannel}
      activeChanLabel={this.props.activeChanLabel}
      activeChannels={this.props.activeChannels}
      overlays={this.props.overlays}
      handlePhenotype={this.props.handlePhenotype}
      dataPanelDisplayed={this.props.dataPanelDisplayed}
      handleDisplayDataPanel={this.props.handleDisplayDataPanel}
      handleActiveSelection={this.props.handleActiveSelection}
      recommendations={this.props.recommendations}
      toggleWhyPanelDisplay={this.props.toggleWhyPanelDisplay}
      showNewPhenotypeModal={this.props.showNewPhenotypeModal}
      activeIds={this.props.activeIds}
      toggleExportSelectionModal={this.props.toggleExportSelectionModal}
      toggleExportMaskModal={this.props.toggleExportMaskModal}
      has_cell_data={this.props.has_cell_data}
      has_cell_labels={this.props.has_cell_labels}
     />
    </div>
   </div>
  );
 }
}
