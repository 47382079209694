import 'bootstrap/dist/css/bootstrap.css';
import 'babel-core/register';
import 'babel-polyfill';

import React from "react";
import ReactDOM from "react-dom";


import "react-input-range/lib/css/index.css";
import AmplifyAuth from './containers/amplifyauth';

ReactDOM.render(
  <AmplifyAuth />,
  document.getElementById("tumormapr")
);

module.hot.accept();
