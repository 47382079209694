import React from "react";
import "../style/exportselectionmodal.css";
import {faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from 'react-select';
import Modal from "../components/modal";


export default class ExportSelectionModal extends React.Component {
	constructor(props) {
    	super();
    	this.state = {
    		selections : [],
    	}
    	this.handleSelections = this.handleSelections.bind(this);
 	}

 	//returns an array of possible selections (rectangles, polygon) for the second circle plot
	getAllSelections(){
		let options = []
		this.props.overlays.forEach( (e,i) => options.push({ value: i, label: 'Selection ' + i }))
		this.props.polygonPoints.forEach( (e,i) => options.push({ value: 'Polygon' + i, label: 'Polygon ' + i }))
		return options;
	}

	handleSelections(e){
		console.log('handling selections. e is ' + JSON.stringify(e))
		let t_selections = e.map((el) => el.label)
		console.log('setting selections to ' + t_selections)
		this.setState({ selections : t_selections });
	}

	render() {
		const { show } = this.props;

		if (!show) {
			return null;
		}

		return (
		<Modal className="modal" toggle={this.props.toggle} show={this.props.show}>
			<div className='header'>
				<h1>Export Selection Data</h1>
			</div>
			_______________________________________________________________________________________________

			<h3>Choose what selections to export:</h3>
			<Select
				  className='selection-select'
		          isMulti={true}
		          onChange={this.handleSelections}
		          value={this.state.selections.map( (s) => {
		          	return {
		          		value: s,
		          		label: s,
		          	}
		          })}
		          options={this.getAllSelections()}
		    />

			<table className="export-options">
				<tr>
					<td>Biomarker Intensity Data</td>
					<td><label htmlFor="bmintensity">
			       		<input type="checkbox" id="bmintensity"
			              value={this.props.includeBMIntensityData} 
			              checked={this.props.includeBMIntensityData}
			              onChange={this.props.handleIncludeBMIntensityData}
			              style={{width:'16px', height:'16px'}}/>
			        	</label>
			        </td>
				</tr>
				<tr>
					<td>Phenotype Frequencies</td>
					<td><label htmlFor="bmintensity">
			       		<input type="checkbox" id="bmintensity"
			              value={this.props.includePhenotypeFrequencies} 
			              checked={this.props.includePhenotypeFrequencies}
			              onChange={this.props.handleIncludePhenotypeFrequencies}
			              style={{width:'16px', height:'16px'}}/>
			        	</label>
			        </td>
				</tr>
				<tr>
					<td>Relative Cell Distances</td>
					<td><label htmlFor="bmintensity">
			       		<input type="checkbox" id="bmintensity"
			              value={this.props.includeRelativeCellDistances} 
			              checked={this.props.includeRelativeCellDistances}
			              onChange={this.props.handleIncludeRelativeCellDistances}
			              style={{width:'16px', height:'16px'}}/>
			        	</label>
			        </td>
				</tr>
				<tr>
					<td>Infiltration distances</td>
					<td><label htmlFor="bmintensity">
			       		<input type="checkbox" id="bmintensity"
			              value={this.props.includeInfiltrationDistances} 
			              checked={this.props.includeInfiltrationDistances}
			              onChange={this.props.handleIncludeInfiltrationDistances}
			              style={{width:'16px', height:'16px'}}/>
			        	</label>
			        </td>
				</tr>
			</table> 

			<button className='ui button export' onClick={()=> {	
					this.props.exportSelectionData(this.state.selections)
				}}
				disabled={this.props.includeInfiltrationDistances && this.props.polygonPoints.length == 0}
				>
			Export</button>

		</Modal>
		);
	}
}