import React from "react";
import '../style/caseviewer.css';

export default class CaseViewer extends React.Component {
    render() {
        let table = 
        <table className="casetable">
        <tbody>
          {this.props.all_case_slides.map(name => {
            let border = name == this.props.active_slide_name ? "4px solid #1fed56" : "";
            return (
              <tr style={{border: border}} onClick={() => this.props.changeActiveSlide(name)}>
                <td>{ name }</td>
              </tr>
            );
          })}
        </tbody>
        </table>
    
        return (<div className="caseviewer">
            <h1 className="header">Choose Slide</h1>
            <div className="table-holder">
              {table}
            </div>
        </div>)
    }
}