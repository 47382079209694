import React, { Component } from "react";
import ImportForm from "../components/importform";
import "../style/import";

class Import extends Component {
  render() {
    return (
      <div className="full-height">
        <h1 className="label">Hello {this.props.user.attributes.email}</h1>
      
        <div className="center-div">
          <div>
            <img
              className="author-logo"
              src="image/tumorMaprBasicLogo.png"
            ></img>
          </div>
          <ImportForm 
            user={this.props.user}
            signOut={this.props.signOut}
      />
        </div>
      </div>
    );
  }
}

export default Import;
