import React from "react";
import "../style/scale.css";

export default class Scale extends React.Component {

  render() {

  	return null;
    
    // return (
    //   <div className='scale'>
    //     <label className='label'>20 &mu;m</label>
    //   </div>
    // )
  }
}
