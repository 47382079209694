import React, { Component } from "react";
import ImageView from "./imageview";
import ImageView2 from "./imageview2";
import { Popup } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faCheck, faImage, faFileArchive } from "@fortawesome/free-solid-svg-icons";
import DataPanel from "../containers/datapanel";
import WhyPanel from "../components/whypanel";
import Scale from "../components/scale";
import CirclePlot from "../components/circleplot";
import NewPhenotypePanel from "../components/newphenotypepanel";
import ExportSelectionModal from "../components/exportselectionmodal";
import ExportMaskModal from "../components/exportmaskmodal";
import Loader from "../components/loader";
import ControlsModal from "../components/controlsmodal";
import CaseViewer from "../components/caseviewer";
import "../style/repo";

const randInt = (n) => Math.floor(Math.random() * n);
// const randColor = () => {
  // old colors/method
  // return [
  //   [0, 0, 255],
  //   [0, 127, 255],
  //   [0, 255, 0],
  //   [0, 255, 127],
  //   [0, 255, 255],
  //   [127, 0, 255],
  //   [127, 127, 127],
  //   [127, 127, 255],
  //   [127, 255, 0],
  //   [127, 255, 127],
  //   [255, 0, 0],
  //   [255, 0, 127],
  //   [255, 0, 255],
  //   [255, 127, 0],
  //   [255, 127, 127],
  //   [255, 255, 0],
  // ][randInt(16)];
// }

const randColor = (n) => {
  return [
    [0, 0, 139],
    [0, 100, 0],
    [176, 48, 96],
    [255, 0, 0],
    [255, 215, 0],
    [127, 255, 0],
    [0, 255, 255],
    [255, 0, 255],
    [100, 149, 237],
    [255, 218, 185],
  ][n%10];
};

// function to hard code the range for certain biomarkers
// const hcRange = (c_id, channels) => {
//   if (channels[c_id] == "Cell Masks") {
//     return { min: 0, max: 1 };
//   } else if (channels[c_id].includes("BetaCaten")) {
//     return { min: 43296, max: 65600 };
//   } else if (channels[c_id].includes("Dapi")) {
//     return { min: 0, max: 10000 };
//   } else if (channels[c_id].includes("CD68")) {
//     return { min: 34768, max: 65600 };
//   } else if (channels[c_id].includes("CD163")) {
//     return { min: 30176, max: 51168 };
//   } else if (channels[c_id].includes("ColIV")) {
//     return { min: 30832, max: 63632 };
//   } else if (channels[c_id].includes("SMA")) {
//     return { min: 32800, max: 61008 };
//   } else if (channels[c_id].includes("EPCAM")) {
//     return { min: 28208, max: 60352 };
//   } else if (channels[c_id].includes("NaKATPas")) {
//     return { min: 40672, max: 65600 };
//   } else if (channels[c_id].includes("CD20")) {
//     return { min: 30176, max: 42640 };
//   } else if (channels[c_id].includes("E_cad")) {
//     return { min: 21648, max: 59696 };
//   } else if (channels[c_id].includes("pck26")) {
//     return { min: 30176, max: 59696 };
//   } else {
//     return { min: 0, max: 32768 };
//   }
// };

const hcRange = (c_id, channels) => {
  if (channels[c_id] == "") {
    return { min: 0, max: 1 };
  } else if (channels[c_id].includes("H&E")){
    return { min: 0, max: 255 };
  }
  else {
    return { min: 0, max: 32768 };
  }
};


//method to grab a hard-coded color for certain channels
// CRC
// might want to rethink how this is done, for cleanliness, but its called only
// once when the app starts, so its fast, might be fine
// const hcColor = (c_id, channels) => {
//   //console.log('Computing hard coded color for ' + channels[c_id]);
//   if (channels[c_id] == "FP1") return [0, 255, 255];
//   else if (channels[c_id] == "FP2") return [0, 255, 0];
//   else if (channels[c_id] == "FP3") return [255, 0, 0];
//   else if (channels[c_id] == "FP4") return [255, 255, 0];
//   else if (channels[c_id] == "FP5") return [255, 0, 255];
//   else if (channels[c_id] == "FP6") return [0, 0, 255];
//   else if (channels[c_id] == "FP7") return [255, 128, 0];
//   else if (channels[c_id] == "FP8") return [128, 0, 255];
//   else if (channels[c_id] == "FP9") return [64, 32, 0];
//   else if (channels[c_id] == "FP10") return [128, 128, 255];
//   else if (channels[c_id] == "FP11") return [200, 0, 64];
//   else if (channels[c_id] == "FP12") return [100, 100, 0];
//   else if (channels[c_id] == "FP13") return [0, 100, 0];
//   else if (channels[c_id].includes("Dapi")) return [0, 0, 255];
//   else if (channels[c_id].includes("Cell Mask")) return [255, 165, 0];
//   else return randColor();
// };

// // triple negative
// const hcColor = (c_id, channels) => {
//   //console.log('Computing hard coded color for ' + channels[c_id]);
//   if (channels[c_id] == "FP 1") return [6, 250, 0];
//   else if (channels[c_id] == "FP 2") return [0, 176, 138];
//   else if (channels[c_id] == "FP 3") return [0, 176, 222];
//   else if (channels[c_id] == "FP 4") return [122, 129, 255];
//   else if (channels[c_id] == "FP 5") return [255, 129, 91];
//   else if (channels[c_id] == "FP 6") return [255, 0, 0];
//   else if (channels[c_id] == "FP 7") return [255, 138, 216];
//   else if (channels[c_id] == "FP 8") return [255, 64, 255];
//   else if (channels[c_id] == "FP 9") return [255, 232, 142];
//   else if (channels[c_id] == "FP 10") return [217, 217, 217];
//   else if (channels[c_id] == "FP 11") return [157, 194, 230];
//   else if (channels[c_id] == "FP 12") return [255, 66, 159];
//   else if (channels[c_id] == "FP 13") return [165, 233, 158];
//   else if (channels[c_id] == "FP 14") return [255, 255, 0];
//   else if (channels[c_id] == "FP 15") return [0, 255, 255];
//   else if (channels[c_id] == "FP 16") return [0, 143, 245];
//   else if (channels[c_id] == "FP 17") return [174, 255, 247];
//   else if (channels[c_id] == "FP 18") return [0, 189, 255];
//   else if (channels[c_id] == "FP 19") return [255, 141, 1];
//   else if (channels[c_id] == "FP 20") return [132, 206, 84];
//   else if (channels[c_id] == "FP 21") return [148, 55, 255];
//   else if (channels[c_id] == "FP 22") return [0, 255, 171];
//   else if (channels[c_id] == "FP 23") return [187, 191, 224];
//   else if (channels[c_id] == "FP 24") return [0, 188, 103];
//   else if (channels[c_id] == "FP 25") return [216, 131, 255];
//   else if (channels[c_id] == "FP 26") return [22, 0, 255];
//   else if (channels[c_id].includes("Dapi")) return [0, 0, 255];
//   else if (channels[c_id].includes("DNA")) return [0, 0, 255];
//   else if (channels[c_id].includes("Cell Mask")) return [255, 165, 0];
//   else if (channels[c_id].includes("Cell masks")) return [255, 165, 0];
//   else return randColor();
// };

// genmab
const hcColor = (c_id, channels, predefined_colors) => {
  if (Object.keys(predefined_colors).includes(channels[c_id])){
    return predefined_colors[channels[c_id]]
  }

  //console.log('Computing hard coded color for ' + channels[c_id]);
  if (channels[c_id] == "HE_Red") return [255, 0, 0];
  else if (channels[c_id] == "HE_Green") return [0, 255, 0];
  else if (channels[c_id] == "HE_Blue") return [0, 0, 255];
  else if (channels[c_id].toLowerCase().includes("dapi")) return [150, 150, 150];
  else if (channels[c_id].includes("Cell mask")) return [255, 0, 0];
  else if (channels[c_id].includes("Red")) return [255, 0, 0];
  else if (channels[c_id].includes("Green")) return [0, 255, 0];
  else if (channels[c_id].includes("Blue")) return [0, 0, 255];
  else return randColor(c_id);
};

const normalize = (viewer, pixels) => {
  const vp = viewer.viewport;
  const norm = vp.viewerElementToViewportCoordinates;
  return norm.call(vp, pixels);
};

class Repo extends Component {
  constructor(props) {
    super();

    const { width, height, maxLevel, tilesize, url } = props;
    const { channels, waypoints } = props;
    const {
      num_real_channels,
      saved_channel_renders,
      saved_channel_labels,
      saved_active_channels,
      saved_overlays,
      saved_linePoints,
      saved_polygonPoints,
      case_name,
      saved_custom_pheno_id,
      predefined_colors,
    } = props;

    const defaultChanRender = new Map(
      channels.map((v, k) => {
        return [
          k,
          {
            maxRange: 65535,
            value: k,
            id: k,
            //color: randColor(),
            color: hcColor(k, channels, predefined_colors),
            // range: {min: 0, max: 32768},
            range: hcRange(k, channels),
            visible: true,
          },
        ];
      })
    );

    this.state = {
      drawType: "",
      drawing: 0,
      mouseNavEnabled: true,
      img: {
        width: width,
        height: height,
        maxLevel: maxLevel,
        tilesize: tilesize,
        url: url,
      },
      imageName: props.imageName,
      all_case_slides: props.all_case_slides, // list of all the slides in the case
      active_slide_name: props.all_case_slides[0], // name of active slide 
      overlays: saved_overlays.length > 0 ? saved_overlays : [], // moving these over to state, for easier access
      linePoints: saved_linePoints.length > 0 ? saved_linePoints : [[], []], //points comprising the "line" (open polygon), [[x], [y]]
      polygonPoints:
        saved_polygonPoints.length > 0 ? saved_polygonPoints : [], // list of x,y coordinates [[[x],[y]], ...]
      highlightedPolygon: -1, // which polygon to highlight
      highlightedMask: -1, // which mask to highlight
      maskPoints: [], // same format as polygon points
      activeSelectionSpatial: "", //string representing the active selection for computing spatial (circleplot)
      activeSelectionInfiltration: "", //string representing the active selection for computing infiltration
      activeSelectionInfiltrationPolygon: "", //string representing the active polygon for computing infiltration
      activeSelectionSpatialNetwork: "",
      activeChannelInfiltration: "",
      clinicalData: {},
      has_cell_data: props.has_cell_data, // whether or not the case has cell csv files
      has_cell_labels: props.has_cell_labels, // whether or not labeled masks exist for the case
      num_fps: channels.filter((c) => c.includes("FP")).length,
      haveClinicalData: false,
      secondViewerActive: false, // whether the 2nd viewer window is active
      caseViewerActive: false, // whether the side panel for viewing case images is open
      viewer1Width: "100%", // width style for main viewer
      refreshViewer: false, // whether to refresh the viewer or not
      curTabControls: "channels", // which tab is currently visible in the controls panel, channels,explore,recommendation
      repoLoading: false, // general loader icon,
      computingInfiltrationDistance: false,
      haveInfiltrationDistanceData: false,
      heatmapData: [], //data for the heatmap, 2d array (2d histogram of biomarker intensities)
      heatmapYLabels: [], //bin labels for the heatmap, comptued in backend
      heatmapXLabels: [],
      heatmapYBinSize: -1, //bin sizes for heatmap x/y axis, used for displaying hover text range
      heatmapXBinSize: -1,
      showExportSelectionModal: false, //whether the 'export selection data' modal is visible
      showExportMaskModal: false, //whether the 'export binary mask' modal is visible
      includeBMIntensityData: true, //whether to include biomarker intensity data in a selection export
      includeRelativeCellDistances: true, //whether to include relative cell distances in selection export
      includePhenotypeFrequencies: true, //whether to include phenotype frequency data in selection export
      includeInfiltrationDistances: true, //whether to include infiltration distances in the selection export
      computingClusters: false, //whether we're currently computing cell clusters
      fetchingHistData: false, //whether a fetch request is out for hist data
      onlyInSelection: false, //whether to only count cells inside of the rectangular selection for violin plot
      onlyInSelectionRect: true, //if onlyInSelection is true, determines whether to count in rect (true) or poly (false)
      onlyInSelectionSpatial: false, //same as above, but for spatial analysis (pmi, cov)
      onlyInSelectionRectSpatial: false,
      newPhenotypeModalDisplayed: false, //whether or not menu for creating a new phenotype is open
      showOverlayModal: false,
      invertMask: false, //whetehr to invert mask before output
      selectedChannelIdx: -1,
      showCirclePlot: false, //whether to display the circle plot modal
      dataPanelWarning: "", //if theres an issue with data panel selection, goes here
      activeViolinChannels: [], //potentially multiple channels being displayed on violin/boxplot
      spatialNetworkLines: [], // lines connecting neighbors in a selection
      haveVisData: false,
      havePolylineVisData: false, //whether we have visualization data relating to the polyline
      haveSpatialData: false, //whether we have spatial data (covariance, pmi)
      violinData: [],
      infiltrationData: [],
      scatterPlotData: [], //data for the scatterplot (see top of DataPanel for format)
      haveScatterPlotData: false,
      fetchingScatterPlotData: false,
      fetchingSecondSpatialData: false, //whether we're currently fetching data for the SECOND circular plot
      haveSecondSpatialData: false, //whether we have the data for the second plot or not
      circlePlotData: [],
      pmi_range: [-10,10], // minimum/maximum values for pmi
      circlePlotCats: [], //categories (biomarkes, phenotypes) for the circle plot (as strings)
      entropy: 0,
      circlePlotData2: [], //circleplot data / entropy for the second circle plot
      entropy2: 0,
      violinOrBoxplot: true, //true if displaying violin data, false if displaying boxplot
      selectedChannel: "",
      distThresholdSpatial: 150, //distance threshold for cell network
      newPhenotypeBiomarkerThresholds: [0, 0], //threhsolds for biomarkers for a new phenotype, default is 0.5 for 2 new biomarkers
      newPhenotypeWindowThresholds: [[0,0], [0,0]], // thresholds for biomarker phenotype WINDOW (values above first val, below second val)
      newPhenotypeBiomarkers: ["", ""], //name of the biomarkers being used for new phenotype
      biomarkerThresholdOptions: ["median", "median"], //options for how to do thresholding for new phenotype computation (if true, use median)
      biomarkerThresholdAbove: [true, true], //whether the biomarkers will be thresholded above or below the median/value/etc.
      dataPanelDisplayed: false,
      dataPanelTabs: [
        //what tab the data panel is on, summary=0,spatial=1,compare=2...
        { id: 0, label: "Spatial", visible: true },
        { id: 1, label: "Summary", visible: false },
        { id: 2, label: "Compare", visible: false },
      ],
      computingCustomPhenotype: false, //whether we're currently computing a custom phenotype in the backend
      whyPanelDisplayed: false,
      curCustomPhenotypeId:
        saved_custom_pheno_id > 0 ? saved_custom_pheno_id : num_real_channels, //id in channels of the next custom channel to add (added after last real channel)
      num_real_channels: num_real_channels, //won't be changed, probably a better way to do this
      curClusterId: num_real_channels + 20, //channel id of the first cluster channel
      recommendations: false,
      numBinsHistogram: 21,
      numClusters: 5, //how many clusters for clustering local cells in spatial analysis
      numNeighborsSpatial: 10,
      histogramData: [0],
      constrolsDisplayed: true,
      channels: channels,
      case_name: case_name,
      out_save_name: case_name,
      saveCompleted: false,
      activeSelection: "Whole Image", //which selection, IE polygon/polyline/rect. init is whole image
      selectionOptions: [
        { value: "Whole Image", label: "Whole Image" }, //empty selection, IE whole image
        { value: "Polygon", label: "Polygon" },
        { value: "Polyline", label: "Polyline" },
        { value: "Rectangle", label: "Rectangle" },
      ],
      curCellCount: 0, // how many cells are in the current selection for the current channel
      curCellCountTotal: 0, // how many cells are in the current selection in total
      viewportBounds: {}, // current openseadragon viewport boundary
      sampleInfo: false,
      activeOverlay: 0,
      viewport: null,
      rangeSliderComplete: true,
      activeIds: saved_active_channels.length > 0 ? saved_active_channels : [0],
      activeIdsSpatial: [],
      chanLabel: saved_channel_labels
        ? saved_channel_labels
        : new Map(
            channels.map((v, k) => {
              return [
                k,
                {
                  value: k,
                  id: k,
                  label: v,
                },
              ];
            })
          ),
      // chanRender: defaultChanRender,
      chanRender: saved_channel_renders
        ? saved_channel_renders
        : defaultChanRender,
    };

    this.filePath = React.createRef();
    // Bind
    this.finishedRefreshingViewer = this.finishedRefreshingViewer.bind(this);
    this.toggleSecondViewer = this.toggleSecondViewer.bind(this);
    this.changeActiveSlide = this.changeActiveSlide.bind(this);
    this.toggleCaseViewer = this.toggleCaseViewer.bind(this);
    this.handleCurTabControls = this.handleCurTabControls.bind(this);
    this.handleSelectSpatial = this.handleSelectSpatial.bind(this);
    this.addChannel = this.addChannel.bind(this);
    this.handleOutSaveName = this.handleOutSaveName.bind(this);
    this.displaySpatialNetwork = this.displaySpatialNetwork.bind(this);
    this.handleNewPhenotypeWindowThresholds = this.handleNewPhenotypeWindowThresholds.bind(this);
    this.handleActiveSelectionSpatial =
      this.handleActiveSelectionSpatial.bind(this);
    this.handleActiveSelectionSpatialNetwork =
      this.handleActiveSelectionSpatialNetwork.bind(this);
    this.handleActiveSelectionInfiltration =
      this.handleActiveSelectionInfiltration.bind(this);
    this.handleActiveSelectionInfiltrationPolygon =
      this.handleActiveSelectionInfiltrationPolygon.bind(this);
    this.clearScatterPlot = this.clearScatterPlot.bind(this);
    this.clearClinicalData = this.clearClinicalData.bind(this);
    this.fetchClinicalData = this.fetchClinicalData.bind(this);
    this.handleIncludeInfiltrationDistances =
      this.handleIncludeInfiltrationDistances.bind(this);
    this.handleIncludeRelativeCellDistances =
      this.handleIncludeRelativeCellDistances.bind(this);
    this.handleIncludePhenotypeFrequencies =
      this.handleIncludePhenotypeFrequencies.bind(this);
    this.handleIncludeBMIntensityData =
      this.handleIncludeBMIntensityData.bind(this);
    this.handleInvertMask = this.handleInvertMask.bind(this);
    this.toggleExportSelectionModal =
      this.toggleExportSelectionModal.bind(this);
    this.toggleExportMaskModal =
      this.toggleExportMaskModal.bind(this);
    this.handleDataPanelTab = this.handleDataPanelTab.bind(this);
    this.clearCirclePlot = this.clearCirclePlot.bind(this);
    this.computeClusters = this.computeClusters.bind(this);
    this.handleBiomarkerThresholdAbove =
      this.handleBiomarkerThresholdAbove.bind(this);
    this.handleBiomarkerThresholdOptions =
      this.handleBiomarkerThresholdOptions.bind(this);
    this.handleNumClusters = this.handleNumClusters.bind(this);
    this.addBiomarkerForCustomPhenotype =
      this.addBiomarkerForCustomPhenotype.bind(this);
    this.removeBiomarkerForCustomPhenotype =
      this.removeBiomarkerForCustomPhenotype.bind(this);
    this.handleNewPhenotypeBiomarkers =
      this.handleNewPhenotypeBiomarkers.bind(this);
    this.computeCustomPhenotype = this.computeCustomPhenotype.bind(this);
    this.handleNewPhenotypeThreshold =
      this.handleNewPhenotypeThreshold.bind(this);
    this.showNewPhenotypeModal = this.showNewPhenotypeModal.bind(this);
    this.handleSecondCirclePlot = this.handleSecondCirclePlot.bind(this);
    this.handleShowCirclePlot = this.handleShowCirclePlot.bind(this);
    this.fetchDataPanelData = this.fetchDataPanelData.bind(this);
    this.fetchScatterPlotData = this.fetchScatterPlotData.bind(this);
    this.fetchCirclePlotData = this.fetchCirclePlotData.bind(this);
    this.fetchAdditionalViolinData = this.fetchAdditionalViolinData.bind(this);
    this.clearViolinData = this.clearViolinData.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleNumNeighborsSpatial = this.handleNumNeighborsSpatial.bind(this);
    this.handleOnlyInSelection = this.handleOnlyInSelection.bind(this);
    this.handleOnlyInSelectionRect = this.handleOnlyInSelectionRect.bind(this);
    this.handleOnlyInSelectionSpatial =
      this.handleOnlyInSelectionSpatial.bind(this);
    this.handleOnlyInSelectionRectSpatial =
      this.handleOnlyInSelectionRectSpatial.bind(this);
    this.handleViolinOrBoxplot = this.handleViolinOrBoxplot.bind(this);
    this.handleActiveViolinChannels =
      this.handleActiveViolinChannels.bind(this);
    this.handlePhenotype = this.handlePhenotype.bind(this);
    this.handleDistThresholdSpatial =
      this.handleDistThresholdSpatial.bind(this);
    this.handleSelectedChannel = this.handleSelectedChannel.bind(this);
    this.handleActiveSelection = this.handleActiveSelection.bind(this);
    this.interactor = this.interactor.bind(this);
    this.lineClick = this.lineClick.bind(this);
    this.polygonClick = this.polygonClick.bind(this);
    this.maskDrawClick =this.maskDrawClick.bind(this);
    this.deleteOverlay = this.deleteOverlay.bind(this);
    this.deleteLine = this.deleteLine.bind(this);
    this.deletePolygon = this.deletePolygon.bind(this);
    this.deleteMask = this.deleteMask.bind(this);
    this.highlightPolygon = this.highlightPolygon.bind(this);
    this.highlightMask = this.highlightMask.bind(this);
    this.minimizeControls = this.minimizeControls.bind(this);
    this.maximizeControls = this.maximizeControls.bind(this);
    this.boxClick = this.boxClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleDisplayDataPanel = this.handleDisplayDataPanel.bind(this);
    this.handleNumBinsHistogram = this.handleNumBinsHistogram.bind(this);
    //this.handleChannelSelect = this.handleChannelSelect.bind(this);
    this.toggleOverlayModal = this.toggleOverlayModal.bind(this);
    this.toggleDataPanelDisplay = this.toggleDataPanelDisplay.bind(this);
    this.toggleWhyPanelDisplay = this.toggleWhyPanelDisplay.bind(this);
    this.save = this.save.bind(this);
    this.exportSelectionData = this.exportSelectionData.bind(this);
    this.exportMask = this.exportMask.bind(this);
    this.exportScatterplot = this.exportScatterplot.bind(this);
    this.computeInfiltrationDistance =
      this.computeInfiltrationDistance.bind(this);
    this.handleActiveChannelInfiltration =
      this.handleActiveChannelInfiltration.bind(this);
    this.clearInfiltrationData = this.clearInfiltrationData.bind(this);
    this.clearSpatialNetwork = this.clearSpatialNetwork.bind(this);
    this.handleViewport = this.handleViewport.bind(this);
  }

  handleKeyDown(event) {
    switch (event.keyCode) {
      case 27: //Escape key
        //stop drawing
        this.setState({
          drawing: 0,
          drawType: "",
        });
        break;
      default:
        break;
    }
  }

  componentDidMount() {
    const { saved_viewport_bounds } = this.props;
    // If we loaded a save file, change view port to the saved1
    if (Object.keys(saved_viewport_bounds).length > 0) {
      const initial_viewport_bounds = new OpenSeadragon.Rect(
        saved_viewport_bounds.x,
        saved_viewport_bounds.y,
        saved_viewport_bounds.width,
        saved_viewport_bounds.height
      );
      var interval = setInterval(() => {
        if (this.state.viewport) {
          this.state.viewport.fitBounds(initial_viewport_bounds);
          clearInterval(interval);
        }
      }, 300);
    }

    console.log("All case slides is: " + this.state.all_case_slides)

    document.addEventListener("keydown", this.handleKeyDown);
    // start auto saving
    // setInterval(() => {
    //   this.autosave();
    // }, 180000);
  }

  autosave() {
    console.log("auto saving");
    this.save("_autosave");
  }

  handleIncludeInfiltrationDistances() {
    this.setState({
      includeInfiltrationDistances: !this.state.includeInfiltrationDistances,
    });
  }

  handleInvertMask() {
    this.setState({ invertMask: !this.state.invertMask })
  }

  handleIncludeRelativeCellDistances() {
    this.setState({
      includeRelativeCellDistances: !this.state.includeRelativeCellDistances,
    });
  }

  handleIncludePhenotypeFrequencies() {
    this.setState({
      includePhenotypeFrequencies: !this.state.includePhenotypeFrequencies,
    });
  }

  handleIncludeBMIntensityData() {
    this.setState({
      includeBMIntensityData: !this.state.includeBMIntensityData,
    });
  }

  toggleExportSelectionModal() {
    this.setState({
      showExportSelectionModal: !this.state.showExportSelectionModal,
    });
  }

  toggleExportMaskModal() {
    this.setState({
      showExportMaskModal: !this.state.showExportMaskModal,
    });
  }

  toggleOverlayModal() {
    this.setState({
      showOverlayModal: !this.state.showOverlayModal,
    });
  }

  toggleDataPanelDisplay() {
    this.setState({
      dataPanelDisplayed: !this.state.dataPanelDisplayed,
    });
  }

  toggleWhyPanelDisplay() {
    this.setState({
      whyPanelDisplayed: !this.state.whyPanelDisplayed,
    });
  }

  showNewPhenotypeModal() {
    this.setState({
      newPhenotypeModalDisplayed: !this.state.newPhenotypeModalDisplayed,
    });
  }

  handleCurTabControls(newTab) {
    this.setState({ curTabControls: newTab });
  }

  handleSelect(channels) {
    const channelArray = channels ? channels : [];
    const activeIds = channelArray.map((c) => c.id);

    this.setState({
      activeIds,
    });
  }

  handleSelectSpatial(channels) {
    const channelArray = channels ? channels : [];
    const activeIdsSpatial = channelArray.map((c) => c.label);

    this.setState({
      activeIdsSpatial,
    });
  }

  changeActiveSlide(new_slide) {
    this.setState({ active_slide_name: new_slide });
    this.setState({ repoLoading: true });

    // make request to backend to update active slide
    const fetchUrl = `/change_active_slide`;
    const fetchBody = JSON.stringify({
      new_slide: new_slide,
    });

    fetch(fetchUrl, {
      method: "POST",
      body: fetchBody,
      headers: {
        "Content-Type": "application/json",
        "Authorization": this.props.user.signInUserSession.idToken.jwtToken,
      },
    }).then((response) => {
      return response.json();
    })
    .then((json) => {
      console.log("succesfully changed active slide to: " + new_slide);
      // update image info
      let old_img = this.state.img;
      let new_img = old_img;
      new_img.width = json["width"];
      new_img.height = json["height"];
      new_img.maxLevel = json["num_levels"] - 1;
      this.setState({ img: new_img })

      // remove all selections
      this.setState({
      overlays: [], 
      linePoints: [[], []], 
      polygonPoints: [],
      highlightedPolygon: -1,
      highlightedMask: -1,
      maskPoints: [],
      })

      // restart viewer
      this.setState({ refreshViewer: true })
      this.setState({ repoLoading: false });
    });
  }

  finishedRefreshingViewer() { 
    this.setState({ refreshViewer: false })
  }

  computeBounds(value, start, len) {
    const center = start + len / 2;
    const end = start + len;
    // Below center
    if (value < center) {
      return {
        start: value,
        range: end - value,
      };
    }
    // Above center
    return {
      start: start,
      range: value - start,
    };
  }
  //sends scatterplot data to backend to be saved
  exportScatterplot(channels) {
    const { scatterPlotData } = this.state;

    const fetchUrl = `/export_scatterplot`;
    const fetchBody = JSON.stringify({
      scatterPlotData: scatterPlotData,
      channels: channels,
    });

    fetch(fetchUrl, {
      method: "POST",
      body: fetchBody,
      headers: {
        "Content-Type": "application/json",
        "Authorization": this.props.user.signInUserSession.idToken.jwtToken,
      },
    }).then((response) => {
      console.log("succesful export :)");
    });
  }

  //sends request to backend to compute, and save, some simple data about a selection
  exportSelectionData(selections) {
    console.log("exporting selection data for selections " + selections);

    const { activeOverlay } = this.state;
    const { includeBMIntensityData, includePhenotypeFrequencies } = this.state;
    const { includeInfiltrationDistances, includeRelativeCellDistances } =
      this.state;
    const { overlays, polygonPoints } = this.state;
    if (overlays.length === 0) {
      return;
    }
    let line = [];
    if (includeInfiltrationDistances) {
      line = this.state.linePoints;
    }
    //selections contains just strings ('Selection 1 ...'), need to get actual selection objects
    let sel_objs = selections.map((s) => {
      if (s.includes("Polygon")) {
        let polygon_points_ind = parseInt(s.substring("Polygon ".length));
        return { isRect: false, selection: polygonPoints[polygon_points_ind] };
      } else {
        let sel_id = parseInt(s.match(/\d+/)[0]); //get 0,1,2, ...
        return { isRect: true, selection: overlays[sel_id] };
      }
    });
    console.log("exporting results for selections " + sel_objs);

    const fetchUrl = `/export_selection`;
    const fetchBody = JSON.stringify({
      selections: sel_objs,
      includeBMIntensityData: includeBMIntensityData,
      includePhenotypeFrequencies: includePhenotypeFrequencies,
      includeInfiltrationDistances:
        includeInfiltrationDistances && line.length > 0,
      includeRelativeCellDistances: includeRelativeCellDistances,
      line: line,
    });

    this.setState({ repoLoading: true });

    fetch(fetchUrl, {
      method: "POST",
      body: fetchBody,
      headers: {
        "Content-Type": "application/json",
        "Authorization": this.props.user.signInUserSession.idToken.jwtToken,
      },
    }).then((response) => {
      console.log("succesful export :)");
      this.setState({ repoLoading: false });
    });
  }

//sends request to backend to generate and save a mask from selection data
exportMask(selections, filename) {
  console.log("exporting mask for selections " + selections);
  const { overlays, polygonPoints, maskPoints, invertMask } = this.state;
  //selections contains just strings ('Selection 1 ...'), need to get actual selection objects
  let sel_objs = selections.map((s) => {
    if (s.includes("Polygon")) {
      let polygon_points_ind = parseInt(s.substring("Polygon ".length));
      return { type: "polygon", selection: polygonPoints[polygon_points_ind] };
    }else if (s.includes("Freehand")) {
      let polygon_points_ind = parseInt(s.substring("Freehand ".length));
      return { type: "freehand", selection: maskPoints[polygon_points_ind] };
    } else {
      let sel_id = parseInt(s.match(/\d+/)[0]); //get 0,1,2, ...
      return { type: "rect", selection: overlays[sel_id] };
    }
  });
  console.log("exporting mask for selections " + sel_objs);

  const fetchUrl = `/export_mask`;
  const fetchBody = JSON.stringify({
    selections: sel_objs,
    invert: invertMask,
    filename: filename
  });

  this.setState({ repoLoading: true });

  fetch(fetchUrl, {
    method: "POST",
    body: fetchBody,
    headers: {
      "Content-Type": "application/json",
      "Authorization": this.props.user.signInUserSession.idToken.jwtToken,
    },
  }).then((response) => {
    console.log("succesful export :)");
    this.setState({ repoLoading: false });
    this.setState({ showExportMaskModal: false})
  });
}

  handleOutSaveName(e) {
    this.setState({ out_save_name: e.target.value });
  }

  handleActiveSelectionSpatial(m) {
    this.setState({ activeSelectionSpatial: m.label });
  }

  handleActiveSelectionInfiltration(m) {
    this.setState({ activeSelectionInfiltration: m.label });
  }

  handleActiveSelectionInfiltrationPolygon(m) {
    this.setState({ activeSelectionInfiltrationPolygon: m.label });
  }

  handleActiveSelectionSpatialNetwork(m) {
    this.setState({ activeSelectionSpatialNetwork: m.label });
  }

  handleNewPhenotypeBiomarkers(new_bm, ind) {
    const { newPhenotypeBiomarkers } = this.state;
    let tmp = newPhenotypeBiomarkers.map((e) => e);
    tmp[ind] = new_bm;
    this.setState({ newPhenotypeBiomarkers: tmp });
  }

  handleNewPhenotypeThreshold(new_val, ind) {
    const { newPhenotypeBiomarkerThresholds } = this.state;
    let tmp = newPhenotypeBiomarkerThresholds.map((e) => e);
    tmp[ind] = new_val;
    this.setState({ newPhenotypeBiomarkerThresholds: tmp });
  }

  handleNewPhenotypeWindowThresholds(new_val, pheno_ind, window_ind) {
    const { newPhenotypeWindowThresholds } = this.state;
    let tmp = newPhenotypeWindowThresholds.map((e) => e); // copy
    tmp[pheno_ind][window_ind] = new_val;
    this.setState({ newPhenotypeWindowThresholds : tmp});
  }

  handleDisplayDataPanel(event) {
    this.setState({ dataPanelDisplayed: !this.state.dataPanelDisplayed });
  }

  handleNumBinsHistogram(event) {
    let new_bins = parseInt(event.target.value);
    if (isNaN(new_bins)) new_bins = 1;
    this.setState({ numBinsHistogram: new_bins });
  }

  handleDistThresholdSpatial(event) {
    let new_thresh = parseInt(event.target.value);
    this.setState({ distThresholdSpatial: new_thresh });
  }

  handleNumNeighborsSpatial(event) {
    let new_num_neighbors = parseInt(event.target.value);
    this.setState({ numNeighborsSpatial: new_num_neighbors });
  }

  //updates state for selected channel for data panel analysis
  handleSelectedChannel(channel) {
    console.log(channel);
    // if it's a custom channel
    if (channel.id >= this.state.num_real_channels) {
      this.setState({ selectedChannel: channel.label });
      this.setState({ selectedChannelIdx: channel.id });
    } else {
      const selectedChannelName = this.state.channels[channel.id];

      this.setState({ selectedChannel: selectedChannelName });
      this.setState({ selectedChannelIdx: channel.id });
    }
    //reset data panel warning
    this.setState({ dataPanelWarning: "" });
  }

  //remove all circle plot data
  clearCirclePlot() {
    console.log("clearing circle plots");
    this.setState({
      entropy: 0,
      entropy2: 0,
      circlePlotData: [],
      circlePlotCats: [],
      circlePlotData2: [],
      showCirclePlot: false,
      haveSpatialData: false,
    });
  }

  //simply removes all violin data
  clearViolinData() {
    this.setState({ violinData: [] });
    this.setState({ activeViolinChannels: [] });
    this.setState({ dataPanelWarning: "" });
    this.setState({ haveVisData: false });
    this.setState({ havePolylineVisData: false });
    this.setState({ fetchingHistData: false });
  }

  handleShowCirclePlot() {
    this.setState({ showCirclePlot: !this.state.showCirclePlot });
  }
  
  handleActiveChannelInfiltration(chan) {
    console.log(
      "setting active channel infiltration to: " + JSON.stringify(chan)
    );
    this.setState({ activeChannelInfiltration: chan.label });
  }

  displaySpatialNetwork() {
    const { activeSelectionSpatialNetwork } = this.state;
    const { overlays } = this.state;
    console.log("displaying spatial network");

    const fetchUrl = `/draw_spatial_network`;
    // Find the selection
    let selectionSplit = activeSelectionSpatialNetwork.split(" ");
    let overlay_id = parseInt(selectionSplit[selectionSplit.length - 1]);
    let overlay = overlays[overlay_id];
    console.log("overlay_id is: " + overlay_id + " && overlay is: " + overlay);
    let fetchBody = JSON.stringify({
      overlay: overlay,
    });
    fetch(fetchUrl, {
      method: "POST",
      body: fetchBody,
      headers: {
        "Content-Type": "application/json",
        "Authorization": this.props.user.signInUserSession.idToken.jwtToken,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        console.log("success, got data: " + JSON.stringify(json));
        this.setState({ spatialNetworkLines: json["network_lines"] });
      });
  }

  computeInfiltrationDistance() {
    const { activeSelectionInfiltration, activeChannelInfiltration } =
      this.state;
    const { activeSelectionInfiltrationPolygon } =
      this.state;
    const { activeOverlay, overlays, polygonPoints } = this.state;
    const { activeSelection, onlyInSelection, onlyInSelectionRect } =
      this.state;
    let polygon_points_ind = parseInt(activeSelectionInfiltrationPolygon.substring("Polygon ".length));
    
    console.log(
      "computing infiltration distance for selection: " +
        activeSelectionInfiltration +
        " && channel: " +
        activeChannelInfiltration
    );
    this.setState({ computingInfiltrationDistance: true });
    this.setState({ haveInfiltrationDistanceData: false });

    const fetchUrl = `/infiltration_distance`;
    let fetchBody;
    if (activeSelectionInfiltration == "Whole Image") {
      fetchBody = JSON.stringify({
        phenotype: activeChannelInfiltration,
        polygon: polygonPoints[polygon_points_ind],
      });
    } else {
      // figure out what overlay it is
      let selectionSplit = activeSelectionInfiltration.split(" ");
      let overlay_id = parseInt(selectionSplit[selectionSplit.length - 1]);
      let overlay = overlays[overlay_id];
      console.log("overlay_id is: " + overlay_id + " &&overlay is: " + overlay);
      fetchBody = JSON.stringify({
        phenotype: activeChannelInfiltration,
        polygon: polygonPoints[polygon_points_ind],
        overlay: overlay,
      });
    }
    fetch(fetchUrl, {
      method: "POST",
      body: fetchBody,
      headers: {
        "Content-Type": "application/json",
        "Authorization": this.props.user.signInUserSession.idToken.jwtToken,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        let inside_dists = json["inside_poly_dists"];
        let outside_dists = json["outside_poly_dists"];

        let new_infiltration_data = this.state.infiltrationData;
        inside_dists.forEach((e) => {
          new_infiltration_data.push({
            location: "inside",
            distance: e,
          });
        });
        outside_dists.forEach((e) => {
          new_infiltration_data.push({
            location: "outside",
            distance: e,
          });
        });
        this.setState({ infiltrationData: new_infiltration_data });
        this.setState({ computingInfiltrationDistance: false });
        this.setState({ haveInfiltrationDistanceData: true });
      })
      .catch((e) => console.log("Error: " + e));
  }

  clearInfiltrationData() {
    console.log("clearing infiltration data");
    this.setState({ infiltrationData: [] });
    this.setState({ computingInfiltrationDistance: false });
    this.setState({ haveInfiltrationDistanceData: false });
  }

  //send request to compute clusters on the cells
  computeClusters() {
    const { numClusters } = this.state;
    console.log(
      "Sending request to compute " + numClusters + " phenotype clusters."
    );
    this.setState({ computingClusters: true });

    const fetchUrl = `/cluster`;
    const fetchBody = JSON.stringify({
      num_clusters: numClusters,
    });
    fetch(fetchUrl, {
      method: "POST",
      body: fetchBody,
      headers: {
        "Content-Type": "application/json",
        "Authorization": this.props.user.signInUserSession.idToken.jwtToken,
      },
    })
      .then((response) => {
        console.log("Success");
        this.setState({ computingClusters: false });
        //add all of the computed cluster channels to active channels
        let t_ids = this.state.activeIds.map((e) => e);
        let cur_id = this.state.curClusterId;
        for (let i = 0; i < numClusters; i++) {
          t_ids.push(cur_id);
          cur_id += 1;
        }
        this.setState({ activeIds: t_ids });
        this.setState({ curClusterId: cur_id });
      })
      .catch((e) => console.log("Error: " + e));
  }

  handleViewport(viewport) {
    this.setState({ viewport: viewport });
  }

  //send request to the backend to compute the new custom phenotype, and adds it to channels to be
  //  displayed on success
  computeCustomPhenotype() {
    const { newPhenotypeBiomarkerThresholds, newPhenotypeBiomarkers } =
      this.state;
    const { biomarkerThresholdOptions, biomarkerThresholdAbove } = this.state;
    const {newPhenotypeWindowThresholds } = this.state;
    console.log(
      "Sending request to compute custom phenotype for biomarkers " +
        newPhenotypeBiomarkers +
        " and thresholds " +
        newPhenotypeBiomarkerThresholds
    );
    //compute a string label for the phenotype ("CD20+CD68-")
    let new_label = "";
    newPhenotypeBiomarkers.forEach((e, i) => {
      let pm = biomarkerThresholdAbove[i] ? "+" : "-";
      new_label = new_label + e + pm;
    });

    // let options_strings = biomarkerThresholdOptions.map((opt) => {
    //   if (opt) {
    //     return "median";
    //   } else {
    //     return "value";
    //   }
    // });
    let options_strings = biomarkerThresholdOptions;

    const fetchUrl = `/compute_custom_phenotype`;
    const custom_channel_id =
      this.state.curCustomPhenotypeId - this.state.num_real_channels;
    const fetchBody = JSON.stringify({
      biomarkers: newPhenotypeBiomarkers,
      thresholds: newPhenotypeBiomarkerThresholds,
      id: custom_channel_id,
      threshold_options: options_strings,
      above_below: biomarkerThresholdAbove,
      label: new_label,
      windows: newPhenotypeWindowThresholds,
    });

    this.setState({ computingCustomPhenotype: true, repoLoading: true });

    fetch(fetchUrl, {
      method: "POST",
      body: fetchBody,
      headers: {
        "Content-Type": "application/json",
        "Authorization": this.props.user.signInUserSession.idToken.jwtToken,
      },
    })
      .then((response) => {
        console.log("got response " + JSON.stringify(response));
        this.showNewPhenotypeModal();
        //add to list of active channel ids
        let t_ids = this.state.activeIds.map((e) => e);
        console.log(
          "finished computing custom phenotype " +
            this.state.curCustomPhenotypeId
        );
        t_ids.push(this.state.curCustomPhenotypeId);
        //update label display from 'Phenotype i' to the names of the biomarkers in the phenotype
        this.handleChange(
          this.state.curCustomPhenotypeId,
          null,
          null,
          new_label,
          null
        );

        this.setState({ activeIds: t_ids });
        this.setState({
          curCustomPhenotypeId: this.state.curCustomPhenotypeId + 1,
        });
        this.setState({ computingCustomPhenotype: false, repoLoading: false });
      })
      .catch((e) => console.log("Error: " + e));
  }

  handleBiomarkerThresholdAbove(i) {
    let curAboveOrBelow = this.state.biomarkerThresholdAbove;
    curAboveOrBelow[i] = !curAboveOrBelow[i];
    this.setState({ biomarkerThresholdAbove: curAboveOrBelow });
  }

  //just flip the option, currently its just a boolean
  handleBiomarkerThresholdOptions(event, i) {
    let curOptions = this.state.biomarkerThresholdOptions;
    // curOptions[i] = !curOptions[i];
    curOptions[i] = event.target.id;
    this.setState({ biomarkerThresholdOptions: curOptions });
  }

  handleNumClusters(e) {
    console.log("updating num clustes to " + e.target.value);
    this.setState({ numClusters: e.target.value });
  }

  handleDataPanelTab(tab_id) {
    let tmp_tabs = this.state.dataPanelTabs;
    //set them all false
    tmp_tabs.forEach((t) => (t.visible = false));
    //only set the one we want visible
    tmp_tabs[tab_id].visible = true;
    this.setState({ dataPanelTabs: tmp_tabs });
  }

  //fetch data for the second circle plot, to compare
  //  almost identical to fetchCircleplotData
  handleSecondCirclePlot(selection) {
    const { activeOverlay, overlays } = this.state;
    const {
      activeSelection,
      onlyInSelectionSpatial,
      onlyInSelectionRectSpatial,
    } = this.state;
    const { distThresholdSpatial, numNeighborsSpatial, polygonPoints } =
      this.state;
    const { activeIdsSpatial, maskPoints } = this.state

    let active_overlay = [];
    let this_onlyInSelectionRect = false;
    let active_polygon;
    if (selection.value.includes("Polygon")) {
      let active_polygon_ind = parseInt(selection.value.substring("Polygon ".length));
      active_polygon = polygonPoints[active_polygon_ind];
    } else if (selection.value.includes("Freehand")) {
      let active_polygon_ind = parseInt(selection.value.substring("Freehand ".length));
      active_polygon = maskPoints[active_polygon_ind];
    } else {
      //if its not a polygon, its a rect. find which rect it is
      let active_rect_id = parseInt(selection.value);
      active_overlay = overlays[active_rect_id];
      this_onlyInSelectionRect = true;
      console.log("active_rect_id : " + active_rect_id);
    }
    console.log("active_overlay: " + active_overlay);
    console.log("active_polygon: " + active_polygon);

    const fetchUrl = `/spatial`;
    const fetchBody = JSON.stringify({
      overlay: active_overlay,
      polygon: active_polygon,
      onlyInSelection: true,
      onlyInSelectionRect: this_onlyInSelectionRect,
      distThreshold: distThresholdSpatial,
      numNeighbors: numNeighborsSpatial,
      phenotypes: activeIdsSpatial,
    });

    this.setState({ fetchingSecondSpatialData: true });

    fetch(fetchUrl, {
      method: "POST",
      body: fetchBody,
      headers: {
        "Content-Type": "application/json",
        "Authorization": this.props.user.signInUserSession.idToken.jwtToken,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        let pmi = json["pmi"];
        let cov = json["cov"];
        let cols = json["cols"];
        let entropy = json["ent"];

        console.log("Got entropy: " + entropy);

        this.setState({ circlePlotData2: pmi });
        // this.setState({ circlePlotData2: cov });
        this.setState({ entropy2: entropy });
        this.setState({ haveSecondSpatialData: true });
        this.setState({ fetchingSecondSpatialData: false });
      });
  }

  //just flips the boolean value of onlyInSelection state variable
  handleOnlyInSelection() {
    const { overlays, polygonPoints } = this.state;
    //check if a rectangle or polygon selection exist
    //  if onlyInSelection is currently false, that means the user is trying
    //  to set it to true, check if thats feasible
    if (
      !this.state.onlyInSelection &&
      overlays.length === 0 &&
      polygonPoints[0].length === 0
    ) {
      this.setState({
        dataPanelWarning: "No rectangular or polygon selection",
      });
      return;
    }

    this.setState({ dataPanelWarning: "" });
    this.setState({ onlyInSelection: !this.state.onlyInSelection });
  }

  handleOnlyInSelectionRect() {
    this.setState({ onlyInSelectionRect: !this.state.onlyInSelectionRect });
  }

  //same 2 functions as above, just for spatial analysis options instead
  handleOnlyInSelectionSpatial() {
    const { overlays, polygonPoints } = this.state;
    if (
      !this.state.onlyInSelectionSpatial &&
      overlays.length === 0 &&
      polygonPoints[0].length === 0
    ) {
      this.setState({
        dataPanelWarning: "No rectangular or polygon selection",
      });
      return;
    }
    console.log(
      "Changing onlyInSelectionSpatial to : " +
        !this.state.onlyInSelectionSpatial
    );
    this.setState({ dataPanelWarning: "" });
    this.setState({
      onlyInSelectionSpatial: !this.state.onlyInSelectionSpatial,
    });
  }

  handleOnlyInSelectionRectSpatial() {
    this.setState({
      onlyInSelectionRectSpatial: !this.state.onlyInSelectionRectSpatial,
    });
  }

  handleViolinOrBoxplot() {
    this.setState({ violinOrBoxplot: !this.state.violinOrBoxplot });
  }

  handleActiveViolinChannels(chan) {
    let newChannels = this.state.activeViolinChannels;
    //only add if it doesn't already exist
    newChannels.indexOf(chan) === -1
      ? newChannels.push(chan)
      : console.log("" + chan + " already in activeViolinChannels");
    this.setState({ activeViolinChannels: newChannels });
    console.log("Active violin channels: " + newChannels);
  }

  clearClinicalData() {
    this.setState({ clinicalData: {}, haveClinicalData: false });
  }
  
  fetchClinicalData() {
    const { activeSelection, overlays } = this.state;

    // figure out what overlay it is
    let selectionSplit = activeSelection.split(" ");
    let overlay_id = parseInt(selectionSplit[selectionSplit.length - 1]);
    let overlay = overlays[overlay_id];
    console.log("fetching clinical data for overlay: " + overlay_id);

    const fetchUrl = `/clinical_data`;
    const fetchBody = JSON.stringify({
      overlay: overlay,
    });

    this.setState({ fetchingHistData: true });

    fetch(fetchUrl, {
      method: "POST",
      body: fetchBody,
      headers: {
        "Content-Type": "application/json",
        "Authorization": this.props.user.signInUserSession.idToken.jwtToken,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        console.log("got clinical data: " + JSON.stringify(json));
        this.setState({ clinicalData: json, haveClinicalData: true });
        this.setState({ fetchingHistData: false });
      })
      .catch((e) => console.log("Error: " + e));
  }
  
  //handle fetching data for an additional channel of the violin plot
  fetchAdditionalViolinData(chan) {
    const { activeOverlay, overlays, polygonPoints } = this.state;
    const { activeSelection, onlyInSelection, onlyInSelectionRect } =
      this.state;

    // check to see if it is a custom channel
    let selectedChannelName;
    if (chan.id >= this.state.num_real_channels) {
      selectedChannelName = chan.label;
    } else {
      selectedChannelName = this.state.channels[chan.id];
    }

    this.handleActiveViolinChannels(selectedChannelName);

    console.log("Fetching additional data for channel " + selectedChannelName);

    let fetchBody;
    //if we're only counting inside the rectangular selection (also default behavior)
    if (onlyInSelectionRect) {
      fetchBody = JSON.stringify({
        line: this.state.linePoints,
        channel: selectedChannelName,
        overlay: overlays[activeOverlay],
        onlyInSelection: onlyInSelection,
      });
    }
    //else, counting polygon selection
    else {
      fetchBody = JSON.stringify({
        line: this.state.linePoints,
        channel: selectedChannelName,
        polygon: polygonPoints,
        onlyInSelection: onlyInSelection,
      });
    }
    let fetchUrl = `/violin`;

    this.setState({ fetchingHistData: true });
    this.setState({ haveVisData: false });

    const tmp_this = this;
    fetch(fetchUrl, {
      method: "POST",
      body: fetchBody,
      headers: {
        "Content-Type": "application/json",
        "Authorization": this.props.user.signInUserSession.idToken.jwtToken,
      },
    })
      .then((response) => response.json())
      .then(function (json) {
        tmp_this.setState({ fetchingHistData: false });

        let new_violin_dists = json["dists"];
        let new_violin_acts = json["activations"];
        //convert violin data into correct form
        let violin_data = tmp_this.state.violinData;
        new_violin_dists.forEach((e) => {
          violin_data.push({
            biomarker: selectedChannelName,
            distance: e,
          });
        });
        tmp_this.setState({ violinData: violin_data });
        //console.log('Violin data at [0]: ' + JSON.stringify(violin_data[0]));
      });
  }

  //handles fetching the spatial heterogeneity data
  //  this is its own function currently, separately from fetchDataPanel,
  //  since computing heterogeneity takes much longer
  fetchCirclePlotData() {
    const { activeOverlay, overlays, polygonPoints } = this.state;
    //const { activeSelection, onlyInSelectionSpatial, onlyInSelectionRectSpatial } = this.state;
    const { distThresholdSpatial, numNeighborsSpatial } = this.state;
    const { activeSelectionSpatial } = this.state;
    const { activeIdsSpatial, maskPoints } = this.state

    //get selection object from selection string ]
    let sel_obj, sel_rect, onlyinselect;
    let active_polygon;
    if (activeSelectionSpatial.includes("Polygon")) {
      let active_polygon_ind = parseInt(activeSelectionSpatial.substring("Polygon ".length));
      active_polygon = polygonPoints[active_polygon_ind]
      sel_obj = polygonPoints[active_polygon_ind];
      sel_rect = false;
      onlyinselect = true;
    } else if (activeSelectionSpatial.includes("Freehand")) {
      let active_polygon_ind = parseInt(activeSelectionSpatial.substring("Freehand ".length));
      active_polygon = maskPoints[active_polygon_ind]
      sel_obj = maskPoints[active_polygon_ind];
      sel_rect = false;
      onlyinselect = true;
    }
    else if (activeSelectionSpatial.includes("Whole Image")) {
      sel_obj = null;
      sel_rect = false;
      onlyinselect = false;
    } else {
      //else its a rectangle, find which one
      let sel_id = parseInt(activeSelectionSpatial.match(/\d+/)[0]); //get 0,1,2, ...
      sel_obj = overlays[sel_id];
      sel_rect = true;
      onlyinselect = true;
    }

    const fetchUrl = `/spatial`;
    const fetchBody = JSON.stringify({
      overlay: overlays[activeOverlay],
      polygon: active_polygon,
      onlyInSelection: onlyinselect,
      onlyInSelectionRect: sel_rect,
      distThreshold: distThresholdSpatial,
      numNeighbors: numNeighborsSpatial,
      phenotypes: activeIdsSpatial,
    });

    fetch(fetchUrl, {
      method: "POST",
      body: fetchBody,
      headers: {
        "Content-Type": "application/json",
        "Authorization": this.props.user.signInUserSession.idToken.jwtToken,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        let pmi = json["pmi"];
        let pmi_range = json["pmi_range"];
        let cov = json["cov"];
        let cols = json["cols"];
        let entropy = json["ent"];

        this.setState({ circlePlotData: pmi });
        this.setState({ pmi_range: pmi_range });
        // this.setState({ circlePlotData: cov });
        this.setState({ entropy: entropy });
        this.setState({ circlePlotCats: cols });
        this.setState({ haveSpatialData: true });
      });
  }

  // forcibly add a channel
  addChannel(chan) {
    console.log("adding channel" + chan);
    const { activeIds, channels } = this.state;
    let t_ids = activeIds;

    if (chan.includes("FP")) {
      channels.forEach((c, i) => {
        if (c == chan) {
          t_ids.push(i);
        }
      });
    } else {
      channels.forEach((c, i) => {
        if (c == chan) {
          t_ids.push(i);
        }
      });
    }
    this.setState({ activeIds: t_ids });
  }

  clearScatterPlot() {
    this.setState({ haveScatterPlotData: false, scatterPlotData: [] });
  }

  clearSpatialNetwork() {
    this.setState({ spatialNetworkLines: [] });
  }

  //handles communicating with the backend to get data for the
  // scatter plot. takes in  the two channels to fetch
  fetchScatterPlotData(channels, selection) {
    const { overlays, polygonPoints, maskPoints } = this.state;

    console.log("fetching scatterplot data for channels " + channels);

    this.setState({
      haveScatterPlotData: false,
      fetchingScatterPlotData: true,
    });
    //get selection object from selection string ]
    let sel_obj, sel_rect;
    if (selection.includes("Polygon")) {
      let active_polygon = parseInt(selection.substring("Polygon ".length));
      sel_obj = polygonPoints[active_polygon];
      sel_rect = false;
    } else if (selection.includes("Freehand")) {
      let active_polygon = parseInt(selection.substring("Freehand ".length));
      sel_obj = maskPoints[active_polygon];
      sel_rect = false;
    } else if (selection.includes("Whole Image")) {
      sel_obj = [0, 0, 5, 5];
      sel_rect = true;
    } else {
      //else its a rectangle, find which one
      let sel_id = parseInt(selection.match(/\d+/)[0]); //get 0,1,2, ...
      sel_obj = overlays[sel_id];
      sel_rect = true;
    }

    const fetchUrl = `/scatterplot`;
    const fetchBody = JSON.stringify({
      channel_1: channels[0],
      channel_2: channels[1],
      selection: sel_obj,
      sel_rect: sel_rect, //whether the selection is a rectangle (true) or polygon (false)
    });
    fetch(fetchUrl, {
      method: "POST",
      body: fetchBody,
      headers: {
        "Content-Type": "application/json",
        "Authorization": this.props.user.signInUserSession.idToken.jwtToken,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        this.setState({
          haveScatterPlotData: true,
          fetchingScatterPlotData: false,
        });
        let new_scatter_data = json["data"];
        let new_heatmap_data = json["heatmap_data"];
        //x,y labels for heatmap bins
        let x_labels = json["x_labels"];
        let y_labels = json["y_labels"];
        let x_bin_size = json["x_bin_size"];
        let y_bin_size = json["y_bin_size"];

        this.setState({ scatterPlotData: new_scatter_data });
        this.setState({ heatmapData: new_heatmap_data });
        this.setState({ heatmapXLabels: x_labels });
        this.setState({ heatmapYLabels: y_labels });
        this.setState({ heatmapYBinSize: y_bin_size });
        this.setState({ heatmapXBinSize: x_bin_size });
      });
  }

  //handles communicating with backend to get some of the data for the data panel
  // (histogram data, violin plot data, etc. )
  fetchDataPanelData() {
    const { activeOverlay, overlays, polygonPoints, maskPoints } = this.state;
    const { activeSelection, onlyInSelection, onlyInSelectionRect } =
      this.state;
    // const selectedChannelName = this.state.channels[this.state.selectedChannelIdx];
    const selectedChannelName = this.state.selectedChannel;

    //if no channel is selected
    if (this.state.selectedChannelIdx === -1) {
      this.setState({ dataPanelWarning: "Please select a channel" });
      return;
    }
    //if a selection has not been made yet
    if (activeSelection.includes("Selection") && overlays.length == 0) {
      this.setState({ dataPanelWarning: "No rectangular selection" });
      return;
    } else if (activeSelection.includes("Polygon") && polygonPoints.length == 0) {
      this.setState({ dataPanelWarning: "No polygon selection" });
      return;
    } else if (
      activeSelection == "Polyline" &&
      this.state.linePoints[0].length == 0
    ) {
      this.setState({ dataPanelWarning: "No polyline selection" });
      return;
    }

    this.setState({ haveVisData: false, fetchingHistData: true });

    console.log(
      "Fetching data panel data for selection: " +
        activeSelection +
        " and channel: " +
        selectedChannelName
    );

    let fetchBody = "";
    let fetchBody2 = ""; //might make multiple api calls
    let fetchUrl = "";
    if (activeSelection.includes("Selection")) {
      console.log("activeOverlay is: " + activeOverlay);
      fetchBody = JSON.stringify({
        overlay: overlays[activeOverlay],
        channel: selectedChannelName,
      });
      fetchUrl = `/histograms`;
      //additional call for spatial information (entropy, network...)
      fetchBody2 = JSON.stringify({
        overlay: overlays[activeOverlay],
      });
    } else if (activeSelection.includes("Polygon")) {
      let active_polygon = parseInt(activeSelection.substring("Polygon ".length));
      console.log("Active polygon is: " + active_polygon);
      fetchBody = JSON.stringify({
        polygon: polygonPoints[active_polygon],
        channel: selectedChannelName,
      });
      fetchUrl = `/histograms`;
      //additional call for spatial information (entropy, network...)
      fetchBody2 = JSON.stringify({
        overlay: overlays[activeOverlay],
      });
    } else if (activeSelection.includes("Freehand")) {
      let active_fh = parseInt(activeSelection.substring("Freehand ".length));
      console.log("Active freehand is: " + active_fh);
      fetchBody = JSON.stringify({
        polygon: maskPoints[active_fh],
        channel: selectedChannelName,
      });
      fetchUrl = `/histograms`;
      //additional call for spatial information (entropy, network...)
      fetchBody2 = JSON.stringify({
        overlay: overlays[activeOverlay],
      });
    }
    else if (activeSelection == "Whole Image") {
      fetchBody = JSON.stringify({
        channel: selectedChannelName,
      });
      fetchUrl = `/histograms`;
      //additional call for spatial information (entropy, network...)
      fetchBody2 = JSON.stringify({
        overlay: "",
      });
    } else if (activeSelection == "Polyline") {
      //if we're only looking to count inside a rectangular selection:
      if (onlyInSelectionRect) {
        //check to make sure we have a rectangle to send along
        if (onlyInSelection && overlays.length === 0) {
          this.setState({ dataPanelWarning: "No rectangular selection" });
          this.setState({ fetchingHistData: false });
          return;
        }

        fetchBody = JSON.stringify({
          line: this.state.linePoints,
          channel: selectedChannelName,
          overlay: overlays[activeOverlay],
          onlyInSelection: onlyInSelection,
        });
      }
      //else, count inside the polygon selection
      else {
        //check to make sure we have a polygon
        if (onlyInSelection && polygonPoints[0].length === 0) {
          this.setState({ dataPanelWarning: "No polygon selection" });
          this.setState({ fetchingHistData: false });
          return;
        }

        fetchBody = JSON.stringify({
          line: this.state.linePoints,
          channel: selectedChannelName,
          polygon: polygonPoints,
          onlyInSelection: onlyInSelection,
        });
      }
      fetchUrl = `/violin`;
    }

    //send request to backend
    let new_hist_data;
    const tmp_this = this; //workaround

    this.setState({ fetchingHistData: true });
    this.setState({ dataPanelWarning: "" });
    this.handleActiveViolinChannels(selectedChannelName);

    if (activeSelection == "Polyline") {
      fetch(fetchUrl, {
        method: "POST",
        body: fetchBody,
        headers: {
          "Content-Type": "application/json",
          "Authorization": this.props.user.signInUserSession.idToken.jwtToken,
        },
      })
        .then((response) => response.json())
        .then(function (json) {
          tmp_this.setState({ fetchingHistData: false });
          let new_violin_dists = json["dists"];
          let new_violin_acts = json["activations"];
          //tmp_this.setState({ haveVisData: true });
          tmp_this.setState({ havePolylineVisData: true });
          tmp_this.setState({ fetchingHistData: false });
          //convert violin data into correct form
          let violin_data = [];
          new_violin_dists.forEach((e) => {
            violin_data.push({
              biomarker: selectedChannelName,
              distance: e,
            });
          });
          tmp_this.setState({ violinData: violin_data });
        });
    }
    //else, for rectangle/polygon/whole image selections...
    else {
      let hist_fetch = fetch(fetchUrl, {
        method: "POST",
        body: fetchBody,
        headers: {
          "Content-Type": "application/json",
          "Authorization": this.props.user.signInUserSession.idToken.jwtToken,
        },
      });

      hist_fetch
        .then((response) => response.json())
        .then(function (json) {
          tmp_this.setState({ fetchingHistData: false });
          new_hist_data = json["hist_data"];
          let new_cell_total = json["cell_total"];
          //update this.state.histogramData
          tmp_this.setState({ histogramData: new_hist_data });
          tmp_this.setState({ haveVisData: true });
          tmp_this.setState({ curCellCount: new_hist_data.length });
          tmp_this.setState({ curCellCountTotal: new_cell_total });
        });
    }
    //refactored
  }

  drawLowerBounds(position) {
    const wh = [0, 0];
    const new_xy = [position.x, position.y];
    const newOverlay = new_xy.concat(wh);

    const { overlays } = this.state;

    let new_overlays = overlays.concat([newOverlay]);
    this.setState({ overlays: new_overlays });
  }

  drawUpperBounds(position) {
    let new_overlays = this.state.overlays;

    const overlay = new_overlays.pop();

    const xy = overlay.slice(0, 2);
    const wh = overlay.slice(2);

    // Set actual bounds
    const x = this.computeBounds(position.x, xy[0], wh[0]);
    const y = this.computeBounds(position.y, xy[1], wh[1]);

    const newOverlay = [x.start, y.start, x.range, y.range];

    new_overlays = new_overlays.concat([newOverlay]);
    this.setState({ overlays: new_overlays });
  }

  //minimize controls panel
  minimizeControls() {
    this.setState({
      constrolsDisplayed: false,
    });
  }

  maximizeControls() {
    this.setState({
      constrolsDisplayed: true,
    });
  }

  drawPolygon(position) {
    const new_xy = [position.x, position.y];

    let new_polygonPoints = this.state.polygonPoints;
    let polyind = new_polygonPoints.length-1
    new_polygonPoints[polyind][0] = new_polygonPoints[polyind][0].concat([position.x]);
    new_polygonPoints[polyind][1] = new_polygonPoints[polyind][1].concat([position.y]);
    this.setState({ polygonPoints: new_polygonPoints });
  }

  addMaskPt(position) {
    const new_xy = [position.x, position.y];

    let new_maskPoints = this.state.maskPoints;
    let maskind = new_maskPoints.length-1
    new_maskPoints[maskind][0] = new_maskPoints[maskind][0].concat([position.x]);
    new_maskPoints[maskind][1] = new_maskPoints[maskind][1].concat([position.y]);
    this.setState({ maskPoints: new_maskPoints });
  }

  deletePolygon(i) {
    let polygon_points = this.state.polygonPoints;
    polygon_points.splice(i, 1);
    this.setState({ polygonPoints: polygon_points });
  }

  deleteMask(i) {
    let mask_points = this.state.maskPoints;
    mask_points.splice(i, 1);
    this.setState({ maskPoints: mask_points });
  }

  highlightPolygon(i) {
    let hl = i == this.state.highlightedPolygon ? -1 : i;
    this.setState({ highlightedPolygon: hl });
  }

  highlightMask(i) {
    let hm = i == this.state.highlightedMask ? -1 : i;
    this.setState({ highlightedMask: hm });
  }

  drawLine(position) {
    let new_linePoints = this.state.linePoints;
    new_linePoints[0] = new_linePoints[0].concat([position.x]);
    new_linePoints[1] = new_linePoints[1].concat([position.y]);
    this.setState({ linePoints: new_linePoints });
  }

  deleteLine() {
    this.setState({ linePoints: [[], []] });
  }

  deleteOverlay(i) {
    let new_overlays = this.state.overlays;
    new_overlays.splice(i, 1);
    this.setState({ overlays: new_overlays });
  }

  interactor(viewer) {
    viewer.addHandler(
      "canvas-click",
      function (e) {
        const THIS = e.userData;
        const { drawing, drawType } = THIS.state;

        if (drawType == "line") {
          if (drawing == 1) {
            const position = normalize(viewer, e.position);
            THIS.drawLine(position);
            e.preventDefaultAction = true;
            viewer.setMouseNavEnabled(true);
          }
        } else if (drawType == "polygon") {
          if (drawing == 1) {
            const position = normalize(viewer, e.position);
            THIS.drawPolygon(position);
            e.preventDefaultAction = true;
            viewer.setMouseNavEnabled(true);
          }
        }
      },
      this
    );

    viewer.addHandler(
      "canvas-drag",
      function (e) {
        const THIS = e.userData;
        const { drawing, drawType } = THIS.state;
        const { maskPoints } = THIS.state;

        if (drawType == "box") {
          const position = normalize(viewer, e.position);
          // user is drawing a box
          if (drawing == 1) {
            THIS.setState({ drawing: 2 });
            e.preventDefaultAction = true;
            THIS.drawLowerBounds(position);
          } else if (drawing == 2) {
            e.preventDefaultAction = true;
            THIS.drawUpperBounds(position);
          }
        }
        else if (drawType == "pencil") {
          const position = normalize(viewer, e.position);
          e.preventDefaultAction = true;
          if (drawing == 1) {
            // first point, create new mask object, add first pt
            THIS.setState({ drawing: 2 });
            let mp = maskPoints;
            mp = mp.concat([[[position.x],[position.y]]]);
            THIS.setState({maskPoints: mp})
          } else if (drawing == 2) {
            // otherwise, add new pt if distance is far enough
            let lst_pt_x = maskPoints.slice(-1)[0][0]
            let lst_pt_y = maskPoints.slice(-1)[0][1]
            THIS.addMaskPt(position)
          }
        }
        else if (drawType == "polygon") {
          // dont move viewer
          e.preventDefaultAction = true;
        }
      },
      this
    );
    // user is done drawing the box
    viewer.addHandler(
      "canvas-drag-end",
      function (e) {
        const THIS = e.userData;
        const { drawing, drawType, maskPoints } = THIS.state;

        if (drawType == "box") {
          const position = normalize(viewer, e.position);
          if (drawing == 2) {
            e.preventDefaultAction = true;
            THIS.drawUpperBounds(position);
            THIS.setState({ drawing: 0, drawType: "" });
          }
        }
        else if (drawType == "pencil") {
          const position = normalize(viewer, e.position);
          console.log("done drawing mask " + maskPoints[0].length)
          e.preventDefaultAction = true;
          // unclick pencil
          THIS.maskDrawClick()
        }
      },
      this
    );
  }

  boxClick() {
    const { drawType } = this.state;
    const _drawType = drawType == "box" ? "" : "box";
    this.setState({ drawType: _drawType });
    const _drawing = _drawType == "" ? 0 : 1;
    this.setState({ drawing: _drawing });
    // in case they had mask draw mode
    this.setState({ mouseNavEnabled: true });
  }
  // line = basically a polygon, but not forced to be closed
  lineClick() {
    const { drawType } = this.state;
    const _drawType = drawType == "line" ? "" : "line";
    this.setState({ drawType: _drawType });
    const _drawing = _drawType == "" ? 0 : 1;
    this.setState({ drawing: _drawing });
    // in case they had mask draw mode
    this.setState({ mouseNavEnabled: true });
  }
  //polygon = click on points, connect together to form a polygon
  polygonClick() {
    const { drawType, polygonPoints } = this.state;
    if(drawType != "polygon"){
      // create new polygon object
      let polygon_points = polygonPoints;
      polygon_points = polygon_points.concat([[[],[]]])
      this.setState({polygonPoints: polygon_points})
    }
    else{
      //if user clicked off polygon draw without drawing any points, delete the polygon
        if(polygonPoints[polygonPoints.length-1][0].length == 0){
          let polygon_points = polygonPoints;
          polygon_points.pop()
          this.setState({polygonPoints: polygon_points})
      }
    }
    const _drawType = drawType == "polygon" ? "" : "polygon";
    this.setState({ drawType: _drawType });
    const _drawing = _drawType == "" ? 0 : 1;
    this.setState({ drawing: _drawing });
    // in case they had mask draw mode
    this.setState({ mouseNavEnabled: true });
  }

  maskDrawClick() {
    const { drawType } = this.state;
    const _drawType = drawType == "pencil" ? "" : "pencil";
    this.setState({ drawType: _drawType });
    const _drawing = _drawType == "" ? 0 : 1;
    this.setState({ drawing: _drawing });
    this.setState({ mouseNavEnabled: true });
  }

  // handle any change to a channel render
  handleChange(id, color, range, label, visible, changeComplete = true) {
    const { chanRender, chanLabel } = this.state;
    let newRender = { ...chanRender.get(id) };

    const newLabel = { ...chanLabel.get(id) };

    if (color) {
      newRender["color"] = color;
    }
    if (range) {
      newRender["range"] = range;
    }
    if (label !== null) {
      newLabel["label"] = label;
    }
    if (visible !== null) {
      newRender["visible"] = visible;
    }
    const newChanLabel = new Map([...chanLabel, ...new Map([[id, newLabel]])]);

    this.setState({
      chanLabel: newChanLabel,
    });

    const newChanRender = new Map([
      ...chanRender,
      ...new Map([[id, newRender]]),
    ]);

    this.setState({
      chanRender: newChanRender,
      rangeSliderComplete: changeComplete,
    });
  }
  // save the current state of the program
  save(suffix) {
    const {
      chanRender,
      chanLabel,
      activeIds,
      viewport,
      out_save_name,
      case_name,
      overlays,
      linePoints,
      polygonPoints,
      curCustomPhenotypeId,
    } = this.state;
    let viewportBounds = { ...viewport.getBounds() };

    let save_name = suffix == "" ? out_save_name : case_name + suffix;
    const _this = this;

    fetch(`/api/tumormapr_save`, {
      method: "POST",
      body: JSON.stringify({
        save_name: save_name,
        channel_renders: [...chanRender],
        channel_labels: [...chanLabel],
        active_channels: activeIds,
        viewport_bounds: viewportBounds,
        overlays: overlays,
        linePoints: linePoints,
        polygonPoints: polygonPoints,
        curCustomPhenotypeId: curCustomPhenotypeId,
      }),
      headers: {
        "Content-Type": `application/json`,
        "Authorization": this.props.user.signInUserSession.idToken.jwtToken,
      },
    })
      .then((response) => response.json())
      .then(function (json) {
        console.log("save succesful");
        if (suffix != "_autosave") {
          _this.setState({ saveCompleted: true });
          setTimeout(() => {
            _this.setState({ saveCompleted: false });
          }, 3000);
        }
      });
  }
  // updates the selection on the main screen of the data panel (summary tab)
  handleActiveSelection(e) {
    const { overlays, polygonPoints } = this.state;
    console.log("Setting active selection to : " + e);
    //check if they are making a selection taht does not exist
    if (e === "Polyline" && this.state.linePoints[0].length === 0) {
      this.setState({ dataPanelWarning: "No polyline selection" });
      return;
    } else if (e.includes("Polygon") && polygonPoints.length === 0) {
      this.setState({ dataPanelWarning: "No polygon selection" });
      return;
    } else if (e.includes("Selection") && overlays.length === 0) {
      this.setState({ dataPanelWarning: "No rectangular selection" });
      return;
    }

    this.setState({
      activeSelection: e,
    });
    // If it's a selection, update active overlay
    if (e.includes("Selection")) {
      let selection_id = parseInt(e.substring("Selection ".length));
      this.setState({ activeOverlay: selection_id });
    }
    //reset warnings too
    this.setState({ dataPanelWarning: "" });
  }

  //
  //When a phenotype button is pressed (tumor, immune cell, etc.)
  //  Should try to combine this with computeCustomPhenotype, essentially the same logic,
  //  this function just assumes a predefined phenotype has been clicked
  handlePhenotype(e) {
    let phenotype = e.target.id; //tumor, epithelial, etc.
    console.log("Handling phenotype: " + phenotype);
    let biomarkers;
    let thresholds;
    let label;
    let options;
    let above_or_below;
    if (phenotype == "tumor") {
      biomarkers = ["bm_pck26", "bm_E_cad", "bm_EPCAM"];
      thresholds = [60, 75, 75];
      label = "Tumor";
      options = ["percentile", "percentile", "percentile"];
      above_or_below = [true, true, true];
    } else if (phenotype == "tcell") {
      biomarkers = ["bm_CD3"];
      thresholds = [90];
      label = "T-Cell";
      options = ["percentile"];
      above_or_below = [true];
    } else if (phenotype == "bcell") {
      biomarkers = ["bm_CD20"];
      thresholds = [95];
      label = "B-Cell";
      options = ["percentile"];
      above_or_below = [true];
    } else if (phenotype == "stroma") {
      biomarkers = ["bm_SMA", "bm_ColIV"];
      thresholds = [75, 75];
      label = "Stroma";
      options = ["percentile", "percentile"];
      above_or_below = [true, true];
    } else if (phenotype == "macrophages") {
      biomarkers = ["bm_CD163", "bm_CD68"];
      thresholds = [75, 75];
      label = "Macrophages";
      options = ["percentile", "percentile"];
      above_or_below = [true, true];
    } else {
      console.log("error, phenotype not recognized");
      return;
    }
    console.log("Making request for phenotype with biomarker/s: " + biomarkers);
    const fetchUrl = `/compute_custom_phenotype`;
    const custom_channel_id =
      this.state.curCustomPhenotypeId - this.state.num_real_channels;
    const fetchBody = JSON.stringify({
      biomarkers: biomarkers,
      thresholds: thresholds,
      id: custom_channel_id,
      threshold_options: options,
      above_below: above_or_below,
      label: label,
    });

    this.setState({ computingCustomPhenotype: true, repoLoading: true });

    fetch(fetchUrl, {
      method: "POST",
      body: fetchBody,
      headers: {
        "Content-Type": "application/json",
        "Authorization": this.props.user.signInUserSession.idToken.jwtToken,
      },
    })
      .then((response) => {
        console.log("got response " + JSON.stringify(response));
        //add to list of active channel ids
        let t_ids = this.state.activeIds.map((e) => e);
        console.log(
          "finished computing custom phenotype " +
            this.state.curCustomPhenotypeId
        );
        t_ids.push(this.state.curCustomPhenotypeId);
        //update label display from 'Phenotype i' to the names of the biomarkers in the phenotype
        // let new_label = phenotype + ' - ';
        // biomarkers.forEach((e,i) => {
        //   let pm ='+';
        //   new_label = new_label + e + pm
        // });
        let new_label = label;
        this.handleChange(
          this.state.curCustomPhenotypeId,
          null,
          null,
          new_label,
          null
        );

        this.setState({ activeIds: t_ids });
        this.setState({
          curCustomPhenotypeId: this.state.curCustomPhenotypeId + 1,
        });
        this.setState({ computingCustomPhenotype: false, repoLoading: false });
      })
      .catch((e) => console.log("Error: " + e));
  }

  toggleSecondViewer() {
    console.log("Toggling second viewer")
    if (this.state.secondViewerActive) {
      // make main viewer take up full screen
      this.setState({ viewer1Width: "100%" })
    }
    else {
      this.setState({ viewer1Width: "50%" })
    }
    this.setState({ secondViewerActive : !this.state.secondViewerActive })
    //change styles
  }

  toggleCaseViewer() {
    console.log("Toggling case viewer")
    if (this.state.caseViewerActive) {
      // hide case viewer
      this.setState({ viewer1Width: "100%" })
    }
    else {
      this.setState({ viewer1Width: "80%" })
    }
    this.setState({ caseViewerActive: !this.state.caseViewerActive })
  }

  addBiomarkerForCustomPhenotype() {
    //simply increase the size of the biomarker names array and thresholds array
    const { newPhenotypeBiomarkers, newPhenotypeBiomarkerThresholds } =
      this.state;
    const { newPhenotypeWindowThresholds } = this.state
    const { biomarkerThresholdOptions, biomarkerThresholdAbove } = this.state;
    let t_names = newPhenotypeBiomarkers.map((e) => e);
    t_names.push(" ");
    let t_threshs = newPhenotypeBiomarkerThresholds.map((e) => e);
    t_threshs.push(0.5);
    let t_options = biomarkerThresholdOptions.map((e) => e);
    t_options.push(true);
    let t_above = biomarkerThresholdAbove.map((e) => e);
    t_above.push(true);
    let t_window = newPhenotypeWindowThresholds.map((e) => e);
    t_window.push([0, 0])
    this.setState({
      newPhenotypeBiomarkers: t_names,
      newPhenotypeBiomarkerThresholds: t_threshs,
      biomarkerThresholdOptions: t_options,
      biomarkerThresholdAbove: t_above,
      newPhenotypeWindowThresholds: t_window,
    });
  }

  removeBiomarkerForCustomPhenotype(i) {
    console.log("removing biomarker" + i);
    const { newPhenotypeBiomarkers, newPhenotypeBiomarkerThresholds } =
      this.state;
    const { newPhenotypeWindowThresholds } = this.state;
    const { biomarkerThresholdOptions, biomarkerThresholdAbove } = this.state;
    let t_names = newPhenotypeBiomarkers.filter((e, ind) => ind != i);
    let t_threshs = newPhenotypeBiomarkerThresholds.filter(
      (e, ind) => ind != i
    );
    let t_options = biomarkerThresholdOptions.filter((e, ind) => ind != i);
    let t_above = biomarkerThresholdAbove.filter((e, ind) => ind != i);
    let t_window = newPhenotypeWindowThresholds.filter((e, ind) => ind != i)
    console.log("before: " + newPhenotypeBiomarkers + " after: " + t_names);
    this.setState({
      newPhenotypeBiomarkers: t_names,
      newPhenotypeBiomarkerThresholds: t_threshs,
      biomarkerThresholdOptions: t_options,
      biomarkerThresholdAbove: t_above,
      newPhenotypeWindowThresholds: t_window,
    });
  }

  render() {
    const { img, chanLabel } = this.state;
    const { chanRender, activeIds } = this.state;
    const { spatialNetworkLines } = this.state;
    // get render settings for all active channels
    let activeChanRender = new Map(
      activeIds.map((a) => [a, chanRender.get(a)])
    );
    // get labels for all active channels
    const activeChanLabel = new Map(
      activeIds.map((a) => [a, chanLabel.get(a)])
    );
    // combine into shape [id, {label, render}]
    const activeChannels = new Map(
      activeIds.map((a) => [
        a,
        {
          ...activeChanLabel.get(a),
          ...activeChanRender.get(a),
        },
      ])
    );
    // filter only visible channels
    let visibleChannels = new Map(
      [...activeChannels].filter(([k, v]) => v.visible)
    );

    let viewer = (
      <ImageView className="ImageView"
        img={ img }
        mouseNavEnabled={this.state.mouseNavEnabled}
        channels={ visibleChannels }
        overlays={ this.state.overlays } arrows={ [] }
        handleViewport={ this.handleViewport }
        interactor={ this.interactor }
        rotation={0}
        maskOpacity={0}
        drawing={this.state.drawing}
        drawType={this.state.drawType}
        highlightedPolygon={this.state.highlightedPolygon}
        highlightedMask={this.state.highlightedMask}
        polygonPoints={this.state.polygonPoints}
        maskPoints={this.state.maskPoints}
        linePoints={this.state.linePoints}
        spatialNetworkLines={spatialNetworkLines}
        user={this.props.user}
        secondViewerActive={this.state.secondViewerActive}
        viewer1Width={this.state.viewer1Width}
        refreshViewer={this.state.refreshViewer}
        finishedRefreshingViewer={this.finishedRefreshingViewer}
      />
    )

    let viewer2 = this.state.secondViewerActive ? (
      <ImageView2 className="ImageView"
        img={ img }
        mouseNavEnabled={this.state.mouseNavEnabled}
        channels={ visibleChannels }
        overlays={ this.state.overlays } arrows={ [] }
        handleViewport={ this.handleViewport }
        interactor={ this.interactor }
        rotation={0}
        maskOpacity={0}
        drawing={this.state.drawing}
        drawType={this.state.drawType}
        highlightedPolygon={this.state.highlightedPolygon}
        highlightedMask={this.state.highlightedMask}
        polygonPoints={this.state.polygonPoints}
        maskPoints={this.state.maskPoints}
        linePoints={this.state.linePoints}
        spatialNetworkLines={spatialNetworkLines}
        user={this.props.user}
        refreshViewer={this.state.refreshViewer}
        finishedRefreshingViewer={this.finishedRefreshingViewer}
      />
    ) : null;


    let checkIconClass = this.state.saveCompleted
      ? "check-icon-fadeIn"
      : "check-icon-fadeOut";

    let caseViewer = this.state.caseViewerActive ? (
      <CaseViewer all_case_slides={this.state.all_case_slides} 
        active_slide_name={this.state.active_slide_name}
        changeActiveSlide={this.changeActiveSlide}>
      </CaseViewer>
    ): null;

    let saveButton = (
      <Popup
        trigger={
          <button className="ui button primary" title={"Save"}>
            <FontAwesomeIcon icon={faSave} />
            &nbsp; Save&nbsp;
          </button>
        }
        on="click"
        hoverable
      >
        Enter desired save name:
        <div>
          <input
            type="text"
            className="save-name-input"
            placeholder={this.state.case_name}
            value={this.state.out_save_name}
            onChange={this.handleOutSaveName}
          />
          .sav
        </div>
        <div>
          <button
            className="ui button primary"
            onClick={() => this.save("")}
            title={"Save"}
          >
            <FontAwesomeIcon icon={faSave} />
            &nbsp; Save&nbsp;
          </button>
          <FontAwesomeIcon
            className={checkIconClass}
            icon={faCheck}
            color="green"
            size="2x"
            style={{ marginLeft: "10px", marginRight: "10px" }}
          />
        </div>
      </Popup>
    );

    let channelsButton =
      this.state.curTabControls == "channels"
        ? "ui button"
        : "ui button active";
    let exploreButton =
      this.state.curTabControls == "explore" ? "ui button" : "ui button active";
    let recommendationButton =
      this.state.curTabControls == "recommendation"
        ? "ui button"
        : "ui button active";
    let tabBar = (
      <div className="row">
        <span className="ui buttons">
          <button
            className={channelsButton}
            onClick={() => this.handleCurTabControls("channels")}
          >
            Channels
          </button>
          <button
            className={exploreButton}
            onClick={() => this.handleCurTabControls("explore")}
          >
            Explore
          </button>
          {/*
          <button
            className={recommendationButton}
            onClick={() => this.handleCurTabControls("recommendation")}
          >
            Recommendation
          </button>
          */}
          {saveButton}
        </span>

        <div
          className="repo-loader"
          style={{ display: this.state.repoLoading ? "block" : "none" }}
        >
          <Loader active={this.state.repoLoading} />
          <label className="repo-loader-label">Loading...</label>
        </div>
      </div>
    )

    return (
      <div className="container-fluid Repo">
        {viewer}
        {viewer2}
        {caseViewer}
        <CirclePlot
          toggle={this.handleShowCirclePlot}
          show={this.state.showCirclePlot}
          activeIdsSpatial={this.state.activeIdsSpatial}
          handleSelectSpatial={this.handleSelectSpatial}
          haveSpatialData={this.state.haveSpatialData}
          activeSelectionSpatial={this.state.activeSelectionSpatial}
          circlePlotData={this.state.circlePlotData}
          pmi_range={this.state.pmi_range}
          entropy={this.state.entropy}
          circlePlotCats={this.state.circlePlotCats}
          overlays={this.state.overlays}
          polygonPoints={this.state.polygonPoints}
          maskPoints={this.state.maskPoints}
          handleSecondCirclePlot={this.handleSecondCirclePlot}
          fetchingSecondSpatialData={this.state.fetchingSecondSpatialData}
          haveSecondSpatialData={this.state.haveSecondSpatialData}
          circlePlotData2={this.state.circlePlotData2}
          entropy2={this.state.entropy2}
          clearCirclePlot={this.clearCirclePlot}
        ></CirclePlot>
        <DataPanel
          toggle={this.toggleDataPanelDisplay}
          show={this.state.dataPanelDisplayed}
          data={this.state.histogramData}
          overlay={this.state.activeOverlay}
          activeSelectionSpatialNetwork={
            this.state.activeSelectionSpatialNetwork
          }
          handleActiveSelectionSpatialNetwork={
            this.handleActiveSelectionSpatialNetwork
          }
          displaySpatialNetwork={this.displaySpatialNetwork}
          spatialNetworkLines={this.state.spatialNetworkLines}
          handleSelectSpatial={this.handleSelectSpatial}
          activeIdsSpatial={this.state.activeIdsSpatial}
          clearSpatialNetwork={this.clearSpatialNetwork}
          selectedChannel={this.state.selectedChannel}
          chanLabel={this.state.chanLabel}
          activeSelection={this.state.activeSelection}
          selectionOptions={this.state.selectionOptions}
          channels={this.state.channels}
          handleSelectedChannel={this.handleSelectedChannel}
          handleActiveSelection={this.handleActiveSelection}
          histData={this.state.histogramData}
          fetchingHistData={this.state.fetchingHistData}
          fetchDataPanelData={this.fetchDataPanelData}
          clinicalData={this.state.clinicalData}
          haveClinicalData={this.state.haveClinicalData}
          clearClinicalData={this.clearClinicalData}
          fetchClinicalData={this.fetchClinicalData}
          haveVisData={this.state.haveVisData}
          violinData={this.state.violinData}
          clearViolinData={this.clearViolinData}
          onlyInSelection={this.state.onlyInSelection}
          handleOnlyInSelection={this.handleOnlyInSelection}
          fetchAdditionalViolinData={this.fetchAdditionalViolinData}
          hcColor={hcColor}
          onlyInSelectionRect={this.state.onlyInSelectionRect}
          handleOnlyInSelectionRect={this.handleOnlyInSelectionRect}
          violinOrBoxplot={this.state.violinOrBoxplot}
          handleViolinOrBoxplot={this.handleViolinOrBoxplot}
          activeViolinChannels={this.state.activeViolinChannels}
          handleActiveViolinChannels={this.handleActiveViolinChannels}
          numBinsHistogram={this.state.numBinsHistogram}
          handleNumBinsHistogram={this.handleNumBinsHistogram}
          fetchCirclePlotData={this.fetchCirclePlotData}
          warning={this.state.dataPanelWarning}
          havePolylineVisData={this.state.havePolylineVisData}
          onlyInSelectionSpatial={this.state.onlyInSelectionSpatial}
          handleOnlyInSelectionSpatial={this.handleOnlyInSelectionSpatial}
          onlyInSelectionRectSpatial={this.state.onlyInSelectionRectSpatial}
          handleOnlyInSelectionRectSpatial={
            this.handleOnlyInSelectionRectSpatial
          }
          distThresholdSpatial={this.state.distThresholdSpatial}
          handleDistThresholdSpatial={this.handleDistThresholdSpatial}
          numNeighborsSpatial={this.state.numNeighborsSpatial}
          handleNumNeighborsSpatial={this.handleNumNeighborsSpatial}
          handleShowCirclePlot={this.handleShowCirclePlot}
          numClusters={this.state.numClusters}
          handleNumClusters={this.handleNumClusters}
          computingClusters={this.state.computingClusters}
          computeClusters={this.computeClusters}
          dataPanelTabs={this.state.dataPanelTabs}
          cell_data_columns={this.props.cell_data_columns}
          handleDataPanelTab={this.handleDataPanelTab}
          scatterPlotData={this.state.scatterPlotData}
          fetchScatterPlotData={this.fetchScatterPlotData}
          haveScatterPlotData={this.state.haveScatterPlotData}
          fetchingScatterPlotData={this.state.fetchingScatterPlotData}
          overlays={this.state.overlays}
          polygonPoints={this.state.polygonPoints}
          maskPoints={this.state.maskPoints}
          linePoints={this.state.linePoints}
          clearScatterPlot={this.clearScatterPlot}
          exportScatterplot={this.exportScatterplot}
          heatmapData={this.state.heatmapData}
          heatmapXLabels={this.state.heatmapXLabels}
          heatmapYLabels={this.state.heatmapYLabels}
          heatmapXBinSize={this.state.heatmapXBinSize}
          heatmapYBinSize={this.state.heatmapYBinSize}
          activeSelectionSpatial={this.state.activeSelectionSpatial}
          activeSelectionInfiltration={this.state.activeSelectionInfiltration}
          activeSelectionInfiltrationPolygon={this.state.activeSelectionInfiltrationPolygon}
          handleActiveSelectionSpatial={this.handleActiveSelectionSpatial}
          handleActiveSelectionInfiltration={
            this.handleActiveSelectionInfiltration
          }
          handleActiveSelectionInfiltrationPolygon={
            this.handleActiveSelectionInfiltrationPolygon
          }
          num_real_channels={this.state.num_real_channels}
          selectedChannelIdx={this.state.selectedChannelIdx}
          curCellCount={this.state.curCellCount}
          curCellCountTotal={this.state.curCellCountTotal}
          computeInfiltrationDistance={this.computeInfiltrationDistance}
          handleActiveChannelInfiltration={this.handleActiveChannelInfiltration}
          activeChannelInfiltration={this.state.activeChannelInfiltration}
          computingInfiltrationDistance={
            this.state.computingInfiltrationDistance
          }
          haveInfiltrationDistanceData={this.state.haveInfiltrationDistanceData}
          infiltrationData={this.state.infiltrationData}
          clearInfiltrationData={this.clearInfiltrationData}
        ></DataPanel>
        <WhyPanel
          toggle={this.toggleWhyPanelDisplay}
          show={this.state.whyPanelDisplayed}
        ></WhyPanel>
        { this.props.cell_data_columns != null ? 
          <NewPhenotypePanel
            toggle={this.showNewPhenotypeModal}
            show={this.state.newPhenotypeModalDisplayed}
            chanLabel={this.state.chanLabel}
            cell_data_columns={this.props.cell_data_columns}
            newPhenotypeBiomarkerThresholds={
              this.state.newPhenotypeBiomarkerThresholds
            }
            handleNewPhenotypeThreshold={this.handleNewPhenotypeThreshold}
            newPhenotypeBiomarkers={this.state.newPhenotypeBiomarkers}
            handleNewPhenotypeBiomarkers={this.handleNewPhenotypeBiomarkers}
            newPhenotypeWindowThresholds={this.state.newPhenotypeWindowThresholds}
            handleNewPhenotypeWindowThresholds={this.handleNewPhenotypeWindowThresholds}
            computeCustomPhenotype={this.computeCustomPhenotype}
            loading={this.state.computingCustomPhenotype}
            addBiomarkerForCustomPhenotype={this.addBiomarkerForCustomPhenotype}
            removeBiomarkerForCustomPhenotype={
              this.removeBiomarkerForCustomPhenotype
            }
            biomarkerThresholdOptions={this.state.biomarkerThresholdOptions}
            handleBiomarkerThresholdOptions={this.handleBiomarkerThresholdOptions}
            biomarkerThresholdAbove={this.state.biomarkerThresholdAbove}
            handleBiomarkerThresholdAbove={this.handleBiomarkerThresholdAbove}
          ></NewPhenotypePanel> : null
        }
        <ExportSelectionModal
          show={this.state.showExportSelectionModal}
          toggle={this.toggleExportSelectionModal}
          includeBMIntensityData={this.state.includeBMIntensityData}
          handleIncludeBMIntensityData={this.handleIncludeBMIntensityData}
          includePhenotypeFrequencies={this.state.includePhenotypeFrequencies}
          handleIncludePhenotypeFrequencies={
            this.handleIncludePhenotypeFrequencies
          }
          includeRelativeCellDistances={this.state.includeRelativeCellDistances}
          handleIncludeRelativeCellDistances={
            this.handleIncludeRelativeCellDistances
          }
          includeInfiltrationDistances={this.state.includeInfiltrationDistances}
          handleIncludeInfiltrationDistances={
            this.handleIncludeInfiltrationDistances
          }
          overlays={this.state.overlays}
          polygonPoints={this.state.polygonPoints}
          exportSelectionData={this.exportSelectionData}
        ></ExportSelectionModal>

        <ExportMaskModal
          show={this.state.showExportMaskModal}
          toggle={this.toggleExportMaskModal}
          overlays={this.state.overlays}
          polygonPoints={this.state.polygonPoints}
          maskPoints={this.state.maskPoints}
          invertMask={this.state.invertMask}
          handleInvertMask={this.handleInvertMask}
          exportMask={this.exportMask}
          active_slide_name={this.state.active_slide_name}
        ></ExportMaskModal>

        <Scale />

      <div className="newviewerbtn">
       <span id="max-switch" className="nav-item">
        <a className="btn" onClick={this.toggleSecondViewer} title="Open second viewer">
         <FontAwesomeIcon
          icon={faImage}
          size={"2x"}
          color={"white"}
          className="fa-icon"
         />
        </a>
       </span>
      </div>

      <div className="opencaseviewer">
       <span id="max-switch" className="nav-item">
        <a className="btn" onClick={this.toggleCaseViewer} title="Open case viewer">
         <FontAwesomeIcon
          icon={faFileArchive}
          size={"2x"}
          color={"white"}
          className="fa-icon"
         />
        </a>
       </span>
      </div>

        <button className="ui button back" onClick={this.props.unload}>
          Back
        </button>

        <div className="row" style={{"margin-top": "30px"}}>
          <div className="col-md-6 col-lg-6 col-xl-4 bg-trans">
            <img
              className="img-fluid w-50"
              style={{
                marginBottom: "10px",
                marginLeft: "-10px",
                paddingTop: "10px",
              }}
              src="image/tumorMaprBasicLogo.png"
            ></img>
          </div>
        </div>
        <ControlsModal
          show={this.state.constrolsDisplayed}
          channels={this.state.channels}
          handleMinimize={this.minimizeControls}
          handleMaximize={this.maximizeControls}
          deleteOverlay={this.deleteOverlay}
          addChannel={this.addChannel}
          num_fps={this.state.num_fps}
          deleteLine={this.deleteLine}
          deletePolygon={this.deletePolygon}
          deleteMask={this.deleteMask}
          highlightPolygon={this.highlightPolygon}
          highlightMask={this.highlightMask}
          drawType={this.state.drawType}
          boxClick={this.boxClick}
          lineClick={this.lineClick}
          polygonClick={this.polygonClick}
          maskDrawClick={this.maskDrawClick}
          handleChange={this.handleChange}
          handleSelect={this.handleSelect}
          curTabControls={this.state.curTabControls}
          chanLabel={chanLabel}
          activeChanLabel={activeChanLabel}
          activeChannels={activeChannels}
          overlays={this.state.overlays}
          linePoints={this.state.linePoints}
          polygonPoints={this.state.polygonPoints}
          maskPoints={this.state.maskPoints}
          handlePhenotype={this.handlePhenotype}
          tabBar={tabBar}
          setState={this.setState}
          dataPanelDisplayed={this.state.dataPanelDisplayed}
          handleDisplayDataPanel={this.handleDisplayDataPanel}
          handleActiveSelection={this.handleActiveSelection}
          recommendations={this.state.recommendations}
          toggleWhyPanelDisplay={this.toggleWhyPanelDisplay}
          showNewPhenotypeModal={this.showNewPhenotypeModal}
          activeIds={this.state.activeIds}
          toggleExportSelectionModal={this.toggleExportSelectionModal}
          toggleExportMaskModal={this.toggleExportMaskModal}
          has_cell_data={this.state.has_cell_data}
          has_cell_labels={this.state.has_cell_labels}
        />
      </div>
    );
  }
}
export default Repo;
