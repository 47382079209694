import React, { Component } from "react";

import { Amplify, Auth } from 'aws-amplify';

import { Authenticator, useTheme, View, Image } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import TmrApp from "./app";
import awsmobile from "../aws-exports"
Amplify.configure(awsmobile);


const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image
          alt="TumorMapr Basic Logo"
          src="image/tumorMaprBasicLogo_dark.png"
        />
      </View>
    );
  }
}

export default function AmplifyAuth() {
  return (
    <Authenticator
      hideSignUp={true}
      components={components}
    >
      {({ signOut, user }) => (
        <main>
          <TmrApp user={ user } signOut={ signOut } />
        </main>

      )}
    </Authenticator>
  );
}
