import React, { Component } from "react";

import Select from "react-select";

import HuePicker from "../components/huepicker";
import ChannelControls from "./channelcontrols";
import FileBrowserModal from "../components/filebrowsermodal";
import Overlays from "./overlays";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { faShareAlt } from "@fortawesome/free-solid-svg-icons";
import { faCrosshairs } from "@fortawesome/free-solid-svg-icons";
import { faPlus, faSave } from "@fortawesome/free-solid-svg-icons";
import { faDrawPolygon } from "@fortawesome/free-solid-svg-icons";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { faExpand } from "@fortawesome/free-solid-svg-icons";
import { faAlignJustify } from "@fortawesome/free-solid-svg-icons";

import "../style/controls";

class Controls extends Component {
 constructor(props) {
  super();
 }

 render() {
  const { curTabControls, channels } = this.props;
  const { handleSelect, handleChange } = this.props;
  const { activeChanLabel, chanLabel } = this.props;
  const { activeChannels } = this.props;
  const { deleteOverlay, deleteLine, deletePolygon } = this.props;
  const { overlays } = this.props;
  const { boxClick, drawType } = this.props;
  const { polygonClick, lineClick, maskDrawClick } = this.props;
  const { addOverlayText } = this.props;
  const { handlePhenotype } = this.props;
  const { linePoints, polygonPoints, maskPoints } = this.props;
  const { toggleWhyPanelDisplay } = this.props;
  const { showNewPhenotypeModal } = this.props;
  const { addChannel, has_cell_labels, has_cell_data } = this.props;

  let stainPresets =(
   <div className="col-12">
    <label className="row font-white">
     Cell Types from Biomarker Thresholding:{" "}
    </label>
    <div className="row">
        {/*
     <div className="ui icon buttons pheno">
      <button
       className="ui button pheno"
       id="tumor"
       title="PCK26+ ECad+ EpCAM+"
       onClick={handlePhenotype}
      >
       Tumor
      </button>
      <button class="ui button">
       <FontAwesomeIcon icon={faAlignJustify} />
      </button>
     </div>
     <div className="ui icon buttons pheno">
      <button
       className="ui button pheno"
       id="tcell"
       title="CD3+"
       onClick={handlePhenotype}
      >
       T-Cells
      </button>
      <button class="ui button">
       <FontAwesomeIcon icon={faAlignJustify} />
      </button>
     </div>
     <div className="ui icon buttons pheno">
      <button
       className="ui button pheno"
       id="bcell"
       title="CD20+"
       onClick={handlePhenotype}
      >
       B-Cells
      </button>
      <button class="ui button">
       <FontAwesomeIcon icon={faAlignJustify} />
      </button>
     </div>
     <div className="ui icon buttons pheno">
      <button
       className="ui button pheno"
       id="stroma"
       title="SMA+ Collagen+"
       onClick={handlePhenotype}
      >
       Stroma
      </button>
      <button class="ui button">
       <FontAwesomeIcon icon={faAlignJustify} />
      </button>
     </div>
     <div className="ui icon buttons pheno">
      <button
       className="ui button pheno"
       id="macrophages"
       title="CD163+ CD68+"
       onClick={handlePhenotype}
      >
       Macrophages
      </button>
      <button class="ui button">
       <FontAwesomeIcon icon={faAlignJustify} />
      </button>
     </div>
*/}
     <div className="ui icon buttons pheno">
      <button class="ui button" onClick={showNewPhenotypeModal} disabled={!has_cell_labels}>
       <FontAwesomeIcon icon={faPlus} />
      </button>
     </div>
    </div>
   </div>
  );


  let advancedButtons = (
      <div className="col-12">
        <label className="row font-white">
          Draw H&amp;E{" "}
        </label>
        <div className="row">
          <div className="ui icon buttons pheno">
            <button
              className="ui button pheno"
              id="Level1"
              title=""
              onClick={() => {
                let he_chans = channels.filter((c)=>c.toLowerCase().includes("red") || c.toLowerCase().includes("blue") || c.toLowerCase().includes("green"));
                for (var c in he_chans) this.props.addChannel(he_chans[c]);
              }}
            >
              H&amp;E
            </button>
          </div>
        </div>
        
     <label className="row font-white">
        Draw All FPs{" "}
      </label>
        <div className="row">
          <div className="ui icon buttons pheno">
            <button
              className="ui button pheno"
              id="Level1"
              title=""
              // onClick={() => {
              //   [...Array(this.props.num_fps + 1).keys()].map((i) => {
              //        this.props.addChannel("FP " + i);
              //   })
              // }}
              onClick={() => {
                Array.from(chanLabel.values()).map((chan) => {
                  chan.label.includes("FP") && !chan.label.includes("Level") ? this.props.addChannel(chan.label) : null;
                })
              }}
            >
              All FPs
            </button>
          </div>
         </div>

      </div>
  )

  if (curTabControls == "recommendation") {
   return (
    <div className="ui form">
     <div className="row">
      <div className="col">
       <h3 className="font-white">Therapy Recommendations:</h3>
       <p className="rec">Oxaliplatin with 5-fluorouracil or Capecitabine</p>
       <h3 className="font-white">Therapy Duration:</h3>
       <p className="rec">6 Months</p>
       <h3 className="font-white">3 year DFS Projection confidence:</h3>
       <p className="rec">94%</p>
      </div>
     </div>

     <div className="row">
      <button className="fluid ui button why" onClick={toggleWhyPanelDisplay}>
       Why?
      </button>
     </div>
    </div>
   );
  } else if (curTabControls == "explore") {
   return !has_cell_data ? 
   ( // no cell data, only render mask stuff
    <div className="ui form">
     <div className="row">
      <div className="col no-right-padding"></div>
     </div>

     <div className="button-cont" style={{ margin: "10px" }}>
      <button
       className="ui button primary"
       title={"Export binary mask"}
       onClick={this.props.toggleExportMaskModal}
      >
       <FontAwesomeIcon icon={faSave} />
       &nbsp; Export Binary Mask&nbsp;
      </button>
     </div>

     <label className="font-white">Selection tools:</label>
     <div className="col-1 p-0">
      <div className="btn-group">
       <span id="draw-switch" className="nav-item draw-switch">
        <a className="btn" onClick={boxClick} title="Select rectangle">
         <FontAwesomeIcon
          icon={faExpand}
          size={"3x"}
          color={drawType == "box" ? "blue" : "white"}
         />
        </a>
       </span>
       <span id="polygon-switch" className="nav-item polygon-switch">
        <a className="btn" onClick={polygonClick} title="Select polygon">
         <FontAwesomeIcon
          icon={faDrawPolygon}
          size={"3x"}
          color={drawType == "polygon" ? "blue" : "white"}
         />
        </a>
       </span>
       <span id="mask-switch" className="nav-item mask-switch">
        <a className="btn" onClick={maskDrawClick} title="Draw mask">
         <FontAwesomeIcon
          icon={faPencilAlt}
          size={"3x"}
          color={drawType == "pencil" ? "blue" : "white"}
         />
        </a>
       </span>
       </div>
     </div>

     <Overlays
      deleteOverlay={deleteOverlay}
      deleteLine={deleteLine}
      deletePolygon={deletePolygon}
      deleteMask={this.props.deleteMask}
      highlightPolygon={this.props.highlightPolygon}
      highlightMask={this.props.highlightMask}
      polygonPoints={polygonPoints}
      maskPoints={maskPoints}
      hasLine={linePoints[0].length > 0}
      addOverlayText={addOverlayText}
      overlays={overlays}
      handleActiveSelection={this.props.handleActiveSelection}
     ></Overlays>
    </div>
   ):( // has cell data
    <div className="ui form">
     <div className="row">
      <div className="col no-right-padding"></div>
     </div>

     <div className="button-cont" style={{ margin: "10px" }}>
      <button
       className="ui button"
       id="brs"
       onClick={this.props.handleDisplayDataPanel}
      >
       Show Data Panel
      </button>

      <button
       className="ui button primary"
       title={"Export selection data"}
       onClick={this.props.toggleExportSelectionModal}
      >
       <FontAwesomeIcon icon={faSave} />
       &nbsp; Export Selection Data&nbsp;
      </button>
      <button
       className="ui button primary"
       title={"Export binary mask"}
       onClick={this.props.toggleExportMaskModal}
      >
       <FontAwesomeIcon icon={faSave} />
       &nbsp; Export Binary Mask&nbsp;
      </button>
     </div>

     <label className="font-white">Selection tools:</label>
     <div className="col-1 p-0">
      <div className="btn-group">
       <span id="draw-switch" className="nav-item draw-switch">
        <a className="btn" onClick={boxClick} title="Select rectangle">
         <FontAwesomeIcon
          icon={faExpand}
          size={"3x"}
          color={drawType == "box" ? "blue" : "white"}
         />
        </a>
       </span>
       <span id="polygon-switch" className="nav-item polygon-switch">
        <a className="btn" onClick={polygonClick} title="Select polygon">
         <FontAwesomeIcon
          icon={faDrawPolygon}
          size={"3x"}
          color={drawType == "polygon" ? "blue" : "white"}
         />
        </a>
       </span>
       <span id="mask-switch" className="nav-item mask-switch">
        <a className="btn" onClick={maskDrawClick} title="Draw mask">
         <FontAwesomeIcon
          icon={faPencilAlt}
          size={"3x"}
          color={drawType == "pencil" ? "blue" : "white"}
         />
        </a>
       </span>
       {/*
       <span id="line-switch" className="nav-item line-switch">
        <a className="btn" onClick={lineClick} title="Draw polyline">
         <FontAwesomeIcon
          icon={faShareAlt}
          size={"3x"}
          color={drawType == "line" ? "blue" : "white"}
         />
        </a>
       </span>
      */}
       </div>
     </div>

     <Overlays
      deleteOverlay={deleteOverlay}
      deleteLine={deleteLine}
      deletePolygon={deletePolygon}
      deleteMask={this.props.deleteMask}
      highlightPolygon={this.props.highlightPolygon}
      highlightMask={this.props.highlightMask}
      polygonPoints={polygonPoints}
      maskPoints={maskPoints}
      hasLine={linePoints[0].length > 0}
      addOverlayText={addOverlayText}
      overlays={overlays}
      handleActiveSelection={this.props.handleActiveSelection}
     ></Overlays>
    </div>
   );
  } else if (curTabControls == "channels") {
   let vis_channels = Array.from(activeChanLabel.values());

   return (
    <div className="">
     <div className="row">
      <div className="col">
       <label className="font-white">Visible Channels:</label>
      </div>
     </div>
     <div className="row">
      <div className="col">
       <Select
        isMulti={true}
        onChange={handleSelect}
        value={vis_channels}
        options={Array.from(chanLabel.values()).filter((chan) => {
         return (
          !chan.label.includes("Cluster") && !chan.label.includes("Phenotype")
         );
        })}
       />
       <div>
        <ChannelControls
         className="ChannelControls"
         channels={activeChannels}
         handleChange={handleChange}
        />
       </div>
       {advancedButtons}
       {stainPresets}
      </div>
     </div>
    </div>
   );
  }
 }
}

export default Controls;
