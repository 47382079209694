import React from "react";
import "../style/newphenotypepanel.css";
//data-ui histogram package
import {
  Histogram,
  DensitySeries,
  BarSeries,
  withParentSize,
  XAxis,
  YAxis,
} from "@data-ui/histogram";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import Modal from "../components/modal";

export default class NewPhenotypePanel extends React.Component {
  renderTable() {
    if (this.props.loading) return null;

    let biomarkerRows = this.props.newPhenotypeBiomarkers.map((e, i) => {
      let valueInput =
        this.props.biomarkerThresholdOptions[i] == "value" ||
          this.props.biomarkerThresholdOptions[i] == "percentile" ? (
          <input
            className="value-input"
            type="number"
            id="thresh2"
            min="0"
            max="100"
            step="0.1"
            value={this.props.newPhenotypeBiomarkerThresholds[i]}
            onChange={(e) => {
              this.props.handleNewPhenotypeThreshold(e.target.value, i);
            }}
          ></input>
        ) : this.props.biomarkerThresholdOptions[i] == "window" ? (
          <div>
            Lower bound:
            <input
              className="value-input"
              type="number"
              id="thresh2"
              min="0"
              max="100"
              step="0.1"
              value={this.props.newPhenotypeWindowThresholds[i][0]}
              onChange={(e) => {
                this.props.handleNewPhenotypeWindowThresholds(e.target.value, i, 0);
              }}
            ></input>
            Upper bound:
            <input
              className="value-input"
              type="number"
              id="thresh2"
              min="0"
              max="100"
              step="0.1"
              value={this.props.newPhenotypeWindowThresholds[i][1]}
              onChange={(e) => {
                this.props.handleNewPhenotypeWindowThresholds(e.target.value, i, 1);
              }}
            ></input>
          </div>
        ) : null;

      return (
        <tr id={i}>
          <td>
            <div className="label-cont">
              <label className="label-wh">Biomarker {i + 1}:</label>
              <span>&nbsp;&nbsp;</span>
            </div>
            <Select
              className="select"
              value={{ label: this.props.newPhenotypeBiomarkers[i] }}
              menuPlacement={"bottom"}
              isMulti={false}
              onChange={(m) => {
                this.props.handleNewPhenotypeBiomarkers(m.label, i);
              }}
              options={
                Array.from(this.props.cell_data_columns.map((chan) => {
                  return { value: chan, label: chan };
                }))

                // Array.from(this.props.chanLabel.values()).filter((chan) => {        
                // //don't show cell masks channel
                // return (
                //  chan.label !== "Cell Masks" &&
                //  !chan.label.includes("Phenotype ") &&
                //  !chan.label.includes("Cluster ") &&
                //  !chan.label.includes("Dapi")
                // );
                //})
              }
            />
          </td>

          <td>
            <div className="label-cont">
              <label className="label-wh">Threshold: </label>
            </div>

            <input
              type="checkbox"
              id="median"
              value={this.props.biomarkerThresholdOptions[i] == "median"}
              checked={this.props.biomarkerThresholdOptions[i] == "median"}
              onChange={(e) => this.props.handleBiomarkerThresholdOptions(e, i)}
              style={{ width: "16px", height: "16px" }}
            />
            <span>&nbsp;&nbsp;</span>
            <span>Median: </span>
            <span>&nbsp;&nbsp;</span>

            <input
              type="checkbox"
              id="value"
              value={this.props.biomarkerThresholdOptions[i] == "value"}
              checked={this.props.biomarkerThresholdOptions[i] == "value"}
              onChange={(e) => this.props.handleBiomarkerThresholdOptions(e, i)}
              style={{ width: "16px", height: "16px" }}
            />
            <span>&nbsp;&nbsp;</span>
            <span>Value: </span>
            <span>&nbsp;&nbsp;</span>

            <input
              type="checkbox"
              id="percentile"
              value={this.props.biomarkerThresholdOptions[i] == "percentile"}
              checked={this.props.biomarkerThresholdOptions[i] == "percentile"}
              onChange={(e) => this.props.handleBiomarkerThresholdOptions(e, i)}
              style={{ width: "16px", height: "16px" }}
            />
            <span>&nbsp;&nbsp;</span>
            <span>Percentile: </span>
            <span>&nbsp;&nbsp;</span>

            <input
              type="checkbox"
              id="window"
              value={this.props.biomarkerThresholdOptions[i] == "window"}
              checked={this.props.biomarkerThresholdOptions[i] == "window"}
              onChange={(e) => this.props.handleBiomarkerThresholdOptions(e, i)}
              style={{ width: "16px", height: "16px" }}
            />
            <span>&nbsp;&nbsp;</span>
            <span>Window: </span>
            <span>&nbsp;&nbsp;</span>

            {valueInput}
          </td>

          {this.props.biomarkerThresholdOptions[i] == 'window' ? <td></td> : (
            <td>
              <span>Highlight Cells</span>
              <span>&nbsp;&nbsp;</span>
              <label htmlFor="above" id={i}>
                <span>&nbsp;&nbsp;</span>
                <input
                  type="checkbox"
                  id="abovebelow"
                  value={this.props.biomarkerThresholdAbove[i]}
                  checked={this.props.biomarkerThresholdAbove[i]}
                  onChange={() => this.props.handleBiomarkerThresholdAbove(i)}
                  style={{ width: "16px", height: "16px" }}
                />
                <span>&nbsp;&nbsp;</span>
                <span>Above: </span>
              </label>
              <span>&nbsp;or&nbsp;</span>
              <label htmlFor="below" id={i}>
                <span>&nbsp;&nbsp;</span>
                <input
                  type="checkbox"
                  id="abovebelow"
                  value={!this.props.biomarkerThresholdAbove[i]}
                  checked={!this.props.biomarkerThresholdAbove[i]}
                  onChange={() => this.props.handleBiomarkerThresholdAbove(i)}
                  style={{ width: "16px", height: "16px" }}
                />
                <span>&nbsp;&nbsp;</span>
                <span>Below: </span>
              </label>
            </td>
          )
          }

          <td>
            <button
              className="ui button remove"
              key={i}
              onClick={this.props.removeBiomarkerForCustomPhenotype.bind(this, i)}
            >
              X
            </button>
          </td>
        </tr>
      );
    });


    return (
      <table className="newphenotypetable">
        <tbody>{biomarkerRows}</tbody>

        <tr>
          <td></td>
          <td></td>
          <td className="addnewbiomarker-cell">
            <div className="newbiomarker-button-container">
              <div className="label-cont">
                <label className="label-wh">Add biomarker: </label>
              </div>
              <button
                class="ui button"
                onClick={this.props.addBiomarkerForCustomPhenotype}
              >
                <FontAwesomeIcon icon={faPlus} />
              </button>
            </div>
          </td>
          <td></td>
        </tr>
      </table>
    );
  }

  render() {
    const { show, loading } = this.props;

    if (!show) {
      return null;
    }

    let body;
    let submitButton;
    let loader;
    if (loading) {
      loader = (
        <div className="pheno-loader-container">
          <div className="pheno-loader-small">
            <label className="label-black">Fetching data...</label>
            <img className="spinner" src="image/spintellx_logo.svg" />
          </div>
        </div>
      );
      submitButton = null;
    } else {
      loader = null;
      submitButton = (
        <div className="submit-btn">
          <button
            className="ui button submit"
            disabled={this.props.newPhenotypeBiomarkers.includes("")}
            onClick={this.props.computeCustomPhenotype}
          >
            Submit
          </button>
        </div>
      );
    }

    return (
      <Modal className="modal" toggle={this.props.toggle} show={this.props.show}>
        <div className="header">
          <h1>Create a new Phenotype</h1>
        </div>
        _______________________________________________________________________________________________
        {loader}
        {this.renderTable()}
        {submitButton}
      </Modal>
    );
  }
}