import React, { Component } from "react";

import Repo from "./repo";
import Import from "./import";

import "semantic-ui-css/semantic.min.css";
import "../style/app.css";


class TmrApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      signedIn: true,
      url: "/api/u16",
      imageName: null,
      channels: [],
      cell_data_columns: [], // may be different than channels
      width: 1024,
      height: 1024,
      num_real_channels: 0,
      saved_custom_pheno_id: 0,
      saved_channel_renders: null,
      saved_channel_labels: null,
      saved_active_channels: [],
      saved_viewport_bounds: {},
      saved_overlays: [],
      saved_linePoints: [],
      saved_polygonPoints: [],
      case_name: "",
      predefined_colors: {},
    };

    this.onSignout = this.onSignout.bind(this);
    this.unload = this.unload.bind(this);
    this.unloadBackEnd = this.unloadBackEnd.bind(this);
    this.getImportResult = this.getImportResult.bind(this);
    let email = this.props.user.signInUserSession.idToken.jwtToken
  }

  async getImportResult() {
    return fetch("/api/import", {
      headers: {
        pragma: "no-cache",
        "cache-control": "no-store",
        "Authorization": this.props.user.signInUserSession.idToken.jwtToken,
      },
    }).then(async (res) => {
      return res.json();
    });
  }

  async componentDidMount() {
    try {
      setInterval(async () => {
        const { loaded } = this.state;
        if (loaded === false) {
          const import_result = await this.getImportResult();

          let chans = import_result.channels;
          this.setState({ num_real_channels: chans.length });
          //push a few additional channels on, which will be the custom phenotype channels the user can create
          for (var i = 0; i < 20; i++) chans.push("Phenotype " + (i + 1));
          for (var i = 0; i < 20; i++) chans.push("Cluster " + i);
          let saved_channel_renders =
            import_result.channel_renders.length > 0
              ? new Map(import_result.channel_renders)
              : null;
          let saved_channel_labels =
            import_result.channel_labels.length > 0
              ? new Map(import_result.channel_labels)
              : null;

          this.setState({
            channels: chans,
            tilesize: import_result.tilesize,
            maxLevel: import_result.maxLevel,
            width: import_result.width,
            height: import_result.height,
            cell_data_columns: import_result.cell_data_columns,
            saved_channel_renders: saved_channel_renders,
            saved_channel_labels: saved_channel_labels,
            saved_custom_pheno_id: import_result.curCustomPhenotypeId,
            saved_active_channels: import_result.saved_active_channels,
            saved_viewport_bounds: import_result.viewport_bounds,
            saved_overlays: import_result.overlays,
            saved_linePoints: import_result.linePoints,
            saved_polygonPoints: import_result.polygonPoints,
            loaded: import_result.loaded,
            case_name: import_result.case_name,
            predefined_colors: import_result.predefined_colors,
            all_case_slides: import_result.all_case_slides,
            has_cell_labels: import_result.has_cell_labels,
            has_cell_data: import_result.has_cell_data,
          });
        }
      }, 3000);
    } catch (e) {
      console.log(e);
    }
  }

  async unload() {
    console.log("unloading and going back to cases");
    const res = await this.unloadBackEnd();
    if (res) {
      this.setState({ loaded: false });
    }
  }
  
  // tell back end to unload
  async unloadBackEnd() {
    const fetchUrl = `/api/unload`;
    return fetch(fetchUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": this.props.user.signInUserSession.idToken.jwtToken,
      },
    }).then((data) => {
      return true;
    });
  }

  onSignout() {
    this.setState({ signedIn: false });
  }

  render() {
    const {
      loaded,
      width,
      height,
      tilesize,
      maxLevel,
      url,
      imageName,
    } = this.state;
    const { channels } =
      this.state;

    if (loaded) {
      return (
        <div>
          <div className={"repo-div show"}>
            <Repo
              channels={channels}
              url={url}
              maxLevel={maxLevel}
              width={width}
              height={height}
              tilesize={tilesize}
              imageName={imageName}
              cell_data_columns={this.state.cell_data_columns}
              num_real_channels={this.state.num_real_channels}
              saved_custom_pheno_id={this.state.saved_custom_pheno_id}
              saved_channel_renders={this.state.saved_channel_renders}
              saved_channel_labels={this.state.saved_channel_labels}
              saved_active_channels={this.state.saved_active_channels}
              saved_viewport_bounds={this.state.saved_viewport_bounds}
              saved_overlays={this.state.saved_overlays}
              saved_linePoints={this.state.saved_linePoints}
              saved_polygonPoints={this.state.saved_polygonPoints}
              case_name={this.state.case_name}
              unload={this.unload}
              user={this.props.user}
              predefined_colors={this.state.predefined_colors}
              all_case_slides={this.state.all_case_slides}
              has_cell_data={this.state.has_cell_data}
              has_cell_labels={this.state.has_cell_labels}
            />
          </div>
        </div>
      );
    }
    if (this.state.signedIn) {
      return (
        <Import
          onSignout={this.props.onSignout}
          signOut={this.props.signOut}
          user={this.props.user}
        />
      );
    } 
  }
}

export default TmrApp;
