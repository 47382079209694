import React from "react";
import "../style/whypanel.css";
//data-ui histogram package 
import { Histogram, DensitySeries, BarSeries, withParentSize, XAxis, YAxis } from '@data-ui/histogram';
import {faLessThan, faGreaterThan} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from 'react-select';
import Modal from "../components/modal";

const rawData = Array(100).fill().map(Math.random);

export default class WhyPanel extends React.Component {

	render() {
		const { show } = this.props;

		if (!show) {
			return null;
		}

		return (
		<Modal className="modal" toggle={this.props.toggle} show={this.props.show}>
			
			<div className='header'>
				<h1> PatientID43123: Personalized xAI Findings</h1>
			</div>
			__________________________________________________________________________________________________________________________

			<div className='findings'>
				<ul>
					<li>Predominance of "Stem-tumor" phenotype and "CAFs" <a className='link' href='/'>Graph -></a></li>
					<li>Transitional cell count is disproportional <a className='link' href='/'>Graph -></a></li>
					<li>Spatial interaction hierarchy between "T-Cells", "B-Cells", and "STEM Cells" <a className='link' href='/'>Graph -></a></li>
					<li>PI3K spatially co-activated with mTOR <a className='link' href='/'>Graph -></a></li>
					<li>TGF-&beta; signaling to "CAFs" is spatially modulated <a className='link' href='/'>Graph -></a></li>
				</ul>
			</div>

			<div className='header'>
				<h1>PatientID43123: TumorMapr Therapeutic Recommendation</h1>
			</div>

			__________________________________________________________________________________________________________________________

			<div className='recommendations'>
				<p>Oxaliplatin with 5-fluorouracil or Capecitabine, for 6 months (Confidence=92%)<sup>1,2</sup></p>
			
				<div className='row'>

				<div className='altrec1'>
					<p className='underline'>Treatment Projection (Confidence=93%):</p>
					<p className='ned'>No Evidence of Disease (3 years)</p>
					<p><a className='link' href='/'>Projection graph -></a></p>
				</div>

				<div className='altrec2'>
					<p className='underline'>No Treatment Projection (Confidence=89%):</p>
					<p className='recur'>Recurrence in 9 months</p>
				</div>

				</div>
			</div>

			<div className='references'>
				<p>1. Longley, Daniel B., D. Paul Harkin, and Patrick G. Johnston. 
				"5-fluorouracil: mechanisms of action and clinical strategies." Nature
				reviews cancer 3, no.5 (2003):330. <a className='link' href='/'>Read-></a></p>
				<p>2. Wu, Jaijun, Yu Liang, Lin Shen, and Liangfang Shen. "MicroRNA-204 modulates
				colorectal cancer cell sensitivity in response to 5-fluorouracil-based tratment 
				by targeting high mobility group protein A2" Biology open 5, no. 5 (2016):563-570. <a className='link' href='/'>Read-></a></p>
			</div>

		</Modal>
		);
	}
}