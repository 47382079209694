export const gridStyles = {
  stroke: '#00FFFF',
  strokeWidth: 1,
};

export const labelStyles = {
	thismakesnodifference: '#000000'
}

export const xAxisStyles = {
	stroke: '#000000',
	strokeWidth: 2,
};

export const yAxisStyles = {
	stroke: '#000000',
	strokeWidth: 2,
};

export const xTickStyles = {
  stroke: '#000000',
};

export const yTickStyles = {
  stroke: '#000000',
};

export const colors = '#000000'

export default {
	colors,
	gridStyles,
	labelStyles,
	xAxisStyles,
	yAxisStyles,
	xTickStyles,
	yTickStyles
}