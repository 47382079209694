import React from "react";
import "../style/datapanel.css";
//data-ui histogram package
import {
  Histogram,
  DensitySeries,
  BarSeries,
  withParentSize,
  XAxis,
  YAxis,
} from "@data-ui/histogram";
import { XYChart, PointSeries } from "@data-ui/xy-chart";

import {
  faLessThan,
  faGreaterThan,
  faTimes,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import { default as chartTheme } from "../components/chartTheme";
//violin plot
import OrdinalFrame from "semiotic/lib/OrdinalFrame";
import Heatmap from "../components/heatmap";

//data form for pointseries (scatterplot)
//  [ {"x" : 1.0,  "y:"  1.0,
//      "fill": "#FFFFFF", "size": 3,
//      "label": "point1"}, {...}... ]

//same hard coded colors in hcColors in repo.jsx
const colors = {
  FP13: "#006400",
  FP12: "#646400",
  FP11: "#C80040",
  FP10: "#8080FF",
  FP9: "#402000",
  FP8: "#8000FF",
  FP7: "#FF8000",
  FP6: "#0000FF",
  FP5: "#FF00FF",
  FP4: "#FFFF00",
  FP3: "#FF0000",
  FP2: "#00FF00",
  FP1: "#00FFFF",
};
const random_colors = [
  "#ff0000",
  "#ffff00",
  "#ff00ff",
  "#00ff00",
  "#00ffff",
  "#0000ff",
];

//compute the median
const median = (arr) => {
  const mid = Math.floor(arr.length / 2),
    nums = [...arr].sort((a, b) => a - b);
  return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
};

//compute quartiles
const quartile = (data, q) => {
  data = Array_Sort_Numbers(data);
  var pos = (data.length - 1) * q;
  var base = Math.floor(pos);
  var rest = pos - base;
  if (data[base + 1] !== undefined) {
    return data[base] + rest * (data[base + 1] - data[base]);
  } else {
    return data[base];
  }
};

const Array_Sort_Numbers = (inputarray) => {
  return inputarray.sort(function (a, b) {
    return a - b;
  });
};

//boxplot properties
const boxplotFrameProps = {
  size: [280, 300],
  margin: { left: 60, bottom: 20, right: 20, top: 2 },

  /* --- Layout --- */
  type: "none", //This makes it NOT DRAW THE INDIVIDUAL DATA POINTS, just the summary
  projection: "vertical",

  /* --- Process --- */
  oAccessor: "biomarker",
  rAccessor: "distance",
  rExtent: [0, undefined],

  oPadding: 6 /*padding between cols*/,

  /*
  pixelColumnWidth: 50,
  */

  summaryStyle: (d) => ({
    /*I am trying to make it a hard coded color, OR, cyan. right now
      It is either doing hard coded color or gray*/

    fill:
      colors[d.biomarker] != undefined
        ? colors[d.biomarker]
        : random_colors[Math.floor(Math.random() * random_colors.length)],
    stroke:
      colors[d.biomarker] != undefined
        ? colors[d.biomarker]
        : random_colors[Math.floor(Math.random() * random_colors.length)],
    fillOpacity: 0.5,
    strokeWidth: 2,
  }),
  /*title: "Biomarker Distance from Polyline",*/
  axes: [{ orient: "left", label: "Distance (pixels)" }],

  /* --- Annotate --- */
  oLabel: true,

  /* --- Hover behavior ---*/
  hoverAnnotation: true,
  tooltipContent: (d) => (
    <div className="tooltip-content">
      <label className="tooltip-header">Distance Statistics</label>
      <table className="tooltip-table">
        <tr>
          <td>Min:</td>
          <td>
            {Math.round(
              Math.min(...d["pieces"].map((data) => data["distance"]))
            )}
          </td>
        </tr>
        <tr>
          <td>Max:</td>
          <td>
            {Math.round(
              Math.max(...d["pieces"].map((data) => data["distance"]))
            )}
          </td>
        </tr>
        <tr>
          <td>Median:</td>
          <td>
            {Math.round(median(d["pieces"].map((data) => data["distance"])))}
          </td>
        </tr>
        <tr>
          <td>1st Q:</td>
          <td>
            {Math.round(
              quartile(
                d["pieces"].map((data) => data["distance"]),
                0.25
              )
            )}
          </td>
        </tr>
        <tr>
          <td>3rd Q:</td>
          <td>
            {Math.round(
              quartile(
                d["pieces"].map((data) => data["distance"]),
                0.75
              )
            )}
          </td>
        </tr>
      </table>
    </div>
  ),
};

//violin plot values
const violinFrameProps = {
  //data passed in in jsx
  /* --- Size --- */
  size: [280, 300],
  margin: { left: 60, bottom: 20, right: 20, top: 2 },

  /* --- Layout --- */
  type: "none", //This makes it NOT DRAW THE INDIVIDUAL DATA POINTS, just the summary
  projection: "vertical",

  /* --- Process --- */
  oAccessor: "biomarker",
  rAccessor: "distance",
  rExtent: [0, undefined],

  oPadding: 2 /*padding between cols*/,

  summaryStyle: (d) => {
    return {
      fill:
        colors[d.biomarker] != undefined
          ? colors[d.biomarker]
          : random_colors[Math.floor(Math.random() * random_colors.length)],
      stroke:
        colors[d.biomarker] != undefined
          ? colors[d.biomarker]
          : random_colors[Math.floor(Math.random() * random_colors.length)],
      fillOpacity: 0.75,
      strokeWidth: 0.75,
    };
  },
  /*title: "Biomarker Distance from Polyline",*/
  axes: [{ orient: "left", label: "Distance (pixels)" }],

  /* --- Annotate --- */
  oLabel: true,

  /* --- Hover behavior ---*/
  /*
  hoverAnnotation: true,
  tooltipContent: d => (
    <div className="tooltip-content">
      <p>{d.biomarker}</p>
      <p>{d.value}</p>
    </div>
  )
  */
};
//End violin plot stuff
//infiltration plot values
const infiltrationFrameProps = {
  //data passed in in jsx
  /* --- Size --- */
  size: [280, 360],
  margin: { left: 60, bottom: 20, right: 20, top: 40 },

  /* --- Layout --- */
  type: "none", //This makes it NOT DRAW THE INDIVIDUAL DATA POINTS, just the summary
  projection: "vertical",

  /* --- Process --- */
  oAccessor: "location",
  rAccessor: "distance",
  rExtent: [0, undefined],

  oPadding: 2 /*padding between cols*/,

  summaryStyle: (d) => ({
    /*I am trying to make it a hard coded color, OR, cyan. right now
      It is either doing hard coded color or gray*/
    fill: d.location == "inside" ? "#FF0000" : "#00FF00",
    stroke: "#000000",
    fillOpacity: 0.5,
    strokeWidth: 1,
  }),
  /*title: "Biomarker Distance from Polyline",*/
  axes: [{ orient: "left", label: "Distance (pixels)" }],

  /* --- Annotate --- */
  oLabel: true,

  /* --- Hover behavior ---*/
  /*
  hoverAnnotation: true,
  tooltipContent: d => (
    <div className="tooltip-content">
      <p>{d.biomarker}</p>
      <p>{d.value}</p>
    </div>
  )
  */
};

export default class DataPanel extends React.Component {
  constructor(props) {
    super(props);
    //to keep track of how many cells are in the window in the violin plot
    this.state = {
      extent: [0, 10000],
      selectedDataColumn: "",
      cellCountsWindow: {}, //cell counts inside the violin plot windows
      densityCellCount: 0,
      activeChannelsScatterPlot: ["", ""],
      activeSelectionScatterPlot: "",
    };
    this.changeExtent = this.changeExtent.bind(this);
    this.updateDensityCellCount = this.updateDensityCellCount.bind(this);
    this.getTotalCellCount = this.getTotalCellCount.bind(this);
    this.handleActiveChannelsScatterPlot =
      this.handleActiveChannelsScatterPlot.bind(this);
    this.handleActiveSelectionScatterPlot =
      this.handleActiveSelectionScatterPlot.bind(this);
  }

  //get list of options for selections, including 'Whole Image' as an option, but not polyline
  getAllSelectionsWholeImage() {
    let options = [{ value: "Whole Image", label: "Whole Image" }];
    this.props.overlays.forEach((e, i) =>
      options.push({ value: "Selection " + i, label: "Selection " + i })
    );
    this.props.polygonPoints.forEach((e, i) =>
      options.push({ value: "Polygon " + i, label: "Polygon " + i })
    );
    this.props.maskPoints.forEach((e, i) =>
      options.push({ value: "Freehand " + i, label: "Freehand " + i })
    );
    return options;
  }

  //same as above, but includes polyline and whole image.
  getAllSelectionsInclPolyline() {
    let options = [{ value: "Whole Image", label: "Whole Image" }];
    this.props.overlays.forEach((e, i) =>
      options.push({ value: "Selection " + i, label: "Selection " + i })
    );
    this.props.polygonPoints.forEach((e, i) =>
      options.push({ value: "Polygon " + i, label: "Polygon " + i })
    );
    this.props.maskPoints.forEach((e, i) =>
      options.push({ value: "Freehand " + i, label: "Freehand " + i })
    ); 
    if (this.props.linePoints[0].length > 0)
      options.push({ value: "Polyline", label: "Polyline" });
    return options;
  }

  changeExtent(e, column) {
    let newcellCountsWindow = this.state.cellCountsWindow;
    let cnt = this.props.violinData.filter(
      (d) => d.biomarker == column && d.distance >= e[0] && d.distance <= e[1]
    ).length;
    newcellCountsWindow[column] = cnt;
    this.setState({ cellCountsWindow: newcellCountsWindow });
  }

  //when the user hovers over the density plot, update the number of cells to
  //  left/right of their cursor on the density plot
  updateDensityCellCount(data) {
    //cumulative is how many cells to the left
    const cumulative = data["cumulative"];
    this.setState({ densityCellCount: cumulative });
  }

  //gets the total number of cells in the data for a channel
  getTotalCellCount(chan) {
    let cnt = this.props.violinData.filter((d) => d.biomarker == chan).length;
    return cnt;
  }

  //updates the active channels being displayed on the circle plot
  handleActiveChannelsScatterPlot(ind, channel) {
    let new_activeChannelsScatterPlot = this.state.activeChannelsScatterPlot;
    new_activeChannelsScatterPlot[ind] = this.props.channels[channel];
    //rerender plot with new channels
    this.setState({ activeChannelsScatterPlot: new_activeChannelsScatterPlot });
  }

  handleActiveSelectionScatterPlot(m) {
    this.setState({ activeSelectionScatterPlot: m.label });
  }

  render() {
    const selectedChannel = this.props.selectedChannel;

    //if its violin, show cell count within the window
    const cellCountsWindowDisp = this.props.violinOrBoxplot ? (
      <div className="cellcountdiv">
        <label className="cellcount">Cell counts:</label>
        <table className="cell-count-table">
          <tr>
            {this.props.activeViolinChannels.map((chan) => (
              <td>{this.state.cellCountsWindow[chan]}</td>
            ))}
          </tr>
        </table>
      </div>
    ) : (
      //if its boxplot, show total cell count
      <div className="cellcountdiv">
        <label className="cellcount">Cell counts:</label>
        <table className="cell-count-table">
          <tr>
            {this.props.activeViolinChannels.map((chan) => (
              <td>{this.getTotalCellCount(chan)}</td>
            ))}
          </tr>
        </table>
      </div>
    );

    if (!this.props.show) return null;

    let hists;
    let options = [];
    //render the current tab
    //summary tab
    if (this.props.dataPanelTabs[0].visible) {
      //if we have no vis data, and are not currently fetching it, just show buttons / selections
      if (
        !this.props.haveVisData &&
        !this.props.fetchingHistData &&
        !this.props.havePolylineVisData &&
        !this.props.haveClinicalData
      ) {
        hists = (
          <div>
            <div className="row">
              <div className="col">
                <label className="label-small">Select a channel:</label>
                <Select
                  isMulti={false}
                  onChange={(m) => this.props.handleSelectedChannel(m)}
                  value={{ label: this.props.selectedChannel }}
                  options={Array.from(this.props.chanLabel.values()).filter(
                    (chan) => {
                      //don't show cell masks channel
                      return (
                        chan.label !== "Cell Masks" &&
                        !chan.label.includes("Phenotype ") &&
                        !chan.label.includes("Cluster ") &&
                        !chan.label.includes("Dapi") &&
                        !chan.label.includes("Cell mask") &&
                        !chan.label.includes("Tissue") 
                      );
                    }
                  )}
                />
              </div>
            </div>

            <div className="row">
              <div className="col">
                <label className="label-small">Choose a selection:</label>
                <Select
                  isMulti={false}
                  value={{ label: this.props.activeSelection }}
                  options={this.getAllSelectionsInclPolyline()}
                  onChange={(c) => this.props.handleActiveSelection(c.value)}
                />
              </div>
            </div>

            <div className="analyzebutton">
              <label className="warnings">{this.props.warning}</label>
              <button
                className="ui button analyze"
                title="Compute"
                disabled={this.props.selectedChannel == ""}
                onClick={this.props.fetchDataPanelData}
              >
                Analyze
              </button>
              {/*
              <button
                className="ui button analyze clinical"
                title="Clinical Data"
                onClick={this.props.fetchClinicalData}
                disabled={this.props.activeSelection == "Whole Image"}
              >
                Get Clinical Data
              </button>
              */}
            </div>
          </div>
        );
      }
      //else, if its still loading, display a loading icon
      else if (
        (!this.props.haveVisData && this.props.fetchingHistData) ||
        this.props.computingClusters
      ) {
        hists = (
          <div className="loader">
            <label className="label-black">Fetching data...</label>
            <img className="spinner" src="image/spintellx_logo.svg" />
          </div>
        );
      }
      // show clinical data
      else if (this.props.haveClinicalData) {
        const { clinicalData } = this.props;
        hists = (
          <div className="clinical-data-container">
            <label className="clinical-data-header">
              Patient:&nbsp;{clinicalData["name"]}
            </label>
            <table className="clinical-data-table">
              <tbody>
                <tr>
                  <th>Age</th>
                  <th>{clinicalData["age"]}</th>
                </tr>
                <tr>
                  <th>Gender</th>
                  <th>{clinicalData["gender"]}</th>
                </tr>
                <tr>
                  <th>Grade</th>
                  <th>{clinicalData["grade"]}</th>
                </tr>
                <tr>
                  <th>Stage</th>
                  <th>{clinicalData["stage"]}</th>
                </tr>
                <tr>
                  <th>Time To Recurrence</th>
                  <th>{clinicalData["rec_time"]} days</th>
                </tr>
                <tr>
                  <th>Survival Time</th>
                  <th>{clinicalData["survival_time"]} days</th>
                </tr>
              </tbody>
            </table>
            <button
              className="ui button analyze"
              title="Back"
              onClick={this.props.clearClinicalData}
            >
              Back
            </button>
          </div>
        );
      }
      //else, if we have the data, and its for polyline selection, show violin

      //note: the reason in OrdinalFrame key=length of the violin data, is so that when the
      // length of the data changes (when an additional channel is added),
      // the key changes, which forces react to re-render the frame (which)
      // it wasn't doing before, even though it was calling render()
      else if (this.props.activeSelection == "Polyline") {
        let frame;
        //we have have visData, but no polyline vis data, in that case, just show
        //  analyze button again
        if (!this.props.havePolylineVisData) {
          hists = (
            <div className="analyzebutton">
              <label className="warnings">{this.props.warning}</label>
              <button
                className="ui button analyze"
                title="Compute"
                onClick={this.props.fetchDataPanelData}
              >
                Analyze
              </button>
            </div>
          );
        }

        //else, we have the polyline vis data
        else {
          //if violinOrBoxplot = true, display the violinplot
          if (this.props.violinOrBoxplot) {
            frame = (
              <OrdinalFrame
                {...violinFrameProps}
                data={this.props.violinData}
                key={this.props.violinData.length}
                summaryType={"violin"}
                interaction={{
                  columnsBrush: true,
                  extent: { singleColumn: this.state.extent },
                  end: this.changeExtent,
                }}
              />
            );
          }
          //else, display the boxplot
          else {
            frame = (
              <OrdinalFrame
                {...boxplotFrameProps}
                data={this.props.violinData}
                key={this.props.violinData.length + 1}
                summaryType={"boxplot"}
              />
            );
          }

          hists = (
            <div className="violin">
              <label className="warnings2">{this.props.warning}</label>

              <div className="plotoptions">
                <label htmlFor="violinplotoption">
                  <input
                    type="checkbox"
                    id="violinplotoption"
                    value={this.props.violinOrBoxplot}
                    onChange={this.props.handleViolinOrBoxplot}
                    checked={this.props.violinOrBoxplot}
                    style={{ width: "16px", height: "16px" }}
                  />
                  <span>Violin Plot</span>
                  <span>&nbsp;&nbsp;</span>
                </label>

                <label htmlFor="boxplotoption">
                  <input
                    type="checkbox"
                    id="boxplotoption"
                    value={!this.props.violinOrBoxplot}
                    onChange={this.props.handleViolinOrBoxplot}
                    checked={!this.props.violinOrBoxplot}
                    style={{ width: "16px", height: "16px" }}
                  />
                  <span>Box Plot</span>
                </label>

                <div className="cellcount-cont">
                  {cellCountsWindowDisp}

                  {/*this.state.selectedDataColumn} cell count in window: {this.state.selectedDataCount*/}
                </div>
              </div>

              {frame}

              <div className="additionalbuttons">
                <div className="row">
                  <span className="ui buttons">
                    <button
                      className="ui button analyze"
                      title="clear"
                      onClick={() => {
                        this.props.clearViolinData();
                        this.setState({ cellCountsWindow: {} });
                        this.setState({ densityCellCount: 0 });
                      }}
                    >
                      Clear
                    </button>
                    <button
                      className="ui button analyze"
                      title="Compute"
                      onClick={() => {
                        this.props.fetchDataPanelData();
                        this.setState({ cellCountsWindow: {} });
                        this.setState({ densityCellCount: 0 });
                      }}
                    >
                      Analyze
                    </button>
                    <button className="ui button analyze" title="export">
                      Export
                    </button>
                  </span>
                </div>
              </div>

              {/*}
          <div className='additionalbuttons'>
          <div className='analyzebutton-small'>
          <button className='ui button analyze' title='clear' onClick={this.props.clearViolinData}
          >Clear</button>
          </div>

          <div className='analyzebutton-small'>
          <button className='ui button analyze' title='Compute' onClick={this.props.fetchDataPanelData}
          >Analyze</button>
          </div>
          </div>
          */}

              <label className="label-sm-black">
                Add additional channels:{" "}
              </label>

              <Select
                isMulti={false}
                onChange={(m) => this.props.fetchAdditionalViolinData(m)}
                menuPlacement={"top"}
                options={Array.from(this.props.chanLabel.values()).filter(
                  (chan) => {
                    //don't show cell masks channel
                    return (
                      chan.label !== "Cell Masks" &&
                      !chan.label.includes("Phenotype ") &&
                      !chan.label.includes("Cluster ") &&
                      !chan.label.includes("Dapi")
                    );
                  }
                )}
              />
            </div>
          );
        }
      }

      //else, if we have the data, and its for rectangle selection, show hist + density
      // or, for no selection at all, show hist for whole slide
      else if (
        this.props.activeSelection.includes("Selection") ||
        this.props.activeSelection.includes("Polygon") ||
        this.props.activeSelection.includes("Freehand") ||
        this.props.activeSelection == "Whole Image"
      ) {
        //check to see if we have data
        if (!this.props.haveVisData) {
          hists = (
            <div className="analyzebutton">
              <label className="warnings">{this.props.warning}</label>
              <button
                className="ui button analyze"
                title="Compute"
                onClick={this.props.fetchDataPanelData}
              >
                Analyze
              </button>
            </div>
          );
        } else {
          // determine whether it's a biomarker (show histogram/density) or phenotype (just show table)
          let display;
          if (this.props.selectedChannelIdx >= this.props.num_real_channels || this.props.channels[this.props.selectedChannelIdx].toLowerCase().includes("positive")) {
            // it is a custom channel, show a table
            display = (
              <div>
                <table className="cell-count-table">
                  <tbody>
                    <tr>
                      <th>Count</th>
                      <th>Total Count</th>
                      <th>Percentage</th>
                    </tr>
                    <tr>
                      <td>{this.props.curCellCount}</td>
                      <td>{this.props.curCellCountTotal}</td>
                      <td>
                        {this.props.curCellCountTotal == 0
                          ? 0
                          : (
                              (this.props.curCellCount /
                                this.props.curCellCountTotal) *
                              100
                            ).toFixed(2)}
                        %
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="analyzebutton-small">
                  <button
                    className="ui button analyze"
                    title="Compute"
                    onClick={this.props.fetchDataPanelData}
                  >
                    Analyze
                  </button>
                </div>
              </div>
            );
          } else {
            display = (
              <div className="hists">
                <label className="warnings2">{this.props.warning}</label>

                <div>
                  <label className="label-sm-black"> Histogram: </label>
                </div>

                <Histogram
                  width={300}
                  height={200}
                  ariaLabel="My histogram of ..."
                  orientation="vertical"
                  cumulative={false}
                  normalized={true}
                  binCount={this.props.numBinsHistogram}
                  valueAccessor={(datum) => datum}
                  binType="numeric"
                  theme={chartTheme}
                  renderTooltip={({ event, datum, data, color }) => (
                    <div>
                      <strong style={{ color }}>
                        {datum.bin0} to {datum.bin1}
                      </strong>
                      <div>
                        <strong>count </strong>
                        {datum.count}
                      </div>
                      <div>
                        <strong>cumulative </strong>
                        {datum.cumulative}
                      </div>
                      <div>
                        <strong>density </strong>
                        {datum.density.toFixed(2)}
                      </div>
                    </div>
                  )}
                >
                  <BarSeries
                    animated
                    rawData={this.props.histData}
                    stroke={"#000000"}
                    fill={"#00FFFF"}
                  />
                  <XAxis numTicks={6} />
                  <YAxis />
                </Histogram>

                <div className="histbinsoption">
                  <label htmlFor="histbins">Number of histogram bins:</label>
                  <span>&nbsp;&nbsp;</span>
                  <input
                    type="number"
                    id="histbins"
                    name="histbins"
                    min="1"
                    max="100"
                    value={this.props.numBinsHistogram}
                    onChange={(e) => {
                      this.props.handleNumBinsHistogram(e);
                    }}
                  ></input>
                </div>

                {/*

                <div>
                  <label className="label-sm-black"> Density plot: </label>
                </div>

                <Histogram
                  width={300}
                  height={200}
                  ariaLabel="My histogram of ..."
                  orientation="vertical"
                  cumulative={false}
                  normalized={true}
                  binCount={50}
                  valueAccessor={(datum) => datum}
                  binType="numeric"
                  theme={chartTheme}
                  renderTooltip={({ event, datum, data, color }) => (
                    <div>
                      ActiveChannelsSca
                      <strong>Num Cells to the left: {datum.cumulative}</strong>
                    </div>
                  )}
                >
                  <DensitySeries
                    animated
                    rawData={this.props.histData}
                    stroke={"#000000"}
                    fill={"#00FFFF"}
                    showLine={true}
                  />
                  <BarSeries
                    animated
                    rawData={this.props.histData}
                    stroke={"#000000"}
                    fill={"#FFFF00"}
                    fillOpacity={0}
                    strokeWidth={1}
                  />
                  <XAxis numTicks={6} />
                  <YAxis />
                </Histogram>
                  */}

                <div className="analyzebutton-small">
                  <button
                    className="ui button analyze"
                    title="Compute"
                    onClick={this.props.fetchDataPanelData}
                  >
                    Analyze
                  </button>
                </div>
              </div>
            );
          }
          hists = (
            <div>
              <div className="row">
                <div className="col">
                  <label className="label-small">Select a channel:</label>
                  <Select
                    isMulti={false}
                    onChange={(m) => this.props.handleSelectedChannel(m)}
                    value={{ label: this.props.selectedChannel }}
                    options={Array.from(this.props.chanLabel.values()).filter(
                      (chan) => {
                        //don't show cell masks channel
                        return (
                          chan.label !== "Cell Masks" &&
                          !chan.label.includes("Phenotype ") &&
                          !chan.label.includes("Cluster ") &&
                          !chan.label.includes("Dapi")
                        );
                      }
                    )}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <label className="label-small">Choose a selection:</label>
                  <Select
                    isMulti={false}
                    value={{ label: this.props.activeSelection }}
                    options={this.getAllSelectionsInclPolyline()}
                    onChange={(c) => this.props.handleActiveSelection(c.value)}
                  />
                </div>
              </div>

              {display}
            </div>
          );
        }
      }
    }
    const phenotypeChannels = this.props.activeIdsSpatial.map((a,i) => {
      return {label: a, id: i, value: i}
    })

    //spatial
    if (this.props.dataPanelTabs[1].visible) {
      //else if datapaneltab==1, show spatial analysis (entropy, network)
      let rectSelect;
      if (
        this.props.onlyInSelectionSpatial &&
        this.props.onlyInSelectionRectSpatial
      ) {
        rectSelect = (
          <Select
            className="small-select"
            isMulti={false}
            options={[{ value: "0", label: "0" }]}
          />
        );
      }

      //setup apatial analysis options
      let options = this.props.onlyInSelectionSpatial ? (
        <div className="suboptions" key="suboptions">
          <div className="options" key="polyorrect">
            <label htmlFor="rectselectioncheckspatial">
              <input
                type="checkbox"
                id="rectselectioncheckspatial"
                value={this.props.onlyInSelectionRectSpatial}
                checked={this.props.onlyInSelectionRectSpatial}
                onChange={this.props.handleOnlyInSelectionRectSpatial}
                style={{ width: "20px", height: "20px" }}
              />
              <span style={{ color: "black" }}>Rectangle</span>
            </label>

            <label htmlFor="polyselectioncheckspatial">
              <input
                type="checkbox"
                id="polyselectioncheckspatial"
                value={!this.props.onlyInSelectionRectSpatial}
                checked={!this.props.onlyInSelectionRectSpatial}
                onChange={this.props.handleOnlyInSelectionRectSpatial}
                style={{ width: "20px", height: "20px" }}
              />
              <span style={{ color: "black" }}>Polygon</span>
            </label>
          </div>
        </div>
      ) : (
        ""
      );

      // If we don't have the data and we're not computing it, show options
      if (
        !this.props.haveInfiltrationDistanceData &&
        !this.props.computingInfiltrationDistance
      ) {
        hists = (
          <div className="hists">
            <label className="warnings2">{this.props.warning}</label>
            <label className="warnings2">{this.props.warning}</label>
            <div className="analyzebutton-small">
              <button
                className="ui button analyze-tight"
                disabled={this.props.activeSelectionSpatialNetwork == ""}
                onClick={() => {
                  this.props.displaySpatialNetwork();
                }}
              >
                Display Spatial Network
              </button>
            </div>
            {this.props.spatialNetworkLines.length > 0 ? (
              <div className="analyzebutton-small">
                <button
                  className="ui button analyze-tight"
                  onClick={() => {
                    this.props.clearSpatialNetwork();
                  }}
                >
                  Clear
                </button>
              </div>
            ) : null}
            <div className="spatial-options">
              <label>Choose a Selection:&nbsp;&nbsp;</label>
              <Select
                isMulti={false}
                onChange={(m) =>
                  this.props.handleActiveSelectionSpatialNetwork(m)
                }
                options={this.getAllSelectionsWholeImage().filter((sel) => {
                  return (
                    !sel.label.includes("Polygon") &&
                    !sel.label.includes("Polyline") &&
                    !sel.label.includes("Freehand") &&
                    !sel.label.includes("Whole Image")
                  );
                })}
                value={{ label: this.props.activeSelectionSpatialNetwork }}
              />
            </div>

            _______________________________________________
            <div className="analyzebutton-small">
              <button
                className="ui button analyze-tight"
                disabled={
                  this.props.activeSelectionInfiltration == "" ||
                  this.props.overlays.length == 0 ||
                  this.props.activeChannelInfiltration == "" ||
                  this.props.polygonPoints[0].length == 0
                }
                onClick={() => {
                  this.props.computeInfiltrationDistance();
                }}
              >
                Compute Infiltration Distance
              </button>
            </div>
            <div className="spatial-options">
              <label>Choose a Selection:&nbsp;&nbsp;</label>
              <Select
                isMulti={false}
                onChange={(m) =>
                  this.props.handleActiveSelectionInfiltration(m)
                }
                options={this.getAllSelectionsWholeImage().filter((sel) => {
                  return (
                    !sel.label.includes("Polygon") &&
                    !sel.label.includes("Polyline") &&
                    !sel.label.includes("Freehand") &&
                    !sel.label.includes("Whole Image")
                  );
                })}
                value={{ label: this.props.activeSelectionInfiltration }}
              />
              <label>Choose a Polygon:&nbsp;&nbsp;</label>
              <Select
                isMulti={false}
                onChange={(m) =>
                  this.props.handleActiveSelectionInfiltrationPolygon(m)
                }
                options={this.getAllSelectionsWholeImage().filter((sel) => {
                  return (
                    sel.label.includes("Polygon")
                  );
                })}
                value={{ label: this.props.activeSelectionInfiltrationPolygon }}
              />
              <label>Select a Phenotype:&nbsp;</label>
              <Select
                isMulti={false}
                onChange={(m) => this.props.handleActiveChannelInfiltration(m)}
                options={Array.from(this.props.chanLabel.values()).filter(
                  (chan, index) => {
                    //don't show cell masks channel
                    return (
                      chan.label !== "Cell Masks" &&
                      !chan.label.includes("Phenotype ") &&
                      !chan.label.includes("Cluster ") &&
                      !chan.label.includes("Dapi") &&
                      (index >= this.props.num_real_channels || chan.label.toLowerCase().includes("positive"))
                    );
                  }
                )}
                value={{ label: this.props.activeChannelInfiltration }}
              />
            </div>

            _______________________________________________
            <div className="analyzebutton-small">
              <button
                className="ui button analyze-tight"
                title="spat"
                disabled={this.props.activeSelectionSpatial == "" || this.props.activeIdsSpatial.length < 2}
                onClick={() => {
                  this.props.fetchCirclePlotData();
                  this.props.handleShowCirclePlot();
                }}
              >
                Compute PMI
              </button>
            </div>
            <div className="histbinsoption">
              <label htmlFor="histbins">Distance Threshold (px):</label>
              <span>&nbsp;&nbsp;</span>
              <input
                type="number"
                id="distthresh"
                name="distthresh"
                min="100"
                max="1000"
                value={this.props.distThresholdSpatial}
                onChange={(e) => {
                  this.props.handleDistThresholdSpatial(e);
                }}
              ></input>
            </div>
            <div className="histbinsoption">
              <label htmlFor="histbins">Number of Neighbors:</label>
              <span>&nbsp;&nbsp;</span>
              <input
                type="number"
                id="numneighbors"
                name="numneighbors"
                min="1"
                max="100"
                value={this.props.numNeighborsSpatial}
                onChange={(e) => {
                  this.props.handleNumNeighborsSpatial(e);
                }}
              ></input>
            </div>
          <div className="spatial-options" key="onlyinselectspatial">
            <label>Choose a Selection:&nbsp;&nbsp;</label>
            <Select
              menuPlacement={"top"}
              isMulti={false}
              onChange={(m) => this.props.handleActiveSelectionSpatial(m)}
              options={this.getAllSelectionsWholeImage()}
              value={{ label: this.props.activeSelectionSpatial }}
          />
          </div>
          <div className="spatial-options" key="onlyinselectspatialphenos">
            <label style={{"width": "100%"}}>Choose phenotypes:&nbsp;&nbsp;</label>
          </div>
          <Select
              menuPlacement={"top"}
              isMulti={true}
              onChange={(m) => this.props.handleSelectSpatial(m)}
              value={phenotypeChannels}
              options={Array.from(this.props.chanLabel.values()).filter(
                (chan, index) => {
                  //don't show cell masks channel
                  return (
                    !this.props.activeIdsSpatial.includes(chan.label) && 
                    chan.label !== "Cell Masks" &&
                    !chan.label.includes("Phenotype ") &&
                    !chan.label.includes("Cluster ") &&
                    !chan.label.includes("Dapi") &&
                    // (index >= this.props.num_real_channels || chan.label.toLowerCase().includes("positive"))
                    (chan.label.toLowerCase().includes("positive"))
                  );
                }
              )}
            />
          <div className="spacer"></div>
            {/*
            
            {/*options*/}

            {/*
            _______________________________________________
            <div style={{ margin: "10px" }}>
              <button
                className="ui button analyze-tight"
                onClick={this.props.computeClusters}
                disabled
              >
                Cluster Similar Cells
              </button>
            </div>
            <div className="histbinsoption">
              <label htmlFor="numclusters">Number of Clusters:</label>
              <span>&nbsp;&nbsp;</span>
              <input
                type="number"
                id="numclusters"
                name="numclusters"
                min="1"
                max="50"
                value={this.props.numClusters}
                onChange={(e) => {
                  this.props.handleNumClusters(e);
                }}
              ></input>
            </div>
            */}

          </div>
        );
      }
      // else if we are computing it, show loader
      else if (this.props.computingInfiltrationDistance) {
        hists = (
          <div className="loader">
            <label className="label-black">Fetching data...</label>
            <img className="spinner" src="image/spintellx_logo.svg" />
          </div>
        );
      }
      // else, we have the data, show the infiltrate plot
      else {
        hists = (
          <div className="infiltration-plot-container">
            <label className="infiltration-plot-label">
              {this.props.activeChannelInfiltration} Infiltration Distances
            </label>
            <OrdinalFrame
              {...infiltrationFrameProps}
              data={this.props.infiltrationData}
              key={this.props.infiltrationData.length}
              summaryType={"violin"}
            />

            <button
              className="ui button sc-clear"
              title="Clear"
              onClick={() => this.props.clearInfiltrationData()}
            >
              Clear
            </button>
          </div>
        );
      }
    }
    //compare (scatterplot) tab
    else if (this.props.dataPanelTabs[2].visible) {
      const { activeChannelsScatterPlot } = this.state;

      let scatterPlot;
      if (
        !this.props.haveScatterPlotData &&
        !this.props.fetchingScatterPlotData
      ) {
        scatterPlot = (
          <div className="scatterPlot-selections">
            <label>Select First Channel to Compare:</label>
            <Select
              isMulti={false}
              onChange={(m) => this.handleActiveChannelsScatterPlot(0, m.value)}
              options={Array.from(this.props.chanLabel.values()).filter(
                (chan) => {
                  //don't show cell masks channel
                  return (
                    !chan.label.toLowerCase().includes("cell")&&
                    !chan.label.toLowerCase().includes("nuclei")&&
                    !chan.label.toLowerCase().includes("positive") &&
                    !chan.label.toLowerCase().includes("mask") &&
                    !chan.label.includes("HE-") &&
                    !chan.label.toLowerCase().includes("dapi") &&
                    !chan.label.includes("Phenotype ") &&
                    !chan.label.includes("Cluster ")
                  );
                }
              )}
              value={{ label: this.state.activeChannelsScatterPlot[0] }}
            />

            <label>Select Second Channel to Compare:</label>
            <Select
              isMulti={false}
              onChange={(m) => this.handleActiveChannelsScatterPlot(1, m.value)}
              options={Array.from(this.props.chanLabel.values()).filter(
                (chan) => {
                  //don't show cell masks channel
                  return (
                    !chan.label.toLowerCase().includes("cell")&&
                    !chan.label.toLowerCase().includes("nuclei")&&
                    !chan.label.toLowerCase().includes("positive") &&
                    !chan.label.toLowerCase().includes("mask") &&
                    !chan.label.toLowerCase().includes("positive") &&
                    !chan.label.includes("HE-") &&
                    !chan.label.toLowerCase().includes("dapi") &&
                    !chan.label.includes("Phenotype ") &&
                    !chan.label.includes("Cluster ")
                  );
                }
              )}
              value={{ label: this.state.activeChannelsScatterPlot[1] }}
            />

            <label>Choose a Selection:</label>
            <Select
              isMulti={false}
              onChange={(m) => this.handleActiveSelectionScatterPlot(m)}
              options={this.getAllSelectionsWholeImage()}
              value={{ label: this.state.activeSelectionScatterPlot }}
            />

            <div className="spacer"></div>
            <div className="spacer"></div>
            <div className="spacer"></div>
            <div className="spacer"></div>
            <div className="spacer"></div>
            <div className="spacer"></div>
            <div className="spacer"></div>
            <div className="spacer"></div>

            <button
              className="ui button sc-analyze"
              title="Compute"
              disabled={
                this.state.activeSelectionScatterPlot == "" ||
                this.state.activeChannelsScatterPlot[0] == "" ||
                this.state.activeChannelsScatterPlot[1] == ""
              }
              onClick={() =>
                this.props.fetchScatterPlotData(
                  this.state.activeChannelsScatterPlot,
                  this.state.activeSelectionScatterPlot
                )
              }
            >
              Analyze
            </button>
          </div>
        );
      } else if (this.props.fetchingScatterPlotData) {
        //show loading spinner
        scatterPlot = (
          <div className="loader">
            <label className="label-black">Fetching data...</label>
            <img className="spinner" src="image/spintellx_logo.svg" />
          </div>
        );
      } else {
        {
          /*
        scatterPlot = 
        <div className='scatterplot'>
        <XYChart
          width={300}
          height={200}
          ariaLabel="My pointseries of ..."
          orientation="vertical"
          cumulative={false}
          normalized={true}
          valueAccessor={datum => datum}
          theme={chartTheme}
          xScale={{"type":"linear","nice":true}}
          yScale={{"type":"linear","nice":true}}
        >
          {/*
          <PointSeries
            animated
            data={this.props.scatterPlotData}
            stroke={'#000000'}
            fill={'#00FFFF'}
          />
          <PointSeries
            data={this.props.scatterPlotData}
            stroke={'#000000'}
            fill={'#00FFFF'}
          />
          <XAxis label={this.state.activeChannelsScatterPlot[0]}
          labelProps={{ width: 200, verticalAnchor: 'bottom' }} />
          <YAxis label={this.state.activeChannelsScatterPlot[1]}
          labelProps={{ width: 200, verticalAnchor: 'middle' }} />
        </XYChart>
        */
        }

        scatterPlot = (
          <div className="scatterplot">
            <Heatmap
              heatmapData={this.props.heatmapData}
              heatmapXLabels={this.props.heatmapXLabels}
              heatmapYLabels={this.props.heatmapYLabels}
              activeChannelsScatterPlot={this.state.activeChannelsScatterPlot}
              activeSelectionScatterPlot={this.state.activeSelectionScatterPlot}
            />

            <button
              className="ui button sc-clear"
              title="Clear"
              onClick={this.props.clearScatterPlot}
            >
              Clear
            </button>

            <button
              className="ui button primary"
              title={"Export scatterplot data"}
              onClick={() =>
                this.props.exportScatterplot(
                  this.state.activeChannelsScatterPlot
                )
              }
            >
              <FontAwesomeIcon icon={faSave} />
              &nbsp; Export Plot&nbsp;
            </button>
          </div>
        );
      }

      hists = (
        <div className="hists">
          <label className="warnings2">{this.props.warning}</label>
          <label className="warnings2">{this.props.warning}</label>
          {scatterPlot}
        </div>
      );
    }

    //add additional options
    if (this.props.activeSelection == "Polyline") {
      let temp = (
        <div className="options" key="onlyinselect">
          <label htmlFor="selectioncheck">
            <input
              type="checkbox"
              id="selectioncheck"
              value={this.props.onlyInSelection}
              onChange={this.props.handleOnlyInSelection}
              checked={this.props.onlyInSelection}
              style={{ width: "20px", height: "20px" }}
            />
            <span>Only count cells inside selection</span>
          </label>
        </div>
      );
      options.push(temp);
    }

    //if they only want to count in a selection, ask what selection (rect or poly)
    if (
      this.props.activeSelection == "Polyline" &&
      this.props.onlyInSelection
    ) {
      let temp = (
        <div className="suboptions" key="suboptions">
          <div className="options" key="polyorrect">
            <label htmlFor="rectselectioncheck">
              <input
                type="checkbox"
                id="rectselectioncheck"
                value={this.props.onlyInSelectionRect}
                checked={this.props.onlyInSelectionRect}
                onChange={this.props.handleOnlyInSelectionRect}
                style={{ width: "20px", height: "20px" }}
              />
              <span>Rectangle</span>
            </label>

            <label htmlFor="polyselectioncheck">
              <input
                type="checkbox"
                id="polyselectioncheck"
                value={!this.props.onlyInSelectionRect}
                checked={!this.props.onlyInSelectionRect}
                onChange={this.props.handleOnlyInSelectionRect}
                style={{ width: "20px", height: "20px" }}
              />
              <span>Polygon</span>
            </label>
          </div>
        </div>
      );
      options.push(temp);
    }

    return (
      <div id="datapanel" className="data panel">
        <div className="modal-content">
          <div className="minimizebtn-rt">
            <span id="min-switch">
              <button
                type="button"
                className="ui button closedp"
                onClick={this.props.toggle}
              >
                <FontAwesomeIcon icon={faTimes} size={"sm"} />
              </button>
            </span>
          </div>

          <div className="tab-selection">
            <label htmlFor="summaryoption">
              <input
                type="checkbox"
                id="summaryoption"
                value={this.props.dataPanelTabs[0].visible}
                checked={this.props.dataPanelTabs[0].visible}
                onChange={(e) => this.props.handleDataPanelTab(0)}
                style={{ width: "16px", height: "16px" }}
              />
              <span>Summary</span>
              <span>&nbsp;&nbsp;</span>
            </label>
            <label htmlFor="spatialoption">
              <input
                type="checkbox"
                id="spatialoption"
                value={this.props.dataPanelTabs[1].visible}
                checked={this.props.dataPanelTabs[1].visible}
                onChange={(e) => this.props.handleDataPanelTab(1)}
                style={{ width: "16px", height: "16px" }}
              />
              <span>Spatial</span>
              <span>&nbsp;&nbsp;</span>
            </label>
            <label htmlFor="compareoption">
              <input
                type="checkbox"
                id="compareoption"
                value={this.props.dataPanelTabs[2].visible}
                checked={this.props.dataPanelTabs[2].visible}
                onChange={(e) => this.props.handleDataPanelTab(2)}
                style={{ width: "16px", height: "16px" }}
              />
              <span>Compare</span>
            </label>
          </div>

          {options}

          {hists}
        </div>
      </div>
    );
  }
}
