import React, { Component } from "react";

import OverlayList from "../components/overlaylist";
import '../style/overlays'

class Overlays extends Component {

  render() {
    const { overlays } = this.props;
    const { deleteOverlay } = this.props;
    const { deleteLine, hasLine, deleteMask } = this.props;
    const { deletePolygon, polygonPoints, highlightPolygon, maskPoints, highlightMask } = this.props;

    const overlayDivs = overlays.map((o, i) => {
      return (
      <div className="" key={i}>
      <div className="col">
      <div className="ui buttons">
        <button className="ui button red compact overlay-x" title="Delete" onClick={()=>{
						deleteOverlay(i);
					}}>
          X
        </button>
        <button className="ui button compact" title="Analyze Region">
        Selection {i}
        </button>
      </div>
      </div>
      </div>
      );
    });

    const lineDiv = (hasLine) ? (
      <div className="">
        <div className="col">
          <div className="ui buttons">
            <button className="ui button red compact overlay-x" title="Delete" onClick={deleteLine}>
              X
            </button>
            <button className="ui button compact" id="line">
            Line Selection
            </button>
          </div>
        </div>
      </div>
    ) : (
      <div className="row">
        <div className="col">
        </div>
      </div>
    );

    const polygonDiv = polygonPoints.map((o, i) => {
      return (
      <div className="" key={i}>
      <div className="col">
      <div className="ui buttons">
        <button className="ui button red compact overlay-x" title="Delete" onClick={()=>{
						deletePolygon(i);
					}}>
          X
        </button>
        <button className="ui button compact" title="Analyze Region" onClick={()=>{
            highlightPolygon(i);
        }}>
        Polygon {i}
        </button>
      </div>
      </div>
      </div>
      );
    });

    const maskDiv = maskPoints.map((o, i) => {
      return (
      <div className="" key={i}>
      <div className="col">
      <div className="ui buttons">
        <button className="ui button red compact overlay-x" title="Delete" onClick={()=>{
						deleteMask(i);
					}}>
          X
        </button>
        <button className="ui button compact" title="Analyze Region" onClick={()=>{
            highlightMask(i);
        }}>
        Freehand {i}
        </button>
      </div>
      </div>
      </div>
      );
    });

    
    return (
      <div className="selections">
      <OverlayList>
        { polygonDiv }
        {/* { lineDiv } */}
        { overlayDivs }
        { maskDiv }
      </OverlayList>
      </div>
    );
  }
}

export default Overlays;
