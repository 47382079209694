import React from "react";
//import "../style/CirclePlot.css";
//data-ui histogram package
import Modal from "../components/modal";
import * as d3 from "d3";
import "d3-chord";
import "../style/circleplot.css";
import Select from "react-select";

export default class CirclePlot extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      redrawPlot: false,
      activeChannelsCirclePlot: [], // what phenotypes to display
      filteredCirclePlotData: [], //the actual data to display, as filtered by active channels
      filteredCirclePlotData2: [], //data for the second circle plot (same active channels)
      secondSelection: "", //what selection to use for the second circle plot
      forceRerender: false,
      forceRerender2: false,
      circleOrMap: true, // whether to show circle plot or pmi map. true for circle
    };
    //binds
    this.handleCircleOrMap = this.handleCircleOrMap.bind(this);
    this.handleActiveChannelsCirclePlot =
      this.handleActiveChannelsCirclePlot.bind(this);
    this.filterActiveChannelData = this.filterActiveChannelData.bind(this);
    this.renderPlot = this.renderPlot.bind(this);
    this.getAllSelections = this.getAllSelections.bind(this);
    this.renderPmiMap = this.renderPmiMap.bind(this);
    this.renderPmiMap2 = this.renderPmiMap2.bind(this);
  }

  handleCircleOrMap() {
    var newVal = !this.state.circleOrMap;
    this.setState({ circleOrMap: newVal });
    // clear current visualization, draw new
    if (newVal) {
      // remove map, draw circle
      d3.select(".map-container").select("svg").selectAll("*").remove();
      d3.select(".map-container").select("svg").remove();
      d3.select(".map-container2").select("svg").selectAll("*").remove();
      d3.select(".map-container2").select("svg").remove();
      this.renderPlot();
      this.renderPlot2();
    } else {
      // remove circle, draw map
      d3.select(".plot-container").select("svg").selectAll("*").remove();
      d3.select(".plot-container").select("svg").remove();
      d3.select(".plot-container2").select("svg").selectAll("*").remove();
      d3.select(".plot-container2").select("svg").remove();
      this.renderPmiMap();
      this.renderPmiMap2();
    }
  }

  //returns an array of possible selections (rectangles, polygon) for the second circle plot
  getAllSelections() {
    let options = [];
    this.props.overlays.forEach((e, i) =>
      options.push({ value: i, label: "Selection " + i })
    );
    this.props.polygonPoints.forEach((e, i) =>
      options.push({ value: "Polygon " + i, label: "Polygon " + i })
    );
    this.props.maskPoints.forEach((e, i) =>
      options.push({ value: "Freehand " + i, label: "Freehand " + i })
    );
    return options;
  }

  handleActiveChannelsCirclePlot(channels) {
    //if channels is empty, that means user removed all channels
    if (!channels) {
      this.setState({ activeChannelsCirclePlot: [] });
      return;
    }
    let new_activeChannelsCirclePlot = channels.map((chan) => chan.value);
    //rerender plot with new channels
    this.setState({ activeChannelsCirclePlot: new_activeChannelsCirclePlot });
    //remove svgs to force re-render
    d3.select(this.refs.circPlot).select("svg").selectAll("*").remove();
    d3.select(this.refs.circPlot).select("svg").remove();
    d3.select(this.refs.circPlot2).select("svg").selectAll("*").remove();
    d3.select(this.refs.circPlot2).select("svg").remove();
  }

  //filters the circle plot data to only include those channels which are active
  filterActiveChannelData() {
    const { filteredCirclePlotData, activeChannelsCirclePlot } = this.state;
    if (this.props.circlePlotData.length == 0) return;
    console.log("filtering");

    //find the indices of the active channels in circlePlotCats (they are the same as the columns in the data)
    // let active_indices = activeChannelsCirclePlot.map( (chan) => this.props.circlePlotCats.findIndex((e) => e===chan ))
    let active_indices = this.props.activeIdsSpatial.map((chan) =>
      this.props.circlePlotCats.findIndex((e) => e === chan)
    );
    //compute indices to remove (difference)

    // let inds_to_remove = [...Array(this.props.circlePlotCats.length).keys()].filter ((e) => {
    // 	return !(active_indices.includes(e));
    // })

    //sort the indices
    active_indices.sort((a, b) => {
      return a - b;
    });

    //get rows/columns from the data
    //first, filter out columns
    let td = this.props.circlePlotData.map((row, i) => {
      return row.filter((e, j) => {
        return active_indices.includes(j);
      });
    });
    //now, filter rows
    td = td.filter((row, ind) => {
      return active_indices.includes(ind);
    });
    return td;
  }

  //same as above, but for getting the filtered data for the second plot
  // PLeEEASEE MAKE THESE ONE FUNCTION
  filterActiveChannelData2() {
    const { filteredCirclePlotData2, activeChannelsCirclePlot } = this.state;
    if (this.props.circlePlotData.length == 0) return;

    //find the indices of the active channels in circlePlotCats (they are the same as the columns in the data)
    // let active_indices = activeChannelsCirclePlot.map( (chan) => this.props.circlePlotCats.findIndex((e) => e===chan ))
    let active_indices = this.props.activeIdsSpatial.map((chan) =>
      this.props.circlePlotCats.findIndex((e) => e === chan)
    );
    //compute indices to remove (difference)

    // let inds_to_remove = [...Array(this.props.circlePlotCats.length).keys()].filter ((e) => {
    // 	return !(active_indices.includes(e));
    // })

    //sort the indices
    active_indices.sort((a, b) => {
      return a - b;
    });

    //get rows/columns from the data
    //first, filter out columns
    let td = this.props.circlePlotData2.map((row, i) => {
      return row.filter((e, j) => {
        return active_indices.includes(j);
      });
    });
    //now, filter rows
    td = td.filter((row, ind) => {
      return active_indices.includes(ind);
    });
    return td;
  }

  renderPlot() {
    //if panel is hidden, don't draw
    if (!this.props.show) return;
    //if no data, don't render
    if (this.props.circlePlotData.length == 0) return;

    if (d3.select(this.refs.circPlot).select("svg").empty()) {
      console.log("DRAWING PLOT!");

      //filter data (if there is any)
      let filtered_data = this.filterActiveChannelData();

      // const circlePlotCats = this.state.activeChannelsCirclePlot;
      const circlePlotCats = this.props.activeIdsSpatial;

      var chord_layout = d3.chord().padAngle(0.03).sortSubgroups(d3.descending);

      var width = 450;
      var height = 450;
      var innerRadius = (width / 2) * 0.7;
      var outerRadius = innerRadius * 1.1;

      //this defines the color scheme for the chords
      var color20 = d3.scaleOrdinal(d3.schemeAccent);
      var color_range = d3
        .scaleSequential(d3.interpolateRdBu)
        .domain([this.props.pmi_range[0], this.props.pmi_range[1]]);

      //add element
      var svg = d3
        .select(".plot-container")
        .append("svg")
        .attr("width", width)
        .attr("height", height);

      //draw nodes
      var outer_arc = d3
        .arc()
        .innerRadius(innerRadius)
        .outerRadius(outerRadius);

      var groups = chord_layout(filtered_data);

      var g_outer = svg
        .append("g")
        .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")")
        .datum(groups);

      var group = g_outer
        .append("g")
        .attr("class", "groups")
        .selectAll("g")
        .data(function (chords) {
          return chords.groups;
        })
        .enter()
        .append("g");

      //add color
      group
        .append("path")
        .style("fill", function (d) {
          return color20(d.index);
        })
        .style("stroke", function (d) {
          return color20(d.index);
        })
        .attr("d", outer_arc);

      // //add text
      group
        .append("text")
        .attr("dy", ".35em") //width
        .attr("fill", "#F7F7F7")
        .attr("transform", function (d, i) {
          //angle
          d.angle = (d.startAngle + d.endAngle) / 2; //calculate the average of the start angle and the end angle
          d.name = circlePlotCats[i]; //assignment for the biomarker/phenotype
          return (
            "rotate(" +
            (d.angle * 180) / Math.PI +
            ")" +
            "translate(0," +
            -1.0 * (outerRadius + 10) +
            ")" +
            (d.angle > (Math.PI * 3) / 4 && d.angle < (Math.PI * 5) / 4
              ? "rotate(180)"
              : "")
          );
        }) //to spin when the angle between 135 to 225 degrees
        .text(function (d) {
          return d.name;
        });

      // //add chord
      var inner_chord = d3.ribbon().radius(innerRadius);

      //create tooltip
      var tooltip = d3
        .select(".plot-container")
        .append("div")
        .attr("class", "tooltip");

      //draw the actual correlations
      g_outer
        .append("g")
        .attr("class", "ribbons")
        .selectAll("path")
        .data(function (chords) {
          return chords;
        })
        .enter()
        //tooltip
        .append("path")
        .attr("d", inner_chord)
        .style("fill", function (d) {
          return color_range(d.source.value);
          //return color20(d.source.index);
        })
        .style("stroke", "black")
        .style("opacity", 0.8)
        .on("mouseover", function (d, i) {
          //change chord color on mouseover
          d3.select(this).style("fill", "yellow");
          //display tooltip
          tooltip.transition().duration(200).style("opacity", 0.8);
          //add text to tooltip (connetion strength, 2 biomarkers)
          var act = d.source.value.toFixed(3);
          tooltip
            .html(
              circlePlotCats[d.source.index] +
                " - " +
                circlePlotCats[d.target.index] +
                " (" +
                act +
                ")"
            )
            .style("left", d3.event.pageX - 100 + "px")
            .style("top", d3.event.pageY + "px")
            .style("display", "inline-block");
        })
        .on("mouseout", function (d, i) {
          d3.select(this)
            .transition()
            .duration(1000)
            .style("fill", color_range(d.source.value));
          //hide tooltip
          tooltip.style("display", "none");
        });
    }
  }

  //A COPY OF RENDER_PLOT, FOR RENDERING THE SECOND PLOT
  //	PLEASE REFACTOR THIS INTO ONE FUNCTION
  renderPlot2() {
    //if panel is hidden, don't draw
    if (!this.props.show) return;
    //if no data, don't render
    if (this.props.circlePlotData2.length == 0) return;

    if (d3.select(this.refs.circPlot2).select("svg").empty()) {
      console.log("DRAWING SECOND PLOT!");

      //filter data (if there is any)
      let filtered_data = this.filterActiveChannelData2();

      // const circlePlotCats = this.state.activeChannelsCirclePlot;
      const circlePlotCats = this.props.activeIdsSpatial;

      var chord_layout = d3.chord().padAngle(0.03).sortSubgroups(d3.descending);

      var width = 450;
      var height = 450;
      var innerRadius = (width / 2) * 0.7;
      var outerRadius = innerRadius * 1.1;

      //this defines the color scheme for the chords
      var color20 = d3.scaleOrdinal(d3.schemeAccent);
      var color_range = d3
        .scaleSequential(d3.interpolateRdBu)
        .domain([this.props.pmi_range[0], this.props.pmi_range[1]]);

      //add element
      var svg = d3
        .select(".plot-container2")
        .append("svg")
        .attr("width", width)
        .attr("height", height);

      //draw nodes
      var outer_arc = d3
        .arc()
        .innerRadius(innerRadius)
        .outerRadius(outerRadius);

      var groups = chord_layout(filtered_data);

      var g_outer = svg
        .append("g")
        .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")")
        .datum(groups);

      var group = g_outer
        .append("g")
        .attr("class", "groups")
        .selectAll("g")
        .data(function (chords) {
          return chords.groups;
        })
        .enter()
        .append("g");

      //add color
      group
        .append("path")
        .style("fill", function (d) {
          return color20(d.index);
        })
        .style("stroke", function (d) {
          return color20(d.index);
        })
        .attr("d", outer_arc);

      // //add text
      group
        .append("text")
        .attr("dy", ".35em") //width
        .attr("fill", "#F7F7F7")
        .attr("transform", function (d, i) {
          //angle
          d.angle = (d.startAngle + d.endAngle) / 2; //calculate the average of the start angle and the end angle
          d.name = circlePlotCats[i]; //assignment for the biomarker/phenotype
          return (
            "rotate(" +
            (d.angle * 180) / Math.PI +
            ")" +
            "translate(0," +
            -1.0 * (outerRadius + 10) +
            ")" +
            (d.angle > (Math.PI * 3) / 4 && d.angle < (Math.PI * 5) / 4
              ? "rotate(180)"
              : "")
          );
        }) //to spin when the angle between 135 to 225 degrees
        .text(function (d) {
          return d.name;
        });

      // //add chord
      var inner_chord = d3.ribbon().radius(innerRadius);

      //create tooltip
      var tooltip = d3
        .select(".plot-container2")
        .append("div")
        .attr("class", "tooltip2");

      //draw the actual correlations
      g_outer
        .append("g")
        .attr("class", "ribbons")
        .selectAll("path")
        .data(function (chords) {
          return chords;
        })
        .enter()
        //tooltip
        .append("path")
        .attr("d", inner_chord)
        .style("fill", function (d) {
          return color_range(d.source.value);
          //return color20(d.source.index);
        })
        .style("stroke", "black")
        .style("opacity", 0.8)
        .on("mouseover", function (d, i) {
          //change chord color on mouseover
          d3.select(this).style("fill", "yellow");
          //display tooltip
          tooltip.transition().duration(200).style("opacity", 0.8);
          //add text to tooltip (connetion strength, 2 biomarkers)
          var act = d.source.value.toFixed(3);
          tooltip
            .html(
              circlePlotCats[d.source.index] +
                " - " +
                circlePlotCats[d.target.index] +
                " (" +
                act +
                ")"
            )
            .style("left", d3.event.pageX - 400 + "px")
            .style("top", d3.event.pageY + 2 + "px")
            .style("display", "inline-block");
        })
        .on("mouseout", function (d, i) {
          d3.select(this)
            .transition()
            .duration(1000)
            .style("fill", color_range(d.source.value));
          //hide tooltip
          tooltip.style("display", "none");
        });
    }
  }

  renderPmiMap() {
    //if panel is hidden, don't draw
    if (!this.props.show) return;
    //if no data, don't render
    if (this.props.circlePlotData.length == 0) return;

    if (d3.select(this.refs.mapPlot).select("svg").empty()) {
      console.log("DRAWING map!");

      let data = this.props.circlePlotData;
      const circlePlotCats = this.props.activeIdsSpatial;

      var width = 350;
      var height = 350;
      var offset = 50;

      var dim = Math.floor((width - offset) / data.length); // dimension of item in grid

      var transformed_data = [];
      var startX = offset;
      var startY = offset;
      var xpos = startX;
      var ypos = startY;
      var stepX = dim;
      var stepY = dim;
      //parent array
      for (var index_a = 0; index_a < data.length; index_a++) {
        transformed_data.push([]);
        //nested array
        for (var index_b = 0; index_b < data[0].length; index_b++) {
			// make upper diagonal
          if (index_b < index_a) {
			transformed_data[index_a].push({
            width: dim,
            height: dim,
            x: xpos,
            y: ypos,
            value: -1000,
            ph1: circlePlotCats[index_a].replace(" positive", "+"),
            ph2: circlePlotCats[index_b].replace(" positive", "+"),
          });
          } 
		  else {
			transformed_data[index_a].push({
            width: dim,
            height: dim,
            x: xpos,
            y: ypos,
            value: data[index_a][index_b],
            ph1: circlePlotCats[index_a].replace(" positive", "+"),
            ph2: circlePlotCats[index_b].replace(" positive", "+"),
          });
          }
          xpos += stepX;
        }
        xpos = startX;
        ypos += stepY;
      }

      console.log("drawing pmi map for data: " + transformed_data);

      //create tooltip
      var tooltip = d3
        .select(".map-container")
        .append("div")
        .attr("class", "tooltip");

      // set up color scaling
      var color_range = d3
        .scaleSequential(d3.interpolateRdBu)
        .domain([this.props.pmi_range[0], this.props.pmi_range[1]]);

      // set up svg element
      var grid = d3
        .select(".map-container")
        .append("svg")
        .attr("width", width)
        .attr("height", height);

      // set up row elements
      var row = grid
        .selectAll(".row")
        .data(transformed_data)
        .enter()
        .append("g")
        .attr("class", "row");
      // fill in cells
      var col = row
        .selectAll(".cell")
        .data(function (d) {
          return d;
        })
        .enter()
        .append("svg:rect")
        .attr("class", "cell")
        .attr("x", function (d) {
          return d.x;
        })
        .attr("y", function (d) {
          return d.y;
        })
        .attr("width", function (d) {
          return d.width;
        })
        .attr("height", function (d) {
          return d.height;
        })
        .style("fill", function (d) {
          if (d.value != -1000) {
          return color_range(d.value);
          }
        })
        .style("stroke", "#ffffff")
		.style("opacity", function (d) {
          if (d.value != -1000) {
          return 1;
          } else {
          	return 0;
          }
        })
        .on("mouseover", function (d, i) {
          if (d.value != -1000) {
			//display tooltip
          tooltip.transition().duration(200).style("opacity", 0.8);
          //add text to tooltip (connetion strength, 2 biomarkers)
          var value = d.value.toFixed(3);
          tooltip
            .html(d.ph1 + " and " + d.ph2 + " (" + value + ")")
            .style("left", d3.event.pageX - 200 + "px")
            .style("top", d3.event.pageY + "px")
            .style("display", "inline-block");
          }
        })
        .on("mouseout", function (d, i) {
          if (d.value != -1000) {
			d3.select(this).transition().duration(1000);
          //hide tooltip
          tooltip.style("display", "none");
          }
        });

      // draw y axis labels
      var newStartY = offset + Math.floor(dim / 2);
      for (var d in transformed_data) {
        console.log(transformed_data[d][0].ph1);
        grid
          .append("text")
          .attr("x", 0)
          .attr("y", newStartY)
          .style("text-anchor", "right")
          // .attr("transform", "rotate(90)")
          // .style("font-size", "12px")
          .style("fill", "#ffffff")
          .text(transformed_data[d][0].ph1);
        newStartY += dim;
      }
      // draw x axis labels
      newStartY = offset - 20;
      var newStartX = offset + Math.floor(dim / 2);
      for (var d in transformed_data) {
        grid
          .append("text")
          .attr("x", newStartX - 20)
          .attr("y", newStartY)
          .style("text-anchor", "right")
          // .attr("transform", "rotate(90)")
          // .style("font-size", "12px")
          .style("fill", "#ffffff")
          .text(transformed_data[d][0].ph1);
        newStartX += dim;
      }
    }
  }

  renderPmiMap2() {
    //if panel is hidden, don't draw
    if (!this.props.show) return;
    //if no data, don't render
    if (this.props.circlePlotData2.length == 0) return;

    if (d3.select(this.refs.mapPlot2).select("svg").empty()) {
      console.log("DRAWING map!");

      let data = this.props.circlePlotData2;
      const circlePlotCats = this.props.activeIdsSpatial;

      var width = 350;
      var height = 350;
      var offset = 50;

      var dim = Math.floor((width - offset) / data.length); // dimension of item in grid

      var transformed_data = [];
      var startX = offset;
      var startY = offset;
      var xpos = startX;
      var ypos = startY;
      var stepX = dim;
      var stepY = dim;
      //parent array
      for (var index_a = 0; index_a < data.length; index_a++) {
        transformed_data.push([]);
        //nested array
        for (var index_b = 0; index_b < data[0].length; index_b++) {
			// make upper diagonal
          if (index_b < index_a) {
			transformed_data[index_a].push({
            width: dim,
            height: dim,
            x: xpos,
            y: ypos,
            value: -1000,
            ph1: circlePlotCats[index_a].replace(" positive", "+"),
            ph2: circlePlotCats[index_b].replace(" positive", "+"),
          });
          } 
		  else {
			transformed_data[index_a].push({
            width: dim,
            height: dim,
            x: xpos,
            y: ypos,
            value: data[index_a][index_b],
            ph1: circlePlotCats[index_a].replace(" positive", "+"),
            ph2: circlePlotCats[index_b].replace(" positive", "+"),
          });
          }
          xpos += stepX;
        }
        xpos = startX;
        ypos += stepY;
      }

      console.log("drawing pmi map for data: " + transformed_data);

      //create tooltip
      var tooltip = d3
        .select(".map-container2")
        .append("div")
        .attr("class", "tooltip");

      // set up color scaling
      var color_range = d3
        .scaleSequential(d3.interpolateRdBu)
        .domain([this.props.pmi_range[0], this.props.pmi_range[1]]);

      // set up svg element
      var grid = d3
        .select(".map-container2")
        .append("svg")
        .attr("width", width)
        .attr("height", height);

      // set up row elements
      var row = grid
        .selectAll(".row")
        .data(transformed_data)
        .enter()
        .append("g")
        .attr("class", "row");
      // fill in cells
      var col = row
        .selectAll(".cell")
        .data(function (d) {
          return d;
        })
        .enter()
        .append("svg:rect")
        .attr("class", "cell")
        .attr("x", function (d) {
          return d.x;
        })
        .attr("y", function (d) {
          return d.y;
        })
        .attr("width", function (d) {
          return d.width;
        })
        .attr("height", function (d) {
          return d.height;
        })
        .style("fill", function (d) {
          if (d.value != -1000) {
          return color_range(d.value);
          }
        })
        .style("stroke", "#ffffff")
		.style("opacity", function (d) {
          if (d.value != -1000) {
          return 1;
          } else {
          	return 0;
          }
        })
        .on("mouseover", function (d, i) {
          if (d.value != -1000) {
			//display tooltip
          tooltip.transition().duration(200).style("opacity", 0.8);
          //add text to tooltip (connetion strength, 2 biomarkers)
          var value = d.value.toFixed(3);
          tooltip
            .html(d.ph1 + " and " + d.ph2 + " (" + value + ")")
            .style("left", d3.event.pageX - 600 + "px")
            .style("top", d3.event.pageY + "px")
            .style("display", "inline-block");
          }
        })
        .on("mouseout", function (d, i) {
          if (d.value != -1000) {
			d3.select(this).transition().duration(1000);
          //hide tooltip
          tooltip.style("display", "none");
          }
        });

      // draw y axis labels
      var newStartY = offset + Math.floor(dim / 2);
      for (var d in transformed_data) {
        console.log(transformed_data[d][0].ph1);
        grid
          .append("text")
          .attr("x", 0)
          .attr("y", newStartY)
          .style("text-anchor", "right")
          // .attr("transform", "rotate(90)")
          // .style("font-size", "12px")
          .style("fill", "#ffffff")
          .text(transformed_data[d][0].ph1);
        newStartY += dim;
      }
      // draw x axis labels
      newStartY = offset - 20;
      var newStartX = offset + Math.floor(dim / 2);
      for (var d in transformed_data) {
        grid
          .append("text")
          .attr("x", newStartX - 20)
          .attr("y", newStartY)
          .style("text-anchor", "right")
          // .attr("transform", "rotate(90)")
          // .style("font-size", "12px")
          .style("fill", "#ffffff")
          .text(transformed_data[d][0].ph1);
        newStartX += dim;
      }
    }
  }

  componentWillReceiveProps(newProps) {
    //only draw the plot when the user opens the panel
    if (newProps.show == true && this.state.redrawPlot == false) {
      console.log("drawing plot now");
      this.renderPlot();
      this.renderPlot2();
      this.setState({ redrawPlot: true });
    }
    //this.renderPlot();
  }

  componentDidUpdate() {
    this.renderPlot();
    this.renderPlot2();
    this.renderPmiMap();
    this.renderPmiMap2();
  }

  render() {
    if (!this.props.show) {
      return null;
    }
    let circlePlot2;
    //if we are currently fetching the data, show the spinner
    if (this.props.fetchingSecondSpatialData) {
      circlePlot2 = (
        <div className="loader-container">
          <div className="loader-small">
            <label className="label-black">Fetching data...</label>
            <img className="spinner" src="image/spintellx_logo.svg" />
          </div>
        </div>
      );
    }
    //if no second plot displayed, show option for adding one
    else if (this.props.circlePlotData2.length == 0) {
      circlePlot2 = (
        <div className="second-selection-container">
          <label className="label-wh">
            Choose another selection for comparison:
          </label>
          <Select
            className="second-plot-selection"
            isMulti={false}
            value={{ label: this.state.secondSelection }}
            options={this.getAllSelections()}
            onChange={(c) => {
              this.props.handleSecondCirclePlot(c);
              this.setState({ secondSelection: c.value });
            }}
          />
        </div>
      );
    } else if (this.state.circleOrMap) {
      circlePlot2 = (
        <div className="plot-container2" ref="circPlot2">
          <label className="circleplot-label">
            {this.state.secondSelection}
          </label>
          {/*<label className='entropy'>Heterogeneity score: {(this.props.entropy2).toFixed(3)}</label>*/}
        </div>
      );
    } else {
      circlePlot2 = (
        <div className="map-container2" ref="mapPlot2">
          <label className="circleplot-label">
            {this.state.secondSelection}
          </label>
          {/*<label className='entropy'>Heterogeneity score: {(this.props.entropy2).toFixed(3)}</label>*/}
        </div>
      );
    }

    // const {activeChannelsCirclePlot} = this.state;
    const activeChannelsCirclePlotTmp = this.props.activeIdsSpatial.map(
      (a, i) => {
        return { id: i, value: i, label: a };
      }
    );

    let plots;
    if (this.state.circleOrMap) {
      plots = (
        <div className="both-plots">
          <div className="plot-container" ref="circPlot">
            <label className="circleplot-label">
              {this.props.activeSelectionSpatial}
            </label>
            {/*<label className='entropy'>Heterogeneity score: {(this.props.entropy).toFixed(3)}</label>*/}
          </div>

          {circlePlot2}
          {/*
          <div>
            <img
              className="dummy-image"
              src="image/network.png"
            ></img>
          </div>
          */}

        </div>
      );
    } else {
      plots = (
        <div className="both-plots">
          <div className="map-container" ref="mapPlot">
            <label className="circleplot-label">
              {this.props.activeSelectionSpatial}
            </label>
          </div>

          {circlePlot2}
          {/*
          <div>
            <img
              className="dummy-image"
              src="image/network.png"
            ></img>
          </div>
          */}

        </div>
      );
    }

    let content;
    //if we're still waiting on the data, show loading spinner
    if (!this.props.haveSpatialData) {
      content = (
        <div className="loader">
          <label className="label-black">Fetching data...</label>
          <img className="spinner" src="image/spintellx_logo.svg" />
        </div>
      );
    }
    //else, dispaly the plot
    else {
      content = (
        <div>
          <h1>Pointwise Mutual Information</h1>

          <Select
            className="channel-select"
            isMulti={true}
            //   onChange={this.handleActiveChannelsCirclePlot}
            onChange={(m) => {
              this.props.handleSelectSpatial(m);
              //remove svgs to force re-render
              d3.select(this.refs.circPlot)
                .select("svg")
                .selectAll("*")
                .remove();
              d3.select(this.refs.circPlot).select("svg").remove();
              d3.select(this.refs.circPlot2)
                .select("svg")
                .selectAll("*")
                .remove();
              d3.select(this.refs.circPlot2).select("svg").remove();
            }}
            value={activeChannelsCirclePlotTmp}
            options={activeChannelsCirclePlotTmp}
          />

          {plots}

          <button
            className="ui button clear circleplot"
            onClick={() => {
              this.setState({
                filteredCirclePlotData: [],
                filteredCirclePlotData2: [],
                secondSelection: "",
              });
              this.props.clearCirclePlot();
              //actually remove svg elements
              // d3.select('.plot-container').select('svg').selectAll('*').remove();
              // d3.select('.plot-container2').select('svg').selectAll('*').remove();
              d3.select(".plot-container").remove();
              d3.select(".plot-container2").remove();
            }}
          >
            Clear
          </button>

          <div className="tooltip" id="tooltip"></div>
          <div className="tooltip2" id="tooltip2"></div>
        </div>
      );
    }
    var select = this.props.haveSpatialData ? (
      <div className="tab-selection">
        <label htmlFor="circleoption">
          <input
            type="checkbox"
            id="circleoption"
            value={this.state.circleOrMap}
            checked={this.state.circleOrMap}
            onChange={this.handleCircleOrMap}
            style={{ width: "16px", height: "16px" }}
          />
          <span>Circle</span>
          <span>&nbsp;&nbsp;</span>
        </label>
        <label htmlFor="mapoption">
          <input
            type="checkbox"
            id="mapoption"
            value={this.state.circleOrMap}
            checked={!this.state.circleOrMap}
            onChange={this.handleCircleOrMap}
            style={{ width: "16px", height: "16px" }}
          />
          <span>Map</span>
        </label>
      </div>
    ) : null;
    return (
      <Modal show={this.props.show} toggle={this.props.toggle}>
        {select}
        {content}
      </Modal>
    );
  }
}
