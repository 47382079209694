import React from 'react';
import { Modal } from 'semantic-ui-react';
import Loader from "./loader";
import FileBrowser from './filebrowser';
import '../style/filebrowser.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons'


export default class FileBrowserModal extends React.Component {
    render() {
        return (
            <Modal open={this.props.open} centered={false} className='filebrowser-modal'>
                <Modal.Header>
                    {this.props.title}
                    {/* <button type="button" className="ui button filebrowser-modal-close" onClick={this.props.close}>
                        <FontAwesomeIcon icon={faTimes} size="lg"/>
                    </button> */}
                <Loader active={this.props.loading} style={{position: "absolute", right: "10px"}}/>

                <button
                    className="ui button"
                    onClick={this.props.signOut}
                    style={{position: "absolute", right: "10px"}}
                    >
                    Sign Out
                </button>

                </Modal.Header>
                <Modal.Content className=''>
                    <FileBrowser onFileSelected={this.props.onFileSelected} 
                        filter={this.props.filter} 
                        home={this.props.home}
                        user={this.props.user}
                         />
                </Modal.Content>
            </Modal>
        );
    }
}
